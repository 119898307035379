import React from "react";
import ProductSlider1 from "./ProductSlider1";
import Slider4 from "./slider4";
import MostSearched from "./mostSearched";
import GuideSlider from "./guideSlider";
import Landingcategories from "./landingcategories";
const Home = () => {
  return (
    <div className="">
      <GuideSlider />
      <Landingcategories />
      <ProductSlider1></ProductSlider1>

      <MostSearched />
{/*   <Slider4 /> */}
    </div>
  );
};

export default Home;
