import React, { useState, useEffect } from "react";
import canceledit from "../../../images/ph_x-bold.png";
import { Form, Alert } from "react-bootstrap";
import makeApiCall from "../../../apiCall";

const EditOffer = ({ data, handleEditMode }) => {
  const [offerTitle, setOfferTitle] = useState(data[0].title || "");
  const [expireDate, setExpireDate] = useState(data[0].expireDate || "");
  const [createdAt, setCreatedAt] = useState(data[0].createdAt || "");
  const [intelligentOfferValidationDate, setIntelligentOfferValidationDate] =
    useState(data[0].intelligentOfferValidationDate || "");
  const [moq, setMoq] = useState(data[0].moq || 0);
  const [description, setDescription] = useState(data[0].description || "");
  const initialShippingTerms = {
    fob: data[0].fob || { Destination: "", Min: "", Max: "" },
    fas: data[0].fas || { Destination: "", Min: "", Max: "" },
    exw: data[0].exw || { Destination: "", Min: "", Max: "" },
    cpt: data[0].cpt || { Destination: "", Min: "", Max: "" },
    cip: data[0].cip || { Destination: "", Min: "", Max: "" },
    fca: data[0].fca || { Destination: "", Min: "", Max: "" },
  };

  const [shippingTerms, setShippingTerms] = useState(initialShippingTerms);

  const [selectedValidationPeriod, setSelectedValidationPeriod] = useState("");
  const [validationOptions, setValidationOptions] = useState([]);
  const validationPeriods = {
    OneWeek: 7,
    TwoWeeks: 14,
    OneMonth: 30,
    ThreeMonths: 90,
    SixMonths: 180,
    OneYear: 365,
  };

  const calculateDifferenceInDays = (startDate, endDate) => {
    const differenceInTime = endDate.getTime() - startDate.getTime();

    return differenceInTime / (1000 * 3600 * 24);
  };

  useEffect(() => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    const differenceInDays = calculateDifferenceInDays(createdAtDate,now  );
console.log(differenceInDays)
    const validationPeriodDays =
      validationPeriods[intelligentOfferValidationDate] || 0;

    const options = [];

    options.push({
      key: "current",
      displayValue: `${new Date(expireDate).toLocaleDateString()} (current)`,
      value:"",
    });

    for (const [key, value] of Object.entries(validationPeriods)) {
      const remainingDays = value - validationPeriodDays;
console.log(remainingDays,value,validationPeriodDays)
      if (differenceInDays > 0) {
        const futureExpirationDate = new Date(expireDate);
        futureExpirationDate.setDate(
          futureExpirationDate.getDate() + remainingDays
        );

        const formattedFutureDate = futureExpirationDate.toLocaleDateString();

        options.push({
          key,
          displayValue: `${formattedFutureDate}`,
          value: key,
        });
      } 
    }

    setValidationOptions(options);
  }, [createdAt, intelligentOfferValidationDate, expireDate]);


  const handleShippingChange = (type, field, value) => {
    setShippingTerms((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [field]: value,
      },
    }));
  };

  const handleValidationChange = (e) => {
    setSelectedValidationPeriod(e.target.value);
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const renderOfferProperties = (offer, type) => {
    const properties = shippingTerms[type] || {};
  
    return (
      <div className="d-flex justify-content-between my-3" key={type}>
        <div className="px-2">
          <Form.Label className="mb-1">{type.toUpperCase()} Dest</Form.Label>
          <Form.Control
            className="px-2"
            value={properties.Destination || ""}
            placeholder="destination"
            onChange={(e) => handleShippingChange(type, 'Destination', e.target.value)}
          />
        </div>
        <div className="px-2">
          <Form.Label className="mb-1">{type.toUpperCase()} {offer.intelligentOfferRequestType === "Seller" ? "Min" : "Max"}</Form.Label>
          <Form.Control
            className="px-2"
            type="number"
            value={offer.intelligentOfferRequestType === "Seller" ? properties.Min || "" : properties.Max || ""}
            placeholder={`${offer.intelligentOfferRequestType === "Seller" ? "Min" : "Max"} amount`}
            onChange={(e) => handleShippingChange(type,offer.intelligentOfferRequestType === "Seller" ? "Min" : "Max", e.target.value)}
          />
        </div>
      </div>
    );
  };
  

  const handleSave = async () => {
    try {
      const formData = new FormData();
      
      formData.append("Title", offerTitle);
      formData.append("expireDate", expireDate);
      formData.append("MOQ", moq);
      formData.append("Description", description);
      formData.append("IntelligentOfferValidationDate", selectedValidationPeriod);
      
      Object.keys(shippingTerms).forEach((type) => {
        const { Destination, Min, Max } = shippingTerms[type];
        formData.append(`${type}Destination`, Destination || "");
        formData.append(`${type}Min`, Min || "");
        formData.append(`${type}Max`, Max || "");
      });

      const response = await makeApiCall({
        restapi: "put",
        controller: "IntelligentOffer",
        apiName: "UpdateIntelligentOffer",
        hasId: data[0].id,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      console.log("Save response:", response);
      handleEditMode(false,true);
    } catch (error) {
      console.error("Error saving offer:", error);
    }
  };
  

  return (
    <div className="edit-offer-container">
      <div className="edit-section d-flex justify-content-between align-items-center mt-5 mx-auto">
        <p className="edit-offer-text text-start">Edit Offer</p>
        <img
          src={canceledit}
          className="cursor-pointer"
          alt="Cancel Edit"
          onClick={()=>handleEditMode(false)}
        />
      </div>

      <div className="d-md-flex justify-content-center mt-5 col-12">
        <Form.Group className="col-md-3 col-9 mx-md-3 mx-auto mt-md-0 mt-3">
          <Form.Label>Offer title</Form.Label>
          <Form.Control
            className="shadow-inset"
            placeholder="Offer title"
            value={offerTitle}
            onChange={(e) => handleInputChange(e, setOfferTitle)}
          />
        </Form.Group>

        <Form.Group className="col-md-3 col-9 mx-md-3 mx-auto mt-md-0 mt-3">
          <Form.Label>Expiration date</Form.Label>
          <Form.Control
            as="select"
            className="shadow-inset"
            value={selectedValidationPeriod}
            onChange={handleValidationChange}
            onClick={(e)=>console.log(e.target.value)}
          >
            {validationOptions.map((option) => (
              <option
          
              key={option.key} value={option.value}>
                {option.displayValue}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-md-3 col-9 mx-md-3 mx-auto mt-md-0 mt-3">
          <Form.Label>MOQ</Form.Label>
          <Form.Control
            type="number"
            className="shadow-inset"
            value={moq}
            onChange={(e) => handleInputChange(e, setMoq)}
          />
        </Form.Group>
      </div>
      <div className="d-md-flex justify-content-center mt-5 col-10 mx-auto">
        <div className="mx-md-auto col-md-6">
          <Form.Label className=" ms-2">Edit Shipping terms</Form.Label>
          <div className="edit-shipping-terms overflow-y-auto grey-scroll border shadow-inset rounded-3 bg-F5F7F8 px-3 py-3">
            <div className="d-flex justify-content-around mb-3">
              
              <div className="border-bottom border-dark col-5 text-center">Destination</div>
              <div className="border-bottom border-dark col-5 text-center">Price</div>
            </div>
            {data.length ? (
              <React.Fragment>
                {data.map((offer) => {
                  const offerProperties = [
                    "fob",
                    "fas",
                    "exw",
                    "cpt",
                    "cip",
                    "fca",
                  ]
                    .map((type) => renderOfferProperties(offer, type))
                    .filter(Boolean);

                  return <div key={offer.id}>{offerProperties}</div>;
                })}
              </React.Fragment>
            ) : (
              <Alert variant="danger">No offers available</Alert>
            )}
          </div>
        </div>

        <Form.Group className="col-md-5 mt-md-0 mt-3 mx-md-auto">
          <Form.Label>Bio</Form.Label>
          <Form.Control
            as="textarea"
            className="edit-offer-bio grey-scroll"
            value={description}
            onChange={(e) => handleInputChange(e, setDescription)}
          />
        </Form.Group>
      </div>

      <div className="d-flex justify-content-center">
        <button
          onClick={handleSave}
          className="save-button btn btn-outline-success"
        >
          <span className="fs-3">Save</span>
        </button>
      </div>
    </div>
  );
};

export default EditOffer;
