import React, { useState, useEffect } from "react";
import { FlagIcon } from "react-flag-kit";
import { Button, Card } from "react-bootstrap";
const CompanyLowDets = ({
  image,
  title,
  country,
  category,
  onClick,
  CountryCode,
}) => {
  useEffect(() => {
    document.querySelectorAll(".my-card").forEach((card) => {
      const blurEffect = card.querySelector(".blur-card-effect");

      card.addEventListener("mouseover", () => {
        blurEffect.classList.add("visible");
      });

      card.addEventListener("mouseout", () => {
        blurEffect.classList.remove("visible");
      });
    });
  });

  return (
    <React.Fragment>
      <div className="company-low-dets-container w-auto d-inline-flex my-0">
        <Card
          onClick={onClick}
          className=" my-3 cursor-pointer company-low-dets rounded-4 pt-3 pb-1 px-3 mx-auto text-center"
        >
          <div className="blur-card-effect">
            <div>view products</div>
          </div>
          <Card.Img
            className="company-image-card rounded-4 border-0"
            src={image}
            alt="Company Image"
          />
          <Card.Title className="W-100 align-items-center bg-white text-start pt-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className={`fw-bold pb-2 h5 mb-0 single-line-text`}>
                {title}
              </div>
              <FlagIcon
                code={CountryCode}
                height={20}
                width={30}
                className=""
              />
            </div>
            <div className="text-ellipsis-230 h6 mt-2 mb-2">{category}</div>
          </Card.Title>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default CompanyLowDets;
