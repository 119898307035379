import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import makeApiCall from "../../apiCall";

const HelpChatWindow = ({ animationClass }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [ticketSent, setTicketSent] = useState(false);
  const handleEmailIsFilled = () => {
    if (email !== "") {
      setEmailSent(true);
    }
  };
  const handleSubmit = async () => {
    try {
      if (email !== "" && message !== "") {
        setLoading(true);
        const response = await makeApiCall({
          restapi: "post",
          controller: "Ticketing",
          apiName: "HelpChatTicketEmail",

          data: { TicketOwnerEmail: email, Message: message },
        });
        console.log(response);
        setLoading(false);
        setTicketSent(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <div className={`help-chat-window ${animationClass}`}>
        <div className="my-3 text-center h4 fw-bold">
          Trade Assist Help Chat
        </div>
        <div className="help-chat-window-body">
          <div>
            <div className="d-flex justify-content-start pt-5 mb-4 ms-2">
              <div className="help-chat-agent-icon rounded-circle mt-3 col-1 align-content-center align-self-end text-center">
                <FontAwesomeIcon icon={faUserTie} size="xl" color="#FFC700" />
              </div>
              <div className="help-chat-agent shadow-sm align-content-center ms-2 px-3 py-2 col-9 rounded-top-5 rounded-end-5">
                <p className="mx-1 mb-0">Hello! How can I assist you today?</p>
              </div>
            </div>
            {ticketSent && (
              <div className="d-flex justify-content-start mb-4 ms-2">
                <div className="help-chat-agent-icon rounded-circle mt-3 col-1 align-content-center align-self-end text-center">
                  <FontAwesomeIcon icon={faUserTie} size="xl" color="#FFC700" />
                </div>
                <div className="help-chat-agent shadow-sm align-content-center ms-2 px-3 py-2 col-9 rounded-top-5 rounded-end-5">
                  <p className="mx-2 mb-0">
                    We have recieved your message and our agents will contact
                    you soon
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="help-chat-input-container mb-4 text-center my-3 mx-3">
            {emailSent && !ticketSent && (
              <React.Fragment>
                <label className="mb-1 fw-bold h5">Message</label>
                <input
                  type="text"
                  name="Message"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="message"
                  className="form-control"
                />
                <Button
                  onClick={() => handleSubmit()}
                  variant="warning"
                  className="mt-2"
                >
                  {loading ? (
                    <React.Fragment>
                      sending
                      <Spinner
                        animation="border"
                        className="spinner-small ms-1 mb-0"
                        role="status"
                      />
                    </React.Fragment>
                  ) : (
                    "send"
                  )}
                </Button>
              </React.Fragment>
            )}
            {!emailSent && !ticketSent && (
              <React.Fragment>
                <label className="mb-1 fw-bold h5">email</label>
                <input
                  type="email"
                  name="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="enter your email"
                  className="form-control"
                />
                <Button
                  onClick={() => handleEmailIsFilled()}
                  variant="warning"
                  className="mt-2"
                >
                  Send
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HelpChatWindow;
