import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Container, Row } from "react-bootstrap";
import PaginationComponent from "../../pagination/pagination";
import { FlagIcon } from "react-flag-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductDetail from "../Companies&Products/productDetails";
import { ToastContainer, toast } from "react-toastify";
import selectCnvImg from "../../../images/selectconversation.svg";
import "react-toastify/dist/ReactToastify.css";
import {
  faCircleCheck as faCheckSol,
  faCircleXmark as faCloseSol,
  faInbox,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTrashCan as faTrashReg,
  faCircleCheck as faCheckReg,
  faCircleXmark as faCloseReg,
} from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router";
import makeApiCall from "../../../apiCall";
import incomingIcon from "../../../images/incomingicon.svg";
import Conversation from "./Conversation";
const MessageCenter = () => {
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const [mCEmpty, setMCEmpty] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [conversationIsSelected, setConversationIsSelected] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("incoming");
  const [stars, setStars] = useState([]);
  const [trash, setTrash] = useState([]);
  const [status, setStatus] = useState("GetUserIncomingConversations");
  const [mcError, setMcError] = useState("");
  const [showIcons, setShowIcons] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [isSure, setIsSure] = useState(false);
  const [conversationId, setConversationId] = useState("");
  const [remainingHeight, setRemainingHeight] = useState("100vh");
  const [remainingHeightwithstatusBar, setRemainingHeightwithstatusBar] =
    useState("100vh");
  const [selectedConversationStatus, setSelectedConversationStatus] =
    useState(null);
  useEffect(() => {
    console.log(isMobileView);
    if (isMobileView == false) console.log("baleee");
  }, []);
  useEffect(() => {
    const updateHeight = () => {
      setTimeout(() => {
        const navbarElement = document.querySelector(".navbar");
        const subnavElement = document.querySelector(".subnav-container");
        const statusBarElement = document.querySelector(".status-bar");
        const navbarHeight = navbarElement ? navbarElement.offsetHeight : 0;
        const subnavHeight = subnavElement ? subnavElement.offsetHeight : 0;
        const statusBarHeight = statusBarElement
          ? statusBarElement.offsetHeight
          : 0;

        const totalHeight = window.innerHeight;
        const newHeight = totalHeight - (navbarHeight + subnavHeight);
        const newHeightwithstatusBar = newHeight - statusBarHeight;
        console.log(newHeight);
        console.log(newHeightwithstatusBar);
        setRemainingHeightwithstatusBar(`${newHeightwithstatusBar}px`);
        setRemainingHeight(`${newHeight}px`);
      }, 100);
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleIsSureModal = (conId) => {
    setIsSure(true);
    setConversationId(conId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "MessageCenter",
          apiName: status,
          paginated: true,
          pageNumber: pageNumber,
          pageSize: "50",
          loading: true,
        });

        if (
          response.data == "No Incoming Conversations Found For The User." ||
          response.data == "No Rejected Conversations Found For The User." ||
          response.data == "No Approved Conversations Found For The User."
        ) {
          setMCEmpty(true);
          setMcError(response.data);
          setConversations([]);
        } else {
          setMCEmpty(false);
          setConversations(response.data.conversations);
          setTotalPages(response.data.totalPages);
        }
        if (activeButton == "incoming") setShowIcons(true);
        else setShowIcons(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [status, pageNumber, forceRender]);

  useEffect(() => {
    if (activeButton === "accepted") {
      setStars(conversations.map(() => true));
    } else {
      setStars(conversations.map(() => false));
    }
    if (activeButton === "rejected") setTrash(conversations.map(() => true));
    else {
      setTrash(conversations.map(() => false));
    }
  }, [conversations, activeButton, forceRender]);



  const handleclick = async (conversationId, offerId, index) => {
    setSelectedConversationStatus(activeButton);
    setConversationIsSelected(true);
    setConversationId(conversationId);
    await makeApiCall({
      restapi: "post",
      controller: "MessageCenter",
      apiName: "MakeConversationIsReadTrue",
      hasId: conversationId,
    });
    setConversations(
      conversations.map((conv) =>
        conv.id === conversationId ? { ...conv, isRead: true } : conv
      )
    );
  };

  const handleconversationStatus = () => {
    setSelectedConversationStatus(true);
    setForceRender(!forceRender);
  };
  // const handleAcceptClick = async (index, conversationId) => {
  //   try {
  //     const response = await makeApiCall({
  //       restapi: "get",
  //       controller: "MessageCenter",
  //       apiName: "DetermineIncomingConversation",
  //       hasId: conversationId,
  //       status: "Accept",
  //       loading: true,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   setStars((prevStars) => {
  //     const newStars = [...prevStars];
  //     newStars[index] = !newStars[index];
  //     setForceRender((prev) => !prev);
  //     return newStars;
  //   });
  // };
  // const handleRejectClick = async (index, conversationId) => {
  //   try {
  //     const response = await makeApiCall({
  //       restapi: "get",
  //       controller: "MessageCenter",
  //       apiName: "DetermineIncomingConversation",
  //       hasId: conversationId,
  //       status: "Reject",
  //       loading: true,
  //     });
  //     setTrash((prevTrash) => {
  //       const newTrash = [...prevTrash];
  //       newTrash[index] = !newTrash[index];
  //       setForceRender((prev) => !prev);
  //       return newTrash;
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleListGroupClick = async (button) => {
    setPageNumber(1);
    setActiveButton(button);
    console.log(button);
    setTotalPages(1);
    if (button == "accepted") setStatus(`GetUserApprovedConversations`);
    else if (button == "incoming") setStatus(`GetUserIncomingConversations`);
    else if (button == "rejected") setStatus(`GetUserRejectedConversations`);
  };

  const handleDelete = async (conversationId) => {
    try {
      makeApiCall({
        restapi: "delete",
        controller: "MessageCenter",
        apiName: "DeleteConversation",
        hasId: conversationId,
        loading: true,
      });

      setForceRender((prev) => !prev);
      toast.success("Conversation deleted successfully!");
      const timerId = setTimeout(() => {}, 2000);

      return () => clearTimeout(timerId);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          minHeight: `${remainingHeight}`,
          maxHeight: `${remainingHeight}`,
        }}
      >
        <ToastContainer position="top-right" />
        <div className="w-100 d-flex justify-content-center mt-0">
          <div
            className={`conversation-sidebar bg-white border-end border-secondary-subtle ${
              isMobileView ? (!conversationIsSelected ? "w-100 mt-0" : "d-none") : ""
            }`}
          >
            <div className="d-flex justify-content-center pt-2 status-bar border-bottom border-secondary-subtle ps-0 my-0 ">
              <button
                className={`status-box-container font-weight-600 col-4 focus-hover-light rounded-top mt-1 border-0 pt-1 ${
                  activeButton === "incoming" ? "active" : ""
                }`}
                onClick={() => handleListGroupClick("incoming")}
              >
                <div className=" mx-auto status-box">Incoming</div>
                <div
                  className={`pt-1 mx-2 ${
                    activeButton == "incoming"
                      ? "border-45474B-bottom"
                      : "border-transparent-bottom"
                  }`}
                ></div>
              </button>
              <button
                className={`status-box-container font-weight-600 col-4 focus-hover-light rounded-top mt-1 border-0 pt-1 ${
                  activeButton === "accepted" ? "active" : ""
                }`}
                onClick={() => handleListGroupClick("accepted")}
              >
                <div className=" mx-auto status-box">Accepted</div>
                <div
                  className={`pt-1 mx-2 ${
                    activeButton == "accepted"
                      ? "border-45474B-bottom"
                      : "border-transparent-bottom"
                  }`}
                ></div>
              </button>
              <button
                className={`status-box-container font-weight-600 col-4 focus-hover-light rounded-top mt-1 border-0 pt-1 ${
                  activeButton === "rejected" ? "active" : ""
                }`}
                onClick={() => handleListGroupClick("rejected")}
              >
                <div className=" mx-auto status-box">Rejected</div>
                <div
                  className={`pt-1 mx-2 ${
                    activeButton == "rejected"
                      ? "border-45474B-bottom"
                      : "border-transparent-bottom"
                  }`}
                ></div>
              </button>
            </div>

            <div
              className="w-100 border-start border-end border-secondary-subtle mx-auto overflow-y-auto overflow-x-hidden grey-scroll"
              style={{
                minHeight: `${remainingHeightwithstatusBar}`,
                maxHeight: `${remainingHeightwithstatusBar}`,
              }}
            >
              <div className="col-md-12 mx-md-auto">
                {conversations &&
                  conversations.map((conversation, index) => (
                    <React.Fragment>
                      <div
                        key={conversation.id}
                        onClick={() =>
                          handleclick(
                            conversation.id,
                            conversation.intelligentOfferId,
                            index
                          )
                        }
                        className={`border-bottom border-light px-2 focus-hover-light conversation-low-dets rounded-4 py-1 ${
                          conversation.isRead ? "conversation-isread" : ""
                        } ${
                          conversation.id == conversationId
                            ? "bg-info-subtle"
                            : "bg-white"
                        } text-center cursor-pointer`}
                      >
                        {/* {showIcons && (
                      <div className="col-md-1">
                        <Row className="">
                          <button
                            disabled={
                              (stars[index] && true) || (trash[index] && true)
                            }
                            key={index}
                            className=" border-0 bg-transparent btn-sm focus-hover rounded-circle star col-6 d-flex justify-content-center"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAcceptClick(index, conversation.id);
                            }}
                          >
                            <FontAwesomeIcon
                              key={stars[index]}
                              icon={stars[index] ? faCheckSol : faCheckReg}
                              size="xl"
                              className="mx-md-2 py-2 my-auto mx-auto text-success"
                            />
                          </button>
                          <button
                            disabled={
                              (trash[index] && true) || (stars[index] && true)
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRejectClick(index, conversation.id);
                            }}
                            className=" border-0  bg-transparent btn-sm focus-hover rounded-circle star col-6 d-flex justify-content-center"
                          >
                            <FontAwesomeIcon
                              key={trash[index]}
                              size="xl"
                              icon={trash[index] ? faCloseSol : faCloseReg}
                              className="mx-md-2 py-1 my-auto mx-auto text-danger"
                            />
                          </button>
                        </Row>
                      </div>
                    )} */}
                        <div className="d-flex justify-content-start h6 mb-0  my-2">
                          <div className="align-self-center mx-2">
                            From: {conversation.sender}
                            {/* <FlagIcon
                              code={conversation.senderCountry}
                              className="rounded-1 py-1 mx-1 my-auto"
                              size={conversation.isRead ? 22 : 25}
                            /> */}
                          </div>
                          <div className=" align-self-center mx-2">
                            To: {conversation.receiver}
                            {/* <FlagIcon
                              code={conversation.receiverCountry}
                              className="rounded-1 py-1 mx-1 my-auto"
                              size={conversation.isRead ? 22 : 25}
                            /> */}
                          </div>
                          {conversation.isRead == false && (
                            <div className="bg-F4CE14 ms-auto me-md-2 font-45474B conversation-new-notif font-size-13 rounded-1 align-self-center">
                              New
                            </div>
                          )}
                        </div>

                        <div className="d-flex justify-content-between text-secondary my-2">
                          <div className="align-self-center col-md-9 px-1 text-truncate  text-start">
                            {conversation.subject}
                          </div>
                          <div className="align-self-center text-end col-md-3 px-2 font-size-13">
                            {new Date(conversation.createdAt).toLocaleString(
                              "en-US",
                              {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric",
                              }
                            )}
                          </div>
                        </div>

                        {/* <div className="col-md-1 ">
                      <button
                        key={index}
                        className=" bg-transparent   border-0 p-2 focus-hover rounded-pill"
                        onClick={(e) => {
                          e.stopPropagation();

                          handleIsSureModal(conversation.id);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="lg"
                          className=" text-danger "
                        />
                      </button>
                    </div> */}
                      </div>
                    </React.Fragment>
                  ))}
              </div>

              {mCEmpty && (
                <Container className="h6 py-3 px-4 my-4 text-center ">
                  {mcError}
                </Container>
              )}
            </div>
          </div>
          {!isMobileView && !conversationIsSelected && (
            <div className="col-8 mx-auto  pt-5 text-center">
              <img src={selectCnvImg} className="mt-5 pt-5" />
              <div className="h4 my-2">Select a conversation to start</div>
            </div>
          )}
          <React.Fragment>
            {conversationIsSelected && (
              <React.Fragment>
                <Conversation
                  AcceptedStatus={selectedConversationStatus}
                  conversationId={conversationId}
                  remainingHeight={remainingHeight}
                  handleconversationStatus={handleconversationStatus}
                  setConversationIsSelected={setConversationIsSelected}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
        <div className=" bg-info d-flex  justify-content-center ">
          <Modal
            centered
            className=" d-flex justify-content-center  text-center"
            show={isSure}
            onHide={() => {
              setIsSure(false);
            }}
          >
            <Modal.Header className="bg-light mb-0 h4">
              Are You Sure?
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSure(false);
                }}
                className="btn-close remove-icon"
              ></button>
            </Modal.Header>
            <Modal.Body className="bg-light text-center pt-4 rounded">
              <span className="h5  mx-5">
                You are permanently deleting this Conversation
              </span>
              <div className="mt-4">
                <Button
                  variant="light"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSure(false);
                    handleDelete(conversationId);
                  }}
                  className="border border-secondary  btn-lg my-3 mx-2"
                >
                  Yes, Delete Conversation
                </Button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSure(false);
                  }}
                  className=" btn-lg my-3 mx-2"
                >
                  Cancel, keep Conversation
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MessageCenter;
