import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import makeApiCall from "./../../apiCall";
import NoImage from "../../images/noImage.png";
import slidermain from "../../images/productslider1main.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ProductSlider1 = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(24 * 60 * 60 * 1000);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerProduct",
          apiName: "GetLast10Products",
        });
        setProducts(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1000) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTimer - 1000;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
      2,
      "0"
    );
    const seconds = String(totalSeconds % 60).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "transparent",
          borderRadius: "25px",
        }}
        onClick={onClick}
      >
        <FontAwesomeIcon
          className="bg-white rounded-circle p-1 border"
          icon={faArrowRight}
          style={{ color: "gold", fontSize: "24px" }}
        />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "transparent",
          borderRadius: "25px",
        }}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          className=" rounded-circle p-1 "
          style={{ color: "gold", fontSize: "24px" }}
        />
      </div>
    );
  }

  const handleProductClick = (productName, productId) => {
    navigate(`/user/products/${productName}`, { state: { productId } });
  };

  return (
    <React.Fragment>
      <div className="col-md-12 col-11 mx-auto my-5">
        <div className="productslider1">
          <div
            className="mx-auto rounded-5 bg-F4CE14 position-relative product-slider1-settings align-content-center"
            style={{
              paddingRight: isMobile ? "0" : "300px",
            }}
          >
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Swiper
                dir="rtl"
                className="px-2"
                spaceBetween={15}
                slidesPerView={'auto'}
           
              >
                {products &&
                  products.map((product) => (
                    <SwiperSlide className=" my-3 w-auto" key={product.id}>
                      <Card
                        onClick={() =>
                          handleProductClick(product.name, product.id)
                        }
                        className="text-center product-slider-1-item cursor-pointer rounded-4 bg-light"
                      >
                        <h6 className="pt-3 rounded-top-5 single-line-text text-start ps-4 text-dark px-1 ">
                          <span className="font-weight-600 h5">
                            {product.name}
                          </span>{" "}
                          <span className="font-size-13 text-secondary">
                            ({product.productPurpose})
                          </span>
                        </h6>
                        <Card.Img
                          className="product-slider-1-img object-fit-contain mx-auto mt-3"
                          src={
                            product.images
                              ? `data:image/png;base64,${product.images}`
                              : NoImage
                          }
                        />
                        <Card.Body className="p-2">
                          <Card.Text
                            className={` mb-0 text-start font-45474B  ms-2 mt-1 ${
                              product.productType &&
                              product.productType.length >= 16
                                ? "medium-category-name"
                                : "large-category-name"
                            }`}
                          >
                            <span className="h5">{product.productType}</span>
                          </Card.Text>
                          <Card.Text className=" h6 mb-0 pt-2 text-start font-45474B ms-2 mt-2">
                            <span className="h5">
                              {product.productUnitType}
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
            {!isMobile && (
              <div className="fixed-slider-item rounded-5 choose-the-best-ismobile">
                <Card
                  className="text-center timer-slider-card"
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  <div
                    className=""
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      marginTop: "16px",
                    }}
                  >
                    <h2 className=" text-dark">{formatTime(timer)}</h2>
                  </div>
                  <Card.Img
                    className="object-fit-contain mx-auto choose-the-best-image"
                    src={slidermain}
                  />
                </Card>
                <h4
                  className="pt-4 rounded-top-5 single-line-text px-1"
                  style={{ fontFamily: "Inter" }}
                >
                  Choose the best
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductSlider1;
