import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import confrence from "../../images/confrence.png";
import ship from "../../images/ship.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slider3 = () => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date().getTime() + 86400000;
    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTime({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const settings = {
    className: "",
    slidesToShow: 2,
    centerMode: true,
    centerPadding: "5%",
    arrows: false,
    swipeToSlide: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,

    responsive: [
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 2,
          centerPadding: "5%",
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "20%",
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5%",
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <Slider {...settings}>
        <div className="slider-item-4">
          <div className="slider-4 rounded-4  my-5 bg-F4CE14">
            <div className="d-flex justify-content-center ">
              <div className="col-4">
                <div className="col-10 mx-auto">
                  <div className="text-center mt-2 py-1  fw-bold bg-dark text-warning rounded-5">
                    Status Panel
                  </div>
                  <div className="text-center mt-4">
                    To access your user panel, select your order in advance
                  </div>
                </div>
              </div>
              <div className="col-8">
                <img className=" mx-auto ship-image" src={ship} />
              </div>
            </div>
            <div className="h2  my-3 fw-bold text-center">
              shipment and order status
            </div>
          </div>
        </div>
        <div className="slider-item-4">
          <div className="slider-4 rounded-4  my-5 bg-495E57">
            <div className="d-flex justify-content-center ">
              <div className="col-8">
                <img className=" mx-auto ship-image" src={confrence} />
              </div>
              <div className="col-4">
                <div className="text-center mx-auto mt-2 py-1 col-10  fw-bold bg-secondary-subtle font-495E57 rounded-5">
                  Thursday-Friday
                </div>
                <div className="text-center text-light col-12 mt-4">
                  Sessions are recorded and participants can send their comments
                  if they register
                </div>
              </div>
            </div>
            <div className="h2 fw-bold text-light my-3 text-center">
              Participate in User meetings
            </div>
          </div>
        </div>
      </Slider>
    </React.Fragment>
  );
};

export default Slider3;
