import React, { useState, useEffect } from "react";
import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import companyBasicImg from "../../../images/companyBasic.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faClose,
  faTimes,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import editImg from "../../../images/edit.svg";
import makeApiCall from "./../../../apiCall";
import closeBtn from "../../../icons/closeBtn.svg";
import addBtn from "../../../icons/addBtn.svg";
import CertificateImageUploadModal from "./certificateImageUpload";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const EditCompanyDetails = ({
  companyDets,
  companyId,
  certificates,
  handleEditMode,
}) => {
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const [showSubcategoryOptions, setShowSubcategoryOptions] = useState(false);
  const [countries, setCountries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );
  const [categories, setCategories] = useState(
    JSON.parse(localStorage.getItem("categories"))
  );
  const [categoryId, setCategoryId] = useState(0);
  const [image, setImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [certificateLinks, setCertificateLinks] = useState([
    { spec: "", value: "" },
  ]);
  const [formData, setFormData] = useState({
    name: companyDets.name || "",
    address: companyDets.address || "",
    category: companyDets.category || "",
    countryName: companyDets.countryName || "",
    website: companyDets.website || "",
    telephoneNumber: companyDets.telephoneNumber || "",
    subCategory: companyDets.subCategory || [],
    bio: companyDets.bio || "",
    email: companyDets.email || "",
    image: companyDets.image || null,
  });

  const [originalData, setOriginalData] = useState({ ...formData });
  const [showModal, setShowModal] = useState(false);
  const [certificatesState, setCertificatesState] = useState(certificates);

  useEffect(() => {
    setCertificatesState(certificates);
  }, [certificates]);
  useEffect(() => {
    setImage(companyDets.image);
  }, []);

  const handleAddCertificateImage = (file, imageType) => {
    const updatedImages = { ...certificatesState };

    switch (imageType) {
      case "cap":
        updatedImages.cap = [...updatedImages.cap, file];
        break;
      case "lc":
        updatedImages.lc = [...updatedImages.lc, file];
        break;
      case "dp":
        updatedImages.dp = [...updatedImages.dp, file];
        break;
      case "otherCertificateImages":
        updatedImages.otherCertificateImages = [
          ...updatedImages.otherCertificateImages,
          file,
        ];
        break;
      default:
        console.error("Unknown image type");
    }
    setCertificatesState(updatedImages);
  };

  useEffect(() => {
    console.log(certificates);
    const fetchCategories = async () => {
      try {
        const currentCategory = categories.find(
          (cat) => cat.name === formData.category
        );
        setCategoryId(currentCategory ? currentCategory.id : 0);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
  }, [formData.category]);

  useEffect(() => {
    const fetchSubcatData = async () => {
      try {
        if (categoryId !== 0) {
          const response = await makeApiCall({
            restapi: "get",
            controller: "CustomerCompany",
            apiName: "GetSubCategory",
            hasId: categoryId,
          });
          setSubcategories(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSubcatData();
  }, [categoryId]);

  useEffect(() => {
    const transformedLinks = Object.keys(
      certificates.otherCertificateLinks
    ).map((key) => ({
      spec: key,
      value: certificates.otherCertificateLinks[key],
    }));
    setCertificateLinks(transformedLinks);
  }, [certificates]);

  useEffect(() => {
    setFormData({
      name: companyDets.name || "",
      address: companyDets.address || "",
      category: companyDets.category || "",
      countryName: companyDets.countryName || "",
      website: companyDets.website || "",
      telephoneNumber: companyDets.telephoneNumber || "",
      subCategory: companyDets.subCategory || [],
      bio: companyDets.bio || "",
      email: companyDets.email || "",
      image: companyDets.image || null,
    });
    setOriginalData({
      name: companyDets.name || "",
      address: companyDets.address || "",
      category: companyDets.category || "",
      countryName: companyDets.countryName || "",
      website: companyDets.website || "",
      telephoneNumber: companyDets.telephoneNumber || "",
      subCategory: companyDets.subCategory || [],
      bio: companyDets.bio || "",
      email: companyDets.email || "",
      image: companyDets.image || null,
    });
  }, [companyDets]);

  const handleCertificateLinksChange = (index, field, value) => {
    const updatedSpecifications = [...certificateLinks];
    updatedSpecifications[index][field] = value;
    setCertificateLinks(updatedSpecifications);
    console.log(updatedSpecifications);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        console.log(reader.result);
        setFormData((prevData) => ({
          ...prevData,
          image: file,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const base64ToFile = (base64String, fileName) => {
    const mimeType = "image/jpeg";
    const base64Data = base64String.startsWith("data:")
      ? base64String.split(",")[1]
      : base64String;

    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new File([byteArray], fileName, { type: mimeType });
  };

  const handleSaveClick = async () => {
    const data = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== originalData[key]) {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((item) => {
            data.append(`${key}[]`, item);
          });
        } else {
          data.append(key, formData[key]);
        }
      }
    });

    const otherCertificateLinks = certificateLinks.reduce((acc, link) => {
      if (link.spec && link.value) {
        acc[link.spec] = link.value;
      }
      return acc;
    }, {});

    data.append("otherCertificateLinks", JSON.stringify(otherCertificateLinks));

    console.log(certificatesState);
    Object.keys(certificatesState).forEach((key) => {
      if (Array.isArray(certificatesState[key])) {
        certificatesState[key].forEach((item) => {
          if (typeof item === "string") {
            const file = base64ToFile(item);
            data.append(`${key.toUpperCase()}`, file);
          } else {
            data.append(`${key.toUpperCase()}`, item);
          }
        });
      }
    });
    try {
      const response = await makeApiCall({
        restapi: "put",
        controller: "CustomerCompany",
        apiName: "UpdateCompany",
        hasId: companyId,
        data: data,
        loading: true,
      });

      console.log(response);
      handleEditMode(false, true);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
        });
      } else {
        toast.error("An unexpected error occurred.");
      }
      console.error("Error updating company:", error);
    }
  };

  const handleCancelClick = () => {
    handleEditMode(false);
  };

  const handleCategoryChange = async (e) => {
    const { value } = e.target;
    setSelectedCategory(value);
    setSelectedSubcategories([]);
    setFormData((prevData) => ({
      ...prevData,
      category: value,
      subCategory: [],
    }));
    try {
      const selectedCategory = categories.find(
        (category) => category.name === value
      );
      if (selectedCategory) {
        const categoryId = selectedCategory.id;

        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "GetSubCategory",
          hasId: categoryId,
        });
        const subCategoriesData = response.data;
        setSubcategories(subCategoriesData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubCategoryChange = (e) => {
    const { value, checked } = e.target;
    setSelectedSubcategories((prev) =>
      checked ? [...prev, value] : prev.filter((sub) => sub !== value)
    );
    setFormData((prevData) => ({
      ...prevData,
      subCategory: checked
        ? [...prevData.subCategory, value]
        : prevData.subCategory.filter((sub) => sub !== value),
    }));
  };

  const handleAddLink = () => {
    setCertificateLinks((prevLinks) => [...prevLinks, { spec: "", value: "" }]);
  };
  const renderLinks = () => {
    const allEmpty = certificateLinks.every(
      (link) => link.spec === "" && link.value === ""
    );

    const handleRemoveLink = (index) => {
      setCertificateLinks((prevLinks) =>
        prevLinks.filter((_, i) => i !== index)
      );
    };

    return (
      <React.Fragment>
        <div className="w-100 d-flex flex-wrap py-1 px-2">
          {certificateLinks.map((link, index) => (
            <React.Fragment key={index}>
              <div className="d-flex align-items-center col-6 ps-3 pe-2 my-2">
                <Form.Control
                  className="shadow-sm rounded-3"
                  value={link.spec}
                  onChange={(event) =>
                    handleCertificateLinksChange(
                      index,
                      "spec",
                      event.target.value
                    )
                  }
                  placeholder="Enter link name"
                />
              </div>
              <div className="position-relative d-flex align-items-center col-6 pe-3 ps-2 my-2">
                <Form.Control
                  className="shadow-sm rounded-3"
                  value={link.value}
                  onChange={(event) =>
                    handleCertificateLinksChange(
                      index,
                      "value",
                      event.target.value
                    )
                  }
                  placeholder="Enter link value"
                />

                <img
                  src={closeBtn}
                  className=" rounded-circle cursor-pointer bg-white end-0 me-4 pe-2 icon-30 position-absolute align-self-center"
                  onClick={() => handleRemoveLink(index)}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        {certificateLinks.length == 0 && (
          <div className="text-center mt-2">
            <Button
              className="w-50 mx-auto my-3 text-center py-2 border bg-white text-dark"
              onClick={handleAddLink}
            >
              Add a certificate link
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  };

  const handleRemoveImage = (index, type) => {
    setCertificatesState((prevState) => {
      const updatedImages = [...prevState[type]];
      updatedImages.splice(index, 1);
      return {
        ...prevState,
        [type]: updatedImages,
      };
    });
  };

  const renderCertificateImages = (images, title, handleRemoveImage) => {
    return (
      <>
        {images.length > 0 && (
          <>
            {images.map((image, index) => (
              <div
                className="col-md-3 col-5 mx-auto edit-certificate-image cursor-pointer"
                key={index}
              >
                <img
                  src={image ? `data:image/png;base64,${image}` : editImg}
                  className="w-100 border object-fit-contain rounded bg-white h-75 "
                  alt={title}
                />
                <div
                  className="edit-certificate-image-overlay"
                  onClick={() => handleRemoveImage(index)}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className=""
                    color="red"
                    size="2xl"
                  />
                </div>
                <h5 className="text-center my-1">{title}</h5>
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <ToastContainer className="" />
      <div>
        <div className="col-1 text-end ms-auto mx-3">
          <Button
            className="mt-4 border-0 me-md-5 bg-transparent"
            onClick={handleCancelClick}
          >
            <FontAwesomeIcon icon={faClose} size="2xl" color="red" />
          </Button>
        </div>
        <Form className="col-md-11 mx-auto my-4">
          <div className="mx-auto col-md-10 col">
            <div className="d-md-flex justify-content-md-end position-relative ">
              <div
                className={`${
                  !isMobileView
                    ? "edit-company-img position-absolute"
                    : ""
                } align-content-start text-center`}
              >
                <img
                  className="icon-150 rounded-4 object-fit-contain border bg-white p-1"
                  src={
                    !image
                      ? companyBasicImg
                      : image.startsWith("data:image")
                      ? image
                      : `data:image/png;base64,${image}`
                  }
                  alt="Company"
                />
                <div className="text-center mt-2">
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="imageUpload"
                  />
                  <label htmlFor="imageUpload" className="cursor-pointer">
                    <img src={editImg} className="col-2 mx-2" />
                    Edit Image
                  </label>
                </div>
              </div>

              <div className="pt-2 col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Company Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    className="py-2 bg-light shadow-sm"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter company name"
                  />
                </FormGroup>
              </div>
              <div className="pt-2 col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Company Address
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    className="py-2 bg-light shadow-sm"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder="Enter company address"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="d-md-flex justify-content-md-end">
              <div className="col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Company Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    className="py-2 bg-light shadow-sm"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter company email"
                  />
                </FormGroup>
              </div>
              <div className="col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Company Telephone Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="telephoneNumber"
                    className="py-2 bg-light shadow-sm"
                    value={formData.telephoneNumber}
                    onChange={handleChange}
                    placeholder="Enter company telephone number"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="d-md-flex justify-content-md-end">
              <div className="col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Category
                  </Form.Label>
                  <Form.Select
                    className="border rounded py-2 bg-light shadow-sm"
                    name="category"
                    value={formData.category}
                    onChange={handleCategoryChange}
                    required
                  >
                    <option value="">Select category</option>
                    {categories &&
                      categories.map((category) => (
                        <option key={category.id} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                  </Form.Select>
                </FormGroup>
              </div>
              <div className="col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Company SubCategory
                  </Form.Label>
                  <div className="position-relative">
                    <Button
                      className={`w-100 text-start shadow-sm py-2 border bg-light text-dark justify-content-between d-flex ${
                        showSubcategoryOptions && "rounded-bottom-0"
                      }`}
                      onClick={() =>
                        setShowSubcategoryOptions(!showSubcategoryOptions)
                      }
                    >
                      {` ${formData.subCategory.length} Subcategories selected`}
                      <span className="pe-1">
                        {" "}
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          size="sm"
                          className=""
                          color="#45474B"
                        />
                      </span>
                    </Button>
                    <div
                      className={`position-absolute w-100 border-top-0 border p-2 bg-white rounded-bottom shadow-sm ${
                        showSubcategoryOptions ? "d-block" : "d-none"
                      }`}
                      style={{ maxHeight: "300px", overflowY: "auto" }}
                    >
                      {subcategories.map((subCat) => (
                        <Form.Check
                          key={subCat.name}
                          type="checkbox"
                          label={subCat.name}
                          value={subCat.name}
                          checked={formData.subCategory.includes(subCat.name)}
                          onChange={handleSubCategoryChange}
                        />
                      ))}
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
            <div className="d-md-flex justify-content-md-end">
              <div className="col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Country
                  </Form.Label>
                  <Form.Select
                    name="countryName"
                    className="border rounded py-2 bg-light shadow-sm"
                    value={formData.countryName}
                    onChange={handleChange}
                  >
                    <option value="">Select a country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </FormGroup>
              </div>
              <div className="col-md-4 col-11 mx-md-0 mx-auto">
                <FormGroup className="col-md-11 my-3 mx-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Website
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="website"
                    className="py-2 bg-light shadow-sm"
                    value={formData.website}
                    onChange={handleChange}
                    placeholder="Enter website URL"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="col d-md-flex justify-content-center">
              <FormGroup className="col-md-7 col-11 my-2 mx-auto">
                <div className="col-md-10 ms-md-auto">
                  <Form.Label className="font-weight-600 font-45474B">
                    Certificate Links
                  </Form.Label>
                </div>

                <div className="col-md-10 border shadow-sm  rounded-3 ms-md-auto mx-md-3 grey-scroll bg-light edit-company-bio">
                  <div className="d-flex">
                    <div className="border-bottom border-dark ms-4 me-2 py-2 col rounded-top-3 rounded-end-0">
                      Name
                    </div>
                    <div className="border-bottom border-dark ms-2 me-4 py-2 col rounded-top-3 rounded-start-0">
                      Link
                      <img
                        src={addBtn}
                        className=" float-end mt-1 icon-20 cursor-pointer"
                        onClick={handleAddLink}
                      />
                    </div>
                  </div>
                  <div>{renderLinks()}</div>
                </div>
              </FormGroup>

              <FormGroup className="col-md-5 col-11 my-2 mx-auto align-self-start">
                <Form.Label className="font-weight-600 font-45474B">
                  Bio
                </Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  name="bio"
                  className="py-2 bg-light shadow-sm edit-company-bio "
                  value={formData.bio}
                  onChange={handleChange}
                  placeholder="Enter company bio"
                />
              </FormGroup>
            </div>
            <div className="col-md-10 col-11 mx-auto">
              <div>
                <Row className="bg-light flex-wrap col-md-9 mx-auto mt-4 mb-3 py-3 rounded-3 border shadow-sm edit-certificate-images-container">
                  {certificatesState.cap.length === 0 &&
                  certificatesState.lc.length === 0 &&
                  certificatesState.dp.length === 0 &&
                  certificatesState.otherCertificateImages.length === 0 ? (
                    <p className="text-center w-100 h5 mb-0">
                      No certificates added
                    </p>
                  ) : (
                    <>
                      {renderCertificateImages(
                        certificatesState.cap,
                        "CAP",
                        (index) => handleRemoveImage(index, "cap")
                      )}
                      {renderCertificateImages(
                        certificatesState.lc,
                        "LC",
                        (index) => handleRemoveImage(index, "lc")
                      )}
                      {renderCertificateImages(
                        certificatesState.dp,
                        "DP",
                        (index) => handleRemoveImage(index, "dp")
                      )}
                      {renderCertificateImages(
                        certificatesState.otherCertificateImages,
                        "Other Certificates",
                        (index) =>
                          handleRemoveImage(index, "otherCertificateImages")
                      )}
                    </>
                  )}
                </Row>

                <div className="mx-auto d-flex justify-content-center">
                  <Button
                    className="border-dark bg-white text-dark col-md-4 text-center mx-auto"
                    onClick={() => setShowModal(true)}
                  >
                    Add Image
                  </Button>
                </div>

                <CertificateImageUploadModal
                  show={showModal}
                  handleClose={() => setShowModal(false)}
                  handleAddImage={handleAddCertificateImage}
                />
              </div>
            </div>
          </div>
          <div className="mx-auto col-md-1 col-4">
            <Button
              onClick={handleSaveClick}
              className="text-center btn-outline-success btn-light w-100 mt-4 btn-lg"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default EditCompanyDetails;
