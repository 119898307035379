import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({
  PageNumber,
  TotalPages,
  handlePaginationClick,
}) => {
  const renderPageNumbers = () => {
    const totalPagesToShow = 3;

    let startPage = Math.max(1, PageNumber - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(TotalPages, startPage + totalPagesToShow - 1);

    if (TotalPages - endPage < Math.floor(totalPagesToShow / 2)) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    const pageNumbers = [];

    // Render Button 1 if startPage is greater than 1
    if (startPage > 1) {
      pageNumbers.push(
        <button
          key={1}
          className={`btn mx-2  border-0 bg-transparent  my-0`}
          onClick={() => handlePaginationClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`btn mx-2 my-1  ${
            PageNumber === i
              ? " text-decoration-underline fw-bold"
              : "bg-transparent "
          }`}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }

    // Render Button TotalPages if endPage is less than TotalPages
    if (endPage < TotalPages) {
      if (endPage < TotalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(
        <button
          key={TotalPages}
          className={`btn mx-2  border-0 bg-transparent  my-0`}
          onClick={() => handlePaginationClick(TotalPages)}
        >
          {TotalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <Pagination className="  mx-auto   text-center">
      <div className="pagination-div border-45474B mx-auto  rounded-3 font-45474B">
        <button
          className="btn mx-2 col rounded-0 border-secondary border-0 bg-transparent border-end px-4 my-0"
          onClick={() => handlePaginationClick(Math.max(1, PageNumber - 1))}
          disabled={PageNumber === 1}
        >
          {"< Previous"}
        </button>

        {renderPageNumbers()}

        <button
          className="btn mx-2 rounded-0 border-secondary border-0 bg-transparent border-start px-4 my-0"
          onClick={() =>
            handlePaginationClick(Math.min(TotalPages, PageNumber + 1))
          }
          disabled={PageNumber === TotalPages}
        >
          {"Next >"}
        </button>
      </div>
    </Pagination>
  );
};

export default PaginationComponent;
