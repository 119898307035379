import React, { useState, useEffect } from "react";
import { Button, Alert, Modal, Spinner, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import AddProduct from "./addProduct";
import "react-toastify/dist/ReactToastify.css";
import CompanyLowDets from "./companyLowDets";
import companyImg from "../../../images/companyBasic.png";
import PaginationComponent from "../../pagination/pagination";
import makeApiCall from "../../../apiCall";
import plusImg from "../../../images/plus.svg";
const UserCompanies = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [showNoCompaniesAlert, setShowNoCompaniesAlert] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [PageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    fetchCompanies();
  }, [PageNumber]);

  useEffect(() => {
    if (submitSuccess) {
      setShowModal(false);
      toast.success("Product added successfully!");
      const timerId = setTimeout(() => {
        setSubmitSuccess(false);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [submitSuccess]);

  const fetchCompanies = async () => {
    try {
      const response = await makeApiCall({
        restapi: "get",
        controller: "CustomerCompany",
        apiName: "GetUserCompaniesPaginated",
        paginated: true,
        pageNumber: PageNumber,
        pageSize: 15,
        loading: true,
      });

      console.log(response.data);
      const companyData = response.data.companies;

      setCompanies(companyData);

      setTotalPages(response.data.totalPages);
    } catch (error) {
      setShowNoCompaniesAlert(true);
      console.error("Error fetching companies:", error);
    }
  };

  const handleAddProduct = () => {
    if (!submitSuccess) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);

    setTimeout(() => {
      setSubmitSuccess(true);
    }, 500);
  };

  const handleAddCompany = () => {
    navigate("/registerCompany");
  };

  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleCompanyClick = (companyId, companyName) => {
    navigate("/user/products", { state: { companyId, companyName } });
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <React.Fragment>
      {showNoCompaniesAlert ? (
        <React.Fragment>
          <Alert variant="danger" className="h4 my-5">
            You have not registered a company yet. You need to add your company
            in order to see products.
          </Alert>
          <div className=" text-center my-5">
            <Button
              variant="warning"
              className=" col-2 btn-lg"
              onClick={handleAddCompany}
            >
              Add Company
              <img src={plusImg} className=" mx-1" alt="Add Company" />
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <div>
          <Button
            className="py-2 bg-transparent focus-hover-light d-flex justify-content-start text-dark border-1  text-start mb-3 mt-5 text-fit-content border-dark"
            style={{ marginLeft: "90px" }}
            href="/registercompany"
          >
            <div className="h5 mb-0 font-45474B  font-weight-600">
              Add Company
              <img src={plusImg} className=" ms-2" alt="Add Company" />
            </div>
          </Button>
          <Row className="col-md-12 mx-auto d-flex justify-content-center">
            {companies.map((company) => (
              <CompanyLowDets
                onClick={() => handleCompanyClick(company.id, company.name)}
                title={company.name}
                image={
                  company.image
                    ? `data:image/png;base64,${company.image}`
                    : companyImg
                }
                country={company.countryName}
                category={company.category}
                CountryCode={company.countryCode}
              ></CompanyLowDets>
            ))}
          </Row>
          <div className="my-5 ms-4 d-flex justify-content-center">
            <PaginationComponent
              PageNumber={PageNumber}
              TotalPages={totalPages}
              handlePaginationClick={handlePaginationClick}
            />
          </div>
        </div>
      )}

      <ToastContainer />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className=" justify-content-end  bg-light">
          <div
            className="modal-close-btn btn"
            onClick={() => setShowModal(false)}
          >
            &#10006;
          </div>
        </Modal.Header>
        <AddProduct
          onSubmitSuccess={() => setSubmitSuccess(true)}
          companies={companies}
        />
      </Modal>
    </React.Fragment>
  );
};

export default UserCompanies;
