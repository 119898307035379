import React, { useEffect } from "react";
import "flag-icons/css/flag-icons.min.css";

const CountryLowDets = ({
  id,
  countryName,
  countryCode,
  fontSize,
  companiesCount,
}) => {
  const lowerCaseCountryCode = countryCode.toLowerCase();
  return (
    <React.Fragment>
      <table
        className="font-45474B bg-F5F7F8 my-4 mx-md-4 mx-0 position-relative country-low-dets"
      
      >
        <tbody>
          <tr>
            <td colSpan={2} className="p-0 m-0">
              <span
                className={`fi fi-${lowerCaseCountryCode} country-low-dets-img`}
                style={{
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12PX",
                  width: "100%",
                  height: "100%",
                  display: "block",
                  backgroundSize: "cover",
                }}
              ></span>
            </td>
          </tr>
          <tr>
            <td
              className={`text-center fw-bold country-low-dets-name px-3 py-2`}
            >
              {countryName}
            </td>
          </tr>
          <tr
            className="position-absolute  top-0 end-0 mt-3 me-4 bg-light rounded-pill"
            style={{ boxShadow: "2px 2px 2px rgb(43,43,43,1.5)" }}
          >
            <td className="text-secondary px-2">{companiesCount}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CountryLowDets;
