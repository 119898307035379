import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CompanyLowDets from "./companyLowDets";
import companyBasicImg from "../../../images/companyBasic.png";
import makeApiCall from "../../../apiCall";
import PaginationComponent from "../../pagination/pagination";
import { FlagIcon } from "react-flag-kit";
import { Row } from "react-bootstrap";

const CountryTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { country } = useParams();
  const [companies, setCompanies] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CrawledCompany",
          apiName: `Get${country}Companies`,
          paginated: true,
          pageNumber: pageNumber,
          pageSize: 15,
          loading: true,
        });

        setCompanies(response.data.companies);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [pageNumber]);

  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleRecordClick = (id) => {
    navigate(`${id}`);
  };

  return (
    <React.Fragment>
      <div className="col-md-6 mt-4 fw-bold text-center h3 mx-auto my-2 d-md-flex justify-content-center">
        <div className="col-md-6">
          <FlagIcon
            className="rounded "
            code={
              country == "iran"
                ? "IR"
                : country == "india"
                ? "IN"
                : country == "china"
                ? "CN"
                : ""
            }
            size={130}
            width={220}
          />
        </div>

        <div className="align-self-center mx-auto  col-md-6">
          <div
            className={`fw-bold mt-md-0 mt-3 ${
              country.length > 25
                ? "h4"
                : country.length > 20
                ? "h3"
                : country.length > 15
                ? "h2"
                : "h1"
            }`}
          >
            {country}
          </div>
        </div>
      </div>
      <div className=" ">
        <div className=" col-12 col-md-12 mx-auto">
          <Row className="col-md-10 mx-auto ">
            {companies &&
              companies.map((company) => (
                <div className="col-md-4 col-12 " key={company.id}>
                  <CompanyLowDets
                    image={companyBasicImg}
                    title={company.name}
                    country={country}
                    CountryCode={
                      country == "China"
                        ? "CN"
                        : country == "Iran"
                        ? "IR"
                        : country == "India"
                        ? "IN"
                        : ""
                    }
                    category={company.category && company.category.name}
                    onClick={() => handleRecordClick(company.id)}
                  />
                </div>
              ))}
          </Row>
        </div>

        <div className="d-flex justify-content-center my-5">
          <PaginationComponent
            PageNumber={pageNumber}
            TotalPages={totalPages}
            handlePaginationClick={handlePaginationClick}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CountryTable;
