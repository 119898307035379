import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faClose,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

const Filter = ({ filterData, setFilterData }) => {
  const [isFixed, setIsFixed] = useState(false);
  const [filterTop, setFilterTop] = useState(130);
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const [navbarHeight, setNavbarHeight] = useState(100);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      setTimeout(() => {
        const navbarElement = document.querySelector(".navbar");
        const subnavElement = document.querySelector(".subnav-container");

        const navbarHeight = navbarElement ? navbarElement.offsetHeight : 0;
        const subnavHeight = subnavElement ? subnavElement.offsetHeight : 0;
        console.log(navbarHeight, subnavHeight);
        setNavbarHeight(navbarHeight + subnavHeight);
      });
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [document.querySelector(".subnav-container")]);

  useEffect(() => {
    console.log("adadwwd");
    if (isMobileView) setFilterTop(navbarHeight + 20);
  }, [navbarHeight]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      const pageHeight = document.documentElement.scrollHeight;
      const maxScrollPosition = pageHeight -  700;

      if (
        scrollTop > (isMobileView ? navbarHeight + 10 : 120) &&
        scrollTop < maxScrollPosition
      ) {
        setIsFixed(true);
        setFilterTop(10);
      } else if (scrollTop >= maxScrollPosition) {
        setIsFixed(false);

        setFilterTop(maxScrollPosition + 10);
      } else {
        setIsFixed(false);
        if (!isMobileView) {
          setFilterTop(130);
        } else {
          setFilterTop(navbarHeight + 20);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileView, navbarHeight]);

  const handleFilterRemove = (e, type) => {
    e.stopPropagation();

    setFilterData((prevData) => ({
      ...prevData,
      checkedData: {
        ...prevData.checkedData,
        [type]: [],
      },
    }));
  };

  const handleFilterSelect = (filter, type) => {
    setFilterData((prevData) => ({
      ...prevData,
      checkedData: {
        ...prevData.checkedData,
        [type]: prevData.checkedData[type].includes(filter)
          ? prevData.checkedData[type].filter((item) => item !== filter)
          : [...prevData.checkedData[type], filter],
      },
    }));
  };

  const handleExpandThisTitle = (titleName) => {
    setFilterData((prevData) => ({
      ...prevData,
      titles: prevData.titles.map((title) =>
        title.name === titleName
          ? { ...title, expanded: !title.expanded }
          : title
      ),
    }));
  };
  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <React.Fragment>
      {isMobileView ? (
        <React.Fragment>
          {isOpen && (
            <div
              onClick={() => setIsOpen(false)}
              className="grey-transparent-bg"
            ></div>
          )}
          {navbarHeight && (
            <div
              style={{ top: filterTop }}
              className={` px-2 d-flex rounded-pill ms-3 zindex-1 bg-white justify-content-center py-1 border border-dark ${
                isFixed ? "position-fixed" : "position-absolute "
              }`}
              onClick={handleFilterClick}
            >
              <FontAwesomeIcon
                className="align-self-center mx-1 pt-1"
                icon={faFilter}
                color="black"
              />
              Filters
            </div>
          )}

          <div
            className={`bg-white rounded-top-5 border-top overflow-y-auto position-fixed filter-mobile-container ${
              isOpen ? "open" : ""
            }`}
          >
            <div className="text-center mt-2 mb-0 w-25 me-auto">Filters</div>
            <hr className="mt-1 border-secondary border-2" />
            <div className="text-start w-100">
              {filterData.titles.map((title) => (
                <div
                  className={`filter-section mx-4 border-top filter-category grey-scroll
              ${title.expanded ? "expanded" : ""} 
     
          my-2`}
                >
                  <div
                    className="filter-section-header d-flex position-absolute justify-content-between rounded align-items-center py-2"
                    onClick={() => handleExpandThisTitle(title.name)}
                  >
                    <span className="mb-0 ps-1">
                      {title.name}
                      <FontAwesomeIcon
                        icon={title.expanded ? faAngleUp : faAngleDown}
                        className=" ms-2"
                      />
                    </span>
                    <div className="">
                      {" "}
                      {filterData.checkedData[title.name].length > 0 && (
                        <FontAwesomeIcon
                          icon={faClose}
                          size="lg"
                          className="cursor-pointer"
                          onClick={(e) => handleFilterRemove(e, title.name)}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`filter-section-checkbox-container`}>
                    {filterData.data[title.name]?.length > 0 &&
                      filterData.data[title.name].map((filter) => (
                        <div
                          key={filter.key}
                          className="form-check  text-start"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`category-${filter.key}`}
                            checked={filterData.checkedData[
                              title.name
                            ].includes(filter.name)}
                            onChange={() =>
                              handleFilterSelect(filter.name, title.name)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`category-${filter.key}`}
                          >
                            {filter.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className={`filter-section-container rounded-3 ${
              isFixed ? "fixed-position" : ""
            }`}
            style={{
              top: `${filterTop}px`,

              width: "250px",
            }}
          >
            <div className="filters-title text-start d-flex ps-4 py-2 border-bottom border-dark mb-0">
              Filters
            </div>

            <div className="text-start w-100">
              {filterData.titles.map((title) => (
                <div
                  className={`filter-section filter-category grey-scroll
              ${title.expanded ? "expanded" : ""} 
     
          my-2`}
                >
                  <div
                    className="filter-section-header d-flex position-absolute justify-content-between rounded align-items-center py-2"
                    onClick={() => handleExpandThisTitle(title.name)}
                  >
                    <span className="mb-0 ps-1">
                      {title.name}
                      <FontAwesomeIcon
                        icon={title.expanded ? faAngleUp : faAngleDown}
                        className=" ms-2"
                      />
                    </span>
                    <div className="">
                      {" "}
                      {filterData.checkedData[title.name].length > 0 && (
                        <FontAwesomeIcon
                          icon={faClose}
                          size="lg"
                          className="cursor-pointer"
                          onClick={(e) => handleFilterRemove(e, title.name)}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`filter-section-checkbox-container`}>
                    {filterData.data[title.name]?.length > 0 &&
                      filterData.data[title.name].map((filter) => (
                        <div
                          key={filter.key}
                          className="form-check  text-start"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`category-${filter.key}`}
                            checked={filterData.checkedData[
                              title.name
                            ].includes(filter.name)}
                            onChange={() =>
                              handleFilterSelect(filter.name, title.name)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`category-${filter.key}`}
                          >
                            {filter.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Filter;
