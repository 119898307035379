import React, { useState, useEffect } from "react";
import { Button, Alert, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import AddProduct from "./addProduct";
import "react-toastify/dist/ReactToastify.css";
import CompanyDetails from "./companyDetails";
import PaginationComponent from "../../pagination/pagination";
import ProductLowDets from "./productLowDets";
import makeApiCall from "../../../apiCall";
import EditCompanyDetails from "./editCompanyDetails";
const Products = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [companyProducts, setCompanyProducts] = useState([]);
  const [certificateId, setCertificateId] = useState("");
  const [companyDets, setCompanyDets] = useState([]);
  const [showNoProductsAlert, setShowNoProductsAlert] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [totalPages, setTotalPages] = useState([]);
  const [isUserCompany, setIsUserCompany] = useState(false);
  const [PageNumber, setPageNumber] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [reRender, setReRender] = useState(false);
  const location = useLocation();
  const { companyId, companyName } = location.state || {};
  const [certificateData, setCertificateData] = useState({
    lc: [],
    dp: [],
    cap: [],
    otherCertificateImages: [],
    otherCertificateLinks: [],
  });
  const [certificateLinks, setCertificateLinks] = useState([
    { spec: "", value: "" },
  ]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "GetCompanyDetail",
          hasId: companyId,
          loading: true,
        });

        setCompanyDets(response.data);
        setCertificateId(response.data.certificateId);
        const isUserCompanyTemp = response.data;
        setIsUserCompany(isUserCompanyTemp.isUserCompany);
      } catch (error) {
        console.error("Error fetching company detail:", error);
      }
    };

    fetchData();
  }, [companyId, reRender]);

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "GetCompanyCertificates",
          hasId: certificateId,
          loading: true,
        });
        setCertificateData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (companyDets.certificateId) {
      fetchCertificates();
    }
  }, [companyDets, reRender]);

  useEffect(() => {
    if (submitSuccess) {
      setShowModal(false);
      toast.success("Product added successfully!");
      setReRender(!reRender);
      const timerId = setTimeout(() => {
        setSubmitSuccess(false);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [submitSuccess]);

  const fetchProducts = async () => {
    try {
      const response = await makeApiCall({
        restapi: "get",
        controller: "CustomerProduct",
        apiName: "GetCompanyProducts",
        hasId: companyId,
        paginated: true,
        pageNumber: PageNumber,
        pageSize: "10",
        loading: true,
      });

      setCompanyProducts(response.data.products);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setShowNoProductsAlert(true);

      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (companyDets.id) {
      fetchProducts();
    }
  }, [companyDets]);
  const handleAddProduct = () => {
    if (!submitSuccess) {
      setShowModal(true);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);

    setTimeout(() => {
      setSubmitSuccess(true);
    }, 500);
  };

  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleEditCompany = (shouldEdit, updated) => {
    setEditMode(shouldEdit);
    console.log("is updated?", updated);
    if (updated) setReRender(!reRender);
    window.scrollTo({ top: 0 });
    console.log(shouldEdit);
  };
  return (
    <React.Fragment>
      {editMode ? (
        <EditCompanyDetails
          certificates={certificateData}
          companyDets={companyDets}
          companyId={companyId}
          handleEditMode={handleEditCompany}
        />
      ) : (
        <React.Fragment>
          {showNoProductsAlert ? (
            <React.Fragment>
              <CompanyDetails
                companyDets={companyDets}
                certificates={certificateData}
                handleEditMode={() => handleEditCompany(true)}
              />
              <Alert variant="danger" className="  h4 my-5">
                You have not added a Product yet. You need to add a Product in
                order to see products.
              </Alert>
              {isUserCompany && (
                <div className=" text-center my-5">
                  <Button
                    variant="warning"
                    className=" col-2 btn-lg"
                    onClick={handleAddProduct}
                  >
                    Add Product
                  </Button>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div>
              <CompanyDetails
                companyDets={companyDets}
                certificates={certificateData}
                handleEditMode={() => handleEditCompany(true)}
              />

              <div>
                <Row className="my-5 col-md-11 mx-auto  text-center ps-md-5">
                  <div className="d-flex align-items-center border-bottom pb-3 border-secondary justify-content-between">
                    <span className="companydetails-products-text">
                      Products
                    </span>
                    {companyDets.isUserCompany && (
                      <div className="d-flex">
                        <Button
                          variant="light"
                          onClick={handleAddProduct}
                          className="text-center border border-2 Add-Product-button fw-bold"
                        >
                          Add Product +
                        </Button>
                      </div>
                    )}
                  </div>

                  {companyProducts?.length > 0 ? (
                    <React.Fragment>
                      <div className=" justify-content-start px-3 d-flex flex-wrap">
                        {companyProducts.map((product) => (
                          <ProductLowDets
                            key={product.id}
                            productId={product.id}
                            productImage={product.images}
                            productName={product.name}
                            productPurpose={product.productPurpose}
                            productType={product.productType}
                            isUserCompany={product.isUserCompany}
                          />
                        ))}
                      </div>
                      <div className="pagination-container   d-flex justify-content-center">
                        <PaginationComponent
                          PageNumber={PageNumber}
                          TotalPages={totalPages}
                          handlePaginationClick={handlePaginationClick}
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div>
                      <Alert variant="info" className="my-3">
                        This company has no products.
                      </Alert>
                    </div>
                  )}
                </Row>
              </div>
            </div>
          )}
          <ToastContainer />
          <Modal
            className=""
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header className=" justify-content-end  bg-light">
              <div
                className="modal-close-btn btn"
                onClick={() => setShowModal(false)}
              >
                &#10006;
              </div>
            </Modal.Header>
            <AddProduct
              companyId={companyId}
              onSubmitSuccess={() => setSubmitSuccess(true)}
              companies={companyName}
            />
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Products;
