import React, { useState } from "react";
import { Form, Button, Alert, Col } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import makeApiCall from "../../apiCall";
import googleImg from "../../images/google.png";
import { jwtDecode } from "jwt-decode";
const Login = ({ handleIsloggedin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const token = "token";
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await makeApiCall({
        restapi: "post",
        controller: "Authentication",
        apiName: "Sign-In",
        loading: true,
        data: { email, password },
      });

      localStorage.setItem(token, response.data.token);
      if (response.data.profileImage !== null)
        localStorage.setItem("userImage", response.data.profileImage);
      const decodedJwt = jwtDecode(response.data.token);
      const user = decodedJwt.sub;
      localStorage.setItem("userName", user);
      handleIsloggedin(true, response.data.token);
      const from = location.state?.from || "/";
      if (!localStorage.getItem("redirectUrl")) navigate(from);
      else {
        window.location.href = localStorage.getItem("redirectUrl");
        localStorage.removeItem("redirectUrl");
      }
    } catch (error) {
      console.error(error);
      setLoginError(error.response.data);
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className=" d-flex justify-content-center ">
      <Col
        sm={11}
        md={6}
        className=" col-11 border border-2 border-warning bg-F5F7F8  shadow p-3 mt-4 rounded-5 "
      >
        <Form onSubmit={handleSubmit}>
          <div className="col-10 mx-auto">
            <Form.Group controlId="formEmail">
              <Form.Label className="mt-3 fw-bold">Email address</Form.Label>
              <Form.Control
                className="border-secondary-subtle py-2"
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label className="mt-3 fw-bold">Password</Form.Label>

              <div className="position-relative">
                <Form.Control
                  className="border-secondary-subtle py-2"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                />

                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  size="lg"
                  onClick={handlePasswordToggle}
                  color="grey"
                  className="position-absolute me-3 end-0 top-50 translate-middle-y"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Form.Group>

            <div className="text-center my-2">
              {loginError && (
                <Alert variant="danger" className="h4">
                  {loginError}
                </Alert>
              )}
              <div className="text-start my-3">
                <NavLink
                  className=" text-decoration-none text-dark ps-3"
                  to="/forgetpassword/getemail"
                >
                  Forgot Password?
                </NavLink>
              </div>
              <Button
                type="submit"
                className="btn btn-lg fw-bold col-6 bg-F4CE14 border-0 text-dark"
              >
                Sign in
              </Button>
              <p className="my-2">or continue with </p>
              <Button className="btn mx-auto  bg-transparent text-dark py-0 border-dark d-flex justify-content-center align-items-center col">
                <img src={googleImg} className=" icon-50" />
                <span className="h5 mb-0">Continue with Google</span>
              </Button>
            </div>

            <div className="text-center ">
              <p className="mb-2 mt-4">
                Don't have an Account?
                <NavLink
                  className="text-decoration-none text-dark fw-bold ms-2"
                  to="/signup"
                >
                  Register for free
                </NavLink>
              </p>
            </div>
          </div>
        </Form>
      </Col>
    </div>
  );
};

export default Login;
