import React, { useState, useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import Home from "./services/home/home";
import DropdownNav from "./services/navbar/navbar";
import Login from "./services/authorization/login";
import SignUp from "./services/authorization/signup";
import Footer from "./services/footer/footer";
import CompanyPage from "./services/user/Companies&Products/companyPage";
import CountryTable from "./services/user/Companies&Products/countryTable";
import IntelligentOffer from "./services/user/IntelligentOffer/intelligentOffer";
import OfferTable from "./services/user/IntelligentOffer/offerTable";
import OfferPage from "./services/user/IntelligentOffer/offerPage";
import { jwtDecode } from "jwt-decode";
import Products from "./services/user/Companies&Products/products";
import AddProduct from "./services/user/Companies&Products/addProduct";
import UserCompanies from "./services/user/Companies&Products/userCompanies";
import AddCompany from "./services/user/Companies&Products/registerCompany";
import MessageCenter from "./services/user/MessageCenter/messageCenter";
import { usePromiseTracker } from "react-promise-tracker";
import { ClipLoader, MoonLoader, SyncLoader } from "react-spinners";
import NotificationsComponent from "./services/notifications/notifications";
import SearchProducts from "./services/Search/searchProducts";
import GetEmail from "./services/forgetPassword/getEmail";
import ResetPassword from "./services/forgetPassword/resetPassword";
import ProductDetails from "./services/user/Companies&Products/productDetails";
import HamburgerMenu from "./services/navbar/hamburgerMenu";
import NotFound from "./services/authorization/notFound";
import HelpChat from "./services/helpChat/helpChatIcon";
import tradeLoading from "./images/tradeloading.png";
import Countries from "./services/user/Companies&Products/countries";
import UserProfile from "./services/user/profile.jsx/profile";
import Categories from "./services/categories/categories";
import SearchCompanies from "./services/Search/searchCompanies";
import AllCompanies from "./services/user/Companies&Products/allCompanies";
import AllProducts from "./services/user/Companies&Products/allProducts";
import toast, { Toaster } from "react-hot-toast";
import About from "./services/about/about";
import CategoryCompanies from "./services/categories/categoryCompanies";
import makeApiCall from "./apiCall";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [navData, setNavData] = useState(false);
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const currentTime = Date.now() / 1000;
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("token"));
  const [userImage, setUserImage] = useState(localStorage.getItem("userImage"));
  const location = useLocation();
  const navigate = useNavigate();
  const isMessageCenter = location.pathname == "/messagecenter";

  useEffect(() => {
    if (isMessageCenter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isMessageCenter]);

  useEffect(() => {
    const handleIsMobileView = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
        localStorage.setItem("isMobileView", true);
      } else {
        setIsMobileView(false);
        localStorage.setItem("isMobileView", false);
      }
    };

    handleIsMobileView();

    window.addEventListener("resize", handleIsMobileView);

    return () => {
      window.removeEventListener("resize", handleIsMobileView);
    };
  }, []);

  useEffect(() => {
    const countries = localStorage.getItem("countries");
    const categories = localStorage.getItem("categories");

    const fetchCountries = async () => {
      if (!countries) {
        try {
          const response = await makeApiCall({
            restapi: "get",
            controller: "CustomerCompany",
            apiName: "GetCountry",
          });
          localStorage.setItem("countries", JSON.stringify(response.data));
        } catch (error) {
          console.error("Error fetching countries:", error);
        }
      }
    };

    const fetchCategories = async () => {
      if (!categories) {
        try {
          const response = await makeApiCall({
            restapi: "get",
            controller: "CustomerCompany",
            apiName: "GetCategory",
          });
          localStorage.setItem("categories", JSON.stringify(response.data));
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      }
    };

    fetchCountries();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (!navigator.onLine) {
      toast.error("No internet connection");
    }
  }, [location]);

  useEffect(() => {
    setUserImage(localStorage.getItem("userImage"));
  }, [userImage]);

  const updateNavData = (data) => {
    setNavData(data);
  };
  const openHamburgerMenu = (data) => {
    setHamburgerMenuOpen(data);
    setNavData(data);
  };

  const handleIsloggedin = (data, token) => {
    setLoggedIn(data);
    setUserImage(localStorage.getItem("userImage"));
  };
  const handleIsLoggedOut = () => {
    setUserImage("");
    setLoggedIn(false);
    localStorage.removeItem("userImage");
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    openHamburgerMenu(false);
    navigate("/login");
  };

  const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress && (
        <div className="main-loading-div-div user-select-none">
          <div className="main-loading-div">
            <img src={tradeLoading} className="main-loading" />
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    try {
      const decodedJwt = jwtDecode(jwtToken);

      const from = location.pathname;
      if (jwtToken) {
        if (decodedJwt.exp < Math.floor(currentTime)) {
          localStorage.setItem("redirectUrl", from);
          navigate("/login");

          localStorage.removeItem("token");
          setLoggedIn(false);
          localStorage.removeItem("userName");
          setUserImage("");
        } else {
          setLoggedIn(true);
        }
      } else {
        localStorage.setItem("redirectUrl", from);
        navigate("/login");
        setLoggedIn(false);
      }
    } catch {
      setLoggedIn(false);
      localStorage.removeItem("userName");
      setUserImage("");
    }
  }, [jwtToken]);

  useEffect(() => {
    if (navData) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [navData]);

  useEffect(() => {
    if (
      isLoggedIn &&
      (location.pathname === "/login" ||
        location.pathname === "/signup" ||
        location.pathname.startsWith("/forgetpassword"))
    ) {
      navigate("/home");
    }
  }, [location, isLoggedIn, navigate]);

  return (
    <React.Fragment>
      <div
        className={`${navData ? "grey-transparent-bg" : ""} ${
          hamburgerMenuOpen ? "z-index-1000" : ""
        }`}
      ></div>
      <Toaster />
      <DropdownNav
        isLoggedIn={isLoggedIn}
        updateNavData={updateNavData}
        openHamburgerMenu={openHamburgerMenu}
      />

      <HamburgerMenu
        isLoggedIn={isLoggedIn}
        setLoggedIn={setLoggedIn}
        handleIsLoggedOut={handleIsLoggedOut}
        hamburgerMenuOpen={hamburgerMenuOpen}
        openHamburgerMenu={openHamburgerMenu}
      />

      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/companies" element={<AllCompanies />} />
        <Route path="/products" element={<AllProducts />} />
        <Route path="/Intelligentoffer" element={<IntelligentOffer />} />
        <Route path="/user/companies" element={<UserCompanies />} />
        <Route path="/user/products" element={<Products />} />

        <Route path="/user/products/:productId" element={<ProductDetails />} />
        <Route path="/user/products/addproduct" element={<AddProduct />} />
        <Route path="/offers" element={<OfferTable />} />
        <Route path="/offers/:offerid" element={<OfferPage />} />
        <Route path="/messageCenter" element={<MessageCenter />} />
        <Route path="/notifications" element={<NotificationsComponent />} />
        <Route path="/Searchproduct/:searchItem" element={<SearchProducts />} />
        <Route
          path="/Searchcompany/:searchItem"
          element={<SearchCompanies />}
        />
        <Route
          path="/registerCompany"
          element={<AddCompany isLoggedIn={isLoggedIn} />}
        />

        <Route path="/" element={<Home />} />
        <Route
          path="/countries/:country/:companyId"
          element={<CompanyPage />}
        />
        <Route path="/countries/:country" element={<CountryTable />} />
        <Route path="/countries" element={<Countries />} />
        <Route path="/categories/:category" element={<CategoryCompanies />} />
        <Route path="/categories" element={<Categories />} />
        <React.Fragment>
          <Route
            path="/login"
            element={
              <Login
                isLoggedIn={isLoggedIn}
                handleIsloggedin={handleIsloggedin}
              />
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="forgetpassword/getemail" element={<GetEmail />} />
          <Route
            path="forgetpassword/resetpassword/:randomkey"
            element={<ResetPassword />}
          />
        </React.Fragment>

        <Route path="*" element={<NotFound />} />
      </Routes>

      <LoadingIndicator />

      <React.Fragment>
        {!isMessageCenter && <HelpChat />}
        <Footer />
      </React.Fragment>
    </React.Fragment>
  );
}

export default App;
