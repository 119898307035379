import React, { useState, useEffect, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, Dropdown, Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import profile from "../../images/profile.png";
import { useLocation, useNavigate } from "react-router-dom";
import MTALogo from "../../images/mtalogo.svg";
import { Badge, IconButton } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import Autosuggest from "react-autosuggest";
import makeApiCall from "../../apiCall";
import { PulseLoader } from "react-spinners";
import SubNav from "./subNav";
import hamburgermenu from "../../icons/hamburgermenu.svg";
import caretDownICon from "../../icons/caretDown.svg";

const DropdownNav = ({ isLoggedIn, updateNavData, openHamburgerMenu }) => {
  const jwtToken = localStorage.getItem("token");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const [notifCounter, setNotifCounter] = useState(0);
  const location = useLocation();
  const [suggestions, setSuggestions] = useState([]);
  const searchInputRef = useRef(null);
  const [searchbarLoading, setSearchbarLoading] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const dropDownRef = useRef(null);
  const dropDownBtnRef = useRef(null);
  const timeoutRef = useRef(null);
  const [searchType, setSearchType] = useState("product");

  const handleInputChange = async (event, { newValue }) => {
    setSuggestions([]);
    setSearchbarLoading(true);
    setSearchTerm(newValue);
    if (newValue === "") setSearchbarLoading(false);
    if (newValue !== "") {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        const response = await makeApiCall({
          restapi: "get",
          controller: `Customer${searchType}`,
          apiName: `${searchType}AutoCompleteSearch`,
          hasWhereId: "searchTerm",
          whereId: newValue,
        });

        setSuggestions(response.data);
        setSearchbarLoading(false);
      }, 1000);
    }
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    setSearchTerm(suggestion);
    searchInputRef.current.blur();
    navigate(`/search${searchType}/${suggestion}`);
    updateNavData(false);
    setSearchTerm("");
  };

  const handleSearch = () => {
    searchInputRef.current.blur();
    if (searchTerm !== "") navigate(`/search${searchType}/${searchTerm}`);
    updateNavData(false);
    setSearchTerm("");
  };

  const handleSearchFocus = () => {
    updateNavData(true);
  };

  const handleSearchFocusOut = () => {
    updateNavData(false);
  };

  const handleDropdownSelect = (type) => {
    setSearchType(type);
    setSearchTerm("");
    setSuggestions([]);
  };

  useEffect(() => {
    try {
      if (jwtToken) {
        const fetchNotifCounter = async () => {
          const response = await makeApiCall({
            restapi: "get",
            controller: "MessageCenter",
            apiName: "GetNotificationCount",
          });

          setNotifCounter(response.data);
        };

        fetchNotifCounter();
      }
    } catch (error) {
      console.error("navbar error: ", error);
      console.log("navbar, not logged in");
    }
  }, [location]);

  const handleNotifClick = () => {
    navigate("notifications");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target) &&
        !dropDownBtnRef.current.contains(event.target)
      ) {
        setIsDropDownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropDownOpen]);

  const handleOpenHamMenu = () => {
    openHamburgerMenu(true);
  };

  return (
    <React.Fragment>
      <Navbar className="col-12 d-flex flex-wrap py-0 justify-content-between navbar">
        <div className="col-md-5 col-3">
          <Button
            onClick={() => {
              handleOpenHamMenu();
            }}
            className="btn-lg bg-transparent col-md-2 col-12 py-2 focus-hover  border-0 py-1 ms-md-3"
          >
            <img src={hamburgermenu} className="col-md-9 col-7 my-0 " />
            
          </Button>
        </div>
        <div className=" col-md col-6  d-flex justify-content-end">
          <NavLink className="text-center col rounded ms-0" href="/home">
            <Image
              src={MTALogo}
              className="col-md-12 mta-logo my-1 p-1 col-10 mx-auto d-block"
            />
          </NavLink>
        </div>

        {isLoggedIn ? (
          <React.Fragment>
            {!isMobileView && (
              <div className=" col-md-4 search-bar-ontop  px-0">
                <div className="d-flex bg-white justify-content-between rounded-3 ms-auto col-9">
                  <div className="">
                    <Dropdown onSelect={handleDropdownSelect} className="">
                      <Dropdown.Toggle className="searchbar-type-dropdown dropdown-sign-remover border border-white border-2 rounded-3">
                        <img src={caretDownICon} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className=" searchbar-type-dropdown-menu blur-5 overflow-y-hidden">
                        <Dropdown.Item
                          className=" focus-hover-light searchbar-type-dropdown-menu-item"
                          eventKey="product"
                        >
                          Product
                        </Dropdown.Item>
                        <Dropdown.Item
                          className=" focus-hover-light searchbar-type-dropdown-menu-item"
                          eventKey="company"
                        >
                          Company
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="autosuggest-container position-relative">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={handleSuggestionSelected}
                      getSuggestionValue={(suggestion) => suggestion}
                      renderSuggestion={(suggestion) => (
                        <div
                          className="suggestion-item border py-2 ps-2 text-black"
                          value={suggestion}
                        >
                          {suggestion}
                        </div>
                      )}
                      inputProps={{
                        onFocus: handleSearchFocus,
                        onBlur: handleSearchFocusOut,
                        placeholder: `Enter ${searchType} name`,
                        value: searchTerm,
                        onChange: handleInputChange,
                        onKeyDown: (e) =>
                          e.key === "Enter" &&
                          handleSuggestionSelected(e, {
                            suggestion: searchTerm,
                          }),
                        type: "text",
                        className:
                          "form-control bg-white position-relative border-0 searchbar-form-control",
                        ref: searchInputRef,
                      }}
                    />
                    {searchbarLoading && (
                      <div className="my-auto position-absolute translate-middle-y top-50 end-0">
                        <PulseLoader
                          className="text-center"
                          loading={searchbarLoading}
                          color="rgba(128, 128, 128, 1)"
                          size={10}
                          speedMultiplier={1}
                        />
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      onClick={handleSearch}
                      className=" searchbar-type-dropdown col-1 px-4 border d-flex justify-content-center border-white border-2 align-items-center  rounded-3"
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="xl"
                        color="#45474B"
                        className="mx-auto"
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className=" col-md-1 col d-flex justify-content-center">
              <IconButton className=" focus-hover" onClick={handleNotifClick}>
                <Badge badgeContent={notifCounter} color="error">
                  <Notifications fontSize="large" className="text-dark" />
                </Badge>
              </IconButton>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className=" d-flex justify-content-end col-md-5 col-3">
              <Button
                href="/login"
                className="bg-transparent border-0 text-center h-25 py-md-3 py-2 focus-hover my-auto me-2  me-md-2  text-dark"
              >
                <h5 className="text-center mb-0">Sign in</h5>
              </Button>
              {!isMobileView && (
                <Button
                  href="/signup"
                  className="bg-transparent border-dark text-center h-25 py-md-3 py-2 focus-hover my-auto me-3  me-md-4  text-dark"
                >
                  <h5 className="text-center mb-0">Sign up</h5>
                </Button>
              )}
            </div>
          </React.Fragment>
        )}
           {isMobileView && (
              <div className=" col-12 search-bar-ontop my-1 px-3  px-0">
                <div className="d-flex bg-white justify-content-between rounded-3">
                  <div className="">
                    <Dropdown onSelect={handleDropdownSelect} className="">
                      <Dropdown.Toggle className="searchbar-type-dropdown dropdown-sign-remover border border-white border-2 rounded-3">
                        <img src={caretDownICon} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className=" searchbar-type-dropdown-menu blur-5 overflow-y-hidden">
                        <Dropdown.Item
                          className=" focus-hover-light searchbar-type-dropdown-menu-item"
                          eventKey="product"
                        >
                          Product
                        </Dropdown.Item>
                        <Dropdown.Item
                          className=" focus-hover-light searchbar-type-dropdown-menu-item"
                          eventKey="company"
                        >
                          Company
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="autosuggest-container position-relative">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionSelected={handleSuggestionSelected}
                      getSuggestionValue={(suggestion) => suggestion}
                      renderSuggestion={(suggestion) => (
                        <div
                          className="suggestion-item border py-2 ps-2 text-black"
                          value={suggestion}
                        >
                          {suggestion}
                        </div>
                      )}
                      inputProps={{
                        onFocus: handleSearchFocus,
                        onBlur: handleSearchFocusOut,
                        placeholder: `Enter ${searchType} name`,
                        value: searchTerm,
                        onChange: handleInputChange,
                        onKeyDown: (e) =>
                          e.key === "Enter" &&
                          handleSuggestionSelected(e, {
                            suggestion: searchTerm,
                          }),
                        type: "text",
                        className:
                          "form-control bg-white position-relative border-0 searchbar-form-control",
                        ref: searchInputRef,
                      }}
                    />
                    {searchbarLoading && (
                      <div className="my-auto position-absolute translate-middle-y top-50 end-0">
                        <PulseLoader
                          className="text-center"
                          loading={searchbarLoading}
                          color="rgba(128, 128, 128, 1)"
                          size={10}
                          speedMultiplier={1}
                        />
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      onClick={handleSearch}
                      className=" searchbar-type-dropdown col-1 px-4 border d-flex justify-content-center border-white border-2 align-items-center  rounded-3"
                    >
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="xl"
                        color="#45474B"
                        className="mx-auto"
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
      </Navbar>

      <div className="subnav-container">
        <SubNav />
      </div>
    </React.Fragment>
  );
};

export default DropdownNav;
