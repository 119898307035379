import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useLocation, useParams, useNavigate } from "react-router";
import ProductLowDets from "../user/Companies&Products/productLowDets";
import PaginationComponent from "../pagination/pagination";
import makeApiCall from "../../apiCall";

const SearchProducts = () => {
  const { searchItem } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [products, setProducts] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const handleShowProductDetails = (productName, productId) => {
    navigate(`/user/products/${productName}`, { state: { productId } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerProduct",
          apiName: "SearchProduct",
          hasWhereId: "searchItem",
          paginated: true,
          pageNumber: pageNumber,
          pageSize: "20",
          whereId: searchItem,
          loading: true,
        });
           console.log(response.data)
        setTotalPages(response.data.totalPages);
        setProducts(response.data.products);
        if (response.data.products.length === 0) setNoResult(true);
        else setNoResult(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [searchItem, pageNumber]);
  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  return (
    <React.Fragment>
      {noResult ? (
        <div className=" mx-auto text-center my-5 border rounded col-5">
          <h4 className="my-3">No results for {searchItem}</h4>
          <h5 className="my-3">
            Try checking your spelling or use more general terms
          </h5>
        </div>
      ) : (
        <h3 className=" m-3">Your search Result for {searchItem}</h3>
      )}
      <Row className=" mx-auto col-md-11 d-md-flex justify-content-start ps-5 my-3">
        {products.map((product) => (
          <ProductLowDets
            key={product.id}
            productId={product.id}
            productImage={product.images}
            productName={product.name}
            productPurpose={product.productPurpose}
            productType={product.productType}
          />
        ))}
      </Row>
      {!noResult && (
        <div className="d-flex justify-content-center my-5">
          <PaginationComponent
            PageNumber={pageNumber}
            TotalPages={totalPages}
            handlePaginationClick={handlePaginationClick}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchProducts;
