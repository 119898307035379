import React, { useEffect, useRef, useState } from "react";
import profile from "../../images/profile.png";
import intelligentofferImg from "../../images/intelligentoffer.png";
import mycopaniesandproductsImg from "../../images/mycopaniesandproducts.png";
import myoffersImg from "../../images/myoffers.png";
import messagecenterImg from "../../images/messagecenter.png";
import logout from "../../images/logout.png";
import login from "../../images/login.png";
import CloseButton from "../../images/closeButton.svg";
import closeButtonDark from "../../images/closeButtonDark.svg";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const HamburgerMenu = ({
  hamburgerMenuOpen,
  openHamburgerMenu,
  setLoggedIn,
  handleIsLoggedOut,
  isLoggedIn,
}) => {
  const menuRef = useRef(null);
  const [visible, setVisible] = useState(hamburgerMenuOpen);
  const [animating, setAnimating] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      setUserImage(localStorage.getItem("userImage"));
      setUserName(localStorage.getItem("userName"));
    }
  }, [
    isLoggedIn,
    localStorage.getItem("userImage"),
    localStorage.getItem("userName"),
  ]);
  useEffect(() => {
    if (hamburgerMenuOpen) {
      setVisible(true);
      setAnimating(true);
    } else if (animating) {
      setTimeout(() => {
        setVisible(false);
        setAnimating(false);
      }, 500);
    }
  }, [hamburgerMenuOpen, animating]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        openHamburgerMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openHamburgerMenu]);

  const handleLogout = () => {
    localStorage.removeItem("userImage");
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    setUserName("");
    setUserImage("");
    handleIsLoggedOut();
    setLoggedIn(false);

    openHamburgerMenu(false);
  };
  useEffect(() => {
    const handleIsMobileView = () => {
      if (window.innerWidth <= 576) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };

    handleIsMobileView();

    window.addEventListener("resize", handleIsMobileView);

    return () => {
      window.removeEventListener("resize", handleIsMobileView);
    };
  }, []);

  const handleUserProfile = () => {
    navigate("/profile");
    
    openHamburgerMenu(false);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };
  return (
    <React.Fragment>
      {visible && (
        <React.Fragment>
          <div
            className={`zindex-1000 hamburger-menu-close ${
              animating
                ? hamburgerMenuOpen
                  ? "menuCloseButtonSlideIn"
                  : "menuCloseButtonSlideOut"
                : ""
            } position-absolute cursor-pointer top-0 end-0`}
            onClick={() => openHamburgerMenu(false)}
          >
            <img
              src={isMobileView ? closeButtonDark : CloseButton}
              alt="Close"
            />
          </div>

          <div
            ref={menuRef}
            className={`hamburger-menu ${
              animating
                ? hamburgerMenuOpen
                  ? "menuSlideIn"
                  : "menuSlideOut"
                : ""
            }`}
          >
            <div className="hamburger-menu-header align-content-center">
              <div className="d-flex  col-md-11 mx-auto justify-content-start">
                <div
                  onClick={isLoggedIn && handleUserProfile}
                  className={`col-md-12 col-10 mx-md-auto ms-md-0 ms-2 d-flex rounded justify-content-md-center justify-content-start ${
                    isLoggedIn && "cursor-pointer focus-hover"
                  }`}
                >
                  <div
                    className={`align-content-center mx-auto d-flex justify-content-center`}
                  >
                    <img
                      className="hamburger-menu-userimage object-fit-cover p-2  rounded-circle"
                      src={
                        !userImage 
                          ? profile
                          : `data:image/png;base64,${userImage}`
                      }
                      alt={userImage ? "default" : "user"}
                    />
                  </div>
                  <div className="align-self-center font-45474B col-8  h3 mb-0 font-weight-600 hamburger-menu-username">
                    {userName ? (
                      userName
                    ) : (
                      <div
                        onClick={(e) => handleLogin(e)}
                        className="bg-transparent focus-hover border-0 fw-bold"
                      >
                        <span className="h2 text-dark">Sign in</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="hamburger-menu-body mt-4 col-11  mx-auto">
              {isLoggedIn && (
                <React.Fragment>
                  <div className="ms-4 font-45474B h6 h5">User Issues</div>
                  {/* <Button
                    className="mb-0 bg-transparent my-2  py-3 d-flex justify-content-start focus-hover text-dark border-0 col-12 text-start"
                    href="/registercompany"
                  >
                    <img
                      src={addcompanyImg}
                      className="icon-30 me-4"
                      alt="Add Company"
                    />
                    <div className="h5 font-45474B col fw-bold my-auto">
                      Add Company
                    </div>
                  </Button> */}
                  <Button
                    className="mb-0 bg-transparent my-2  py-3 d-flex justify-content-start focus-hover text-dark border-0 col-12 text-start"
                    href="/user/companies"
                  >
                    <img
                      src={mycopaniesandproductsImg}
                      className="icon-30 me-4"
                      alt="My Companies & Products"
                    />
                    <div className="h5 font-45474B col  my-auto">
                      My Companies
                    </div>
                  </Button>
                  <Button
                    href="/intelligentoffer"
                    className="mb-0 bg-transparent my-2  py-3 d-flex justify-content-start focus-hover text-dark border-0 col-12 text-start"
                    onClick={() =>
                      navigate("/intelligentoffer", {
                        state: {
                          productId: "",
                          productName: "",
                          productPurpose: "",
                        },
                      })
                    }
                  >
                    <img
                      src={intelligentofferImg}
                      className="icon-30 me-4"
                      alt="Intelligent Offer"
                    />
                    <div className="h5 font-45474B col  my-auto">
                      Intelligent Offer
                    </div>
                  </Button>
                  <Button
                    className="mb-0 bg-transparent my-2  py-3 d-flex justify-content-start focus-hover text-dark border-0 col-12 text-start"
                    href="/offers"
                  >
                    <img
                      src={myoffersImg}
                      className="icon-30 me-4"
                      alt="My Offers"
                    />
                    <div className="h5 font-45474B col  my-auto">My Offers</div>
                  </Button>
                  <Button
                    className="mb-0 bg-transparent my-2  py-3 d-flex justify-content-start focus-hover text-dark border-0 col-12 text-start"
                    href="/messagecenter"
                  >
                    <img
                      src={messagecenterImg}
                      className="icon-30 me-4"
                      alt="Message Center"
                    />
                    <div className="h5 font-45474B col  my-auto">
                      Message Center
                    </div>
                  </Button>
                </React.Fragment>
              )}

              {isLoggedIn ? (
                <Button
                  onClick={handleLogout}
                  className=" py-3 bg-transparent text-start font-45474B my-2 focus-hover col-12 rounded border-0"
                >
                  <img src={logout} className="icon-30 me-4" alt="Log out" />
                  <span className="h5 mb-0">Log out</span>
                </Button>
              ) : (
                <Button
                  href="/login"
                  className=" py-3 bg-transparent text-start font-45474B my-2 focus-hover col-12 rounded border-0"
                >
                  <img src={login} className="icon-30 me-4" alt="Log out" />
                  <span className="h5 mb-0">Log in</span>
                </Button>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default HamburgerMenu;
