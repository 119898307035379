import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import makeApiCall from "../../../apiCall";
import Image from "../../../images/companyBasic.png";
const CompanyPage = (image) => {
  const location = useLocation();
  const { country } = useParams();
  const { companyId } = useParams();
  const [companyDetails, setCompanyDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CrawledCompany",
          apiName: `Get${country}CompanyDetail`,
          hasWhereId: "companyId",
          whereId: companyId,
          loading: true,
        });
        setCompanyDetails(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Container className=" col-md-7 col-12 ps-md-5 ps-4 my-5">
          {Object.keys(companyDetails).length === 0 ? (
            <p>Loading...</p>
          ) : (
            Object.entries(companyDetails)
              .filter(([key]) => key !== "id")
              .map(([key, value]) => (
                <p key={key}>
                  <strong>{key}:</strong> {value}
                </p>
              ))
          )}
        </Container>
        <div className=" col-md-5 col-8 mx-auto d-flex justify-content-center align-items-center">
          <img className=" col-md-6 col-12 " src={Image} alt="company image" />
        </div>
      </Row>
    </React.Fragment>
  );
};

export default CompanyPage;
