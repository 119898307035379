import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;

  if (error.response && error.response.status === 401) {
    if (!window.location.href.includes("/login")) {
      localStorage.setItem("redirectUrl", window.location.href);
      window.location.href = "/login";
    }
  }
  if (!expectedError) {
    console.error("Logging the error", error);
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  put: axios.put,
  delete: axios.delete,
  post: axios.post,
};
