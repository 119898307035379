import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const CertificateImageUploadModal = ({ show, handleClose, handleAddImage }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const [imageType, setImageType] = useState("cap");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result.split(",")[1]);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    if (image) {
      handleAddImage(image, imageType);
      setImage(null);
      setError(false);
      handleClose();
    } else {
      setError(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="edit-certificate-images-modal"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile">
            <Form.Label>Image</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Form.Group>
          <Form.Group controlId="formImageType">
            <Form.Label className="mt-2">Certificate Type</Form.Label>
            <Form.Control
              as="select"
              value={imageType}
              onChange={(e) => setImageType(e.target.value)}
            >
              <option value="cap">CAP</option>
              <option value="lc">LC</option>
              <option value="dp">DP</option>
              <option value="otherCertificateImages">Other Certificates</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {error && (
          <div className="text-danger text-center mt-2 mx-auto">
            Please add an image
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="warning" onClick={handleSubmit}>
          Add Image
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CertificateImageUploadModal;
