import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  Image,
  Alert,
  Container,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import makeApiCall from "../../../apiCall";
import companyBasicImg from "../../../images/companyBasic.png";
import editImg from "../../../images/edit.svg";
import settingImg from "../../../images/setting.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal, Carousel } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeBtn from "../../../icons/closeBtn.svg";
import addBtn from "../../../icons/addBtn.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useState({});
  const [productDetailsDef, setProductDetailsDef] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [reRenderPD, setReRenderPD] = useState(false);
  const [productTypes, setProductTypes] = useState("");
  const [newImages, setNewImages] = useState([]);
  const [isUserProduct, setIsUserProduct] = useState(false);
  const [existingImages, setExistingImages] = useState([]);
  const [existingImagesDefault, setExistingImagesDefault] = useState([]);
  const [specifications, setSpecifications] = useState([
    { spec: "", value: "" },
  ]);
  const location = useLocation();
  const { productId } = location.state || useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await makeApiCall({
        restapi: "get",
        controller: "CustomerProduct",
        apiName: "GetProductDetail",
        hasId: productId,
        loading: true,
      });
      setProductDetails(response.data);
      setProductDetailsDef(response.data);
      setIsUserProduct(response.data.isUserProduct);
      const existingImages = response.data.images || [];
      setExistingImages(existingImages);
      setExistingImagesDefault(existingImages);
      const transformedSpecifications = Object.keys(
        response.data.specifications
      ).map((key) => ({
        spec: key,
        value: response.data.specifications[key],
      }));
      setSpecifications(transformedSpecifications);
    };

    fetchData();
  }, [productId, reRenderPD]);

  useEffect(() => {
    const fetchData = async () => {
      if (productDetails.companyId) {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerProduct",
          apiName: "GetProductTypes",
          hasId: productDetails.companyId,
        });
        setProductTypes(response.data);
      }
    };

    fetchData();
  }, [productDetails.companyId]);

  const handleCompanyClick = (companyId) => {
    navigate("/user/products", { state: { companyId } });
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const handleEditClick = (notEdited) => {
    setEditMode(!editMode);
    console.log(notEdited);
    if (editMode == true && notEdited != "true") setReRenderPD(!reRenderPD);
    if (notEdited == "true") setProductDetails(productDetailsDef);
    setNewImages([]);
  };

  const handleSpecificationChange = (e, index, field) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index][field] = e.target.value;
    setSpecifications(updatedSpecifications);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);
    if (files) {
      setNewImages((prevImages) => [...prevImages, ...files]);
    }
  };

  const handleImageDelete = (index, type) => {
    console.log(index, type);
    if (type === "existing") {
      setExistingImages((prevImages) =>
        prevImages.filter((_, i) => i !== index)
      );
      console.log("bbbbbbbbbbbbbbbbbbbbbbbbbbbb");
    } else {
      setNewImages((prevImages) => prevImages.filter((_, i) => i !== index));
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    }
  };

  const imageFileToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(imageFile);
    });
  };
  const base64ToFile = (base64String, fileName) => {
    const arr = base64String.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  const addDataImagePrefix = (base64String) => {
    if (!base64String.startsWith("data:image")) {
      return `data:image/png;base64,${base64String}`;
    }
    return base64String;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      if (productDetails.productId !== productDetailsDef.productId) {
        formData.append("productId", productId);
      }

      if (productDetails.name !== productDetailsDef.name) {
        formData.append("name", productDetails.name);
      }

      if (productDetails.productPurpose !== productDetailsDef.productPurpose) {
        formData.append("productPurpose", productDetails.productPurpose);
      }

      if (
        productDetails.productUnitType !== productDetailsDef.productUnitType
      ) {
        formData.append("productUnitType", productDetails.productUnitType);
      }

      if (productDetails.productType !== productDetailsDef.productType) {
        formData.append("productType", productDetails.productType);
      }

      const specificationsObj = {};
      specifications.forEach((specification) => {
        specificationsObj[specification.spec] = specification.value;
      });
      const specificationsJSON = JSON.stringify(specificationsObj);
      if (specificationsObj != productDetails.specifications)
        formData.append("specifications", specificationsJSON);

      const allImages = [...newImages, ...existingImages];
      console.log(allImages);

      const base64Images = await Promise.all(
        allImages.map(async (image, index) => {
          if (typeof image === "string") {
            const prefixedBase64 = addDataImagePrefix(image);
            return {
              base64: prefixedBase64,
              fileName: `image${index + 1}.png`,
            };
          } else if (image instanceof Blob || image instanceof File) {
            const base64String = await imageFileToBase64(image);
            const prefixedBase64 = addDataImagePrefix(base64String);
            const fileName = image.name || `image${index + 1}.png`;
            return { base64: prefixedBase64, fileName };
          } else {
            console.warn("Ignoring invalid image format:", image);
            return null;
          }
        })
      );

      const validBase64Images = base64Images.filter((image) => image !== null);
      console.log(validBase64Images);

      validBase64Images.forEach(({ base64, fileName }) => {
        const file = base64ToFile(base64, fileName);
        // console.log(allImages);
        // console.log(existingImages);
        // console.log(validBase64Images);
        if (newImages.length > 0 || existingImages != existingImagesDefault) {
          formData.append("Images", file);
        }
      });

      const response = await makeApiCall({
        restapi: "put",
        controller: "CustomerProduct",
        apiName: "UpdateProduct",
        hasId: productId,
        data: formData,
        loading: true,
      });
      setEditMode(false);
      setReRenderPD(!reRenderPD);
      setNewImages([]);
    } catch (error) {
      console.error(error);

      if (error.response && error.response.data) {
        toast.error(error.response.data, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 7000,
        });
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const handleManualOffer = () => {
    navigate("/intelligentoffer", {
      state: {
        productId: productDetails.id,
        productName: productDetails.name,
        productPurpose: productDetails.productPurpose,
      },
    });
  };

  const handleAddSpecification = () => {
    setSpecifications([...specifications, { spec: "", value: "" }]);
  };
  const handleDeleteSpecification = (index) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications.splice(index, 1);
    setSpecifications(updatedSpecifications);
  };

  const renderSpecificationControls = () => {
    return specifications.map((specification, index) => (
      <Row
        key={index}
        className={`my-2 ${
          !editMode
            ? "spec-container bg-light mx-md-2 mx-auto h6 mb-0 border  rounded-3"
            : ""
        }`}
      >
        {editMode ? (
          <div className="d-flex align-items-center col-6 ps-3 pe-2 my-2">
            <Form.Control
              type="text"
              placeholder="e.g. Color"
              name={`specifications[${index}].spec`}
              value={specification.spec}
              onChange={(e) => handleSpecificationChange(e, index, "spec")}
              className="shadow-sm rounded-3"
            />
          </div>
        ) : (
          <div className={`text-start py-2 `}>{specification.spec}</div>
        )}

        {editMode ? (
          <div className="position-relative d-flex align-items-center col-6 pe-3 ps-2 my-2">
            <Form.Control
              type="text"
              placeholder="e.g. Red"
              name={`specifications[${index}].value`}
              value={specification.value}
              onChange={(e) => handleSpecificationChange(e, index, "value")}
              className="shadow-sm rounded-3"
            />
            <img
              src={closeBtn}
              className="rounded-circle cursor-pointer bg-white end-0 me-4 pe-2 icon-30 position-absolute align-self-center"
              onClick={() => handleDeleteSpecification(index)}
            />
          </div>
        ) : (
          <span className={`text-dark text-start pt-2 h5 spec-value`}>
            {specification.value}
          </span>
        )}
      </Row>
    ));
  };

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleImageClick = (index) => {
    setIndex(index);
    handleShow();
  };

  const handleMouseOver = (index) => {
    setIndex(index);
    handleShow();
  };

  const handleMouseOut = () => {
    handleHide();
  };

  return (
    <div className="my-5 rounded">
      <ToastContainer className="" />
      {!editMode ? (
        <React.Fragment>
          <div className={`col-md-5`}>
            <Button
              onClick={() => handleCompanyClick(productDetails.companyId)}
              name="Name"
              className="bg-transparent border-0 text-dark d-flex justify-content-center  ms-md-5 ms-2 mb-md-4 my-0"
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="col-1 align-self-center mx-2"
              />
              Go to Company
            </Button>
          </div>

          <div className="py-2 col-md-10 px-md-5 mt-4 col-11 mx-auto position-relative">
            <div className="d-flex justify-content-between flex-wrap">
              <h3 className="col-md col-12 fw-bold mb-0 mt-1 text-start ps-4">
                {productDetails.name}
                <span className=" text-dark-emphasis h5 ">
                  &nbsp; ({productDetails.productType})
                </span>
              </h3>
              {isUserProduct && (
                <Button
                  onClick={() => handleEditClick()}
                  className="bg-transparent  border-0 mx-1 text-center"
                >
                  <span className="d-flex align-items-center text-dark">
                    <img src={editImg} className="w-25 mx-2" />
                    Edit
                  </span>
                </Button>
              )}
              {!isUserProduct && (
                <Button
                  onClick={() => handleManualOffer()}
                  variant="warning"
                  name="Name"
                  className="mx-1 px-3 border py-2 shadow"
                >
                  Make an Offer
                </Button>
              )}
            </div>

            <div className="font-45474B align-content-center px-md-4 py-2 h6 mx-md-2 mx-auto justify-content-center d-flex justify-content-md-start">
              <div> {productDetails.productPurpose}er</div>
              <div className="border-start border-dark mx-2"></div>
              <div>
                Unit:
                {` ${productDetails.productUnitType}`}
              </div>
            </div>
          </div>
          <div className="mx-auto col-md-9 d-flex  flex-wrap my-3">
            {productDetails.specifications && renderSpecificationControls()}
          </div>

          <div className="col-10 px-4 mx-auto">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              className="py-2 px-2 bg-F5F7F8 border rounded-4"
              // breakpoints={{
              //   320: {
              //     slidesPerView: 2,
              //   },
              //   768: {
              //     slidesPerView: 5,
              //   },
              // }}
            >
              {productDetails.images ? (
                [...existingImages, ...newImages].map((image, index) => (
                  <SwiperSlide
                    onClick={() => handleImageClick(index)}
                    key={index}
                    className="text-center my-2 rounded product-img-container cursor-pointer align-content-center border"
                  >
                    <Image
                      className=" object-fit-contain"
                      src={
                        existingImages.includes(image)
                          ? `data:image/png;base64,${image}`
                          : URL.createObjectURL(image)
                      }
                      thumbnail
                      alt={`image-${index}`}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <Alert className="text-center">No Image</Alert>
              )}
            </Swiper>

            {show && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 1)",
                  backdropFilter: "blur(10px)",
                  zIndex: 1000,
                }}
              />
            )}

            <div className="ps-3 py-3">
              <FaInfoCircle className="text-secondary" />
              The photos may be different from the product and the details may
              not be clear. Please request to avoid problems in the
              conversation.
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="d-flex justify-content-between align-items-center">
            <div className="ms-auto">
              <Button
                onClick={() => handleEditClick("true")}
                className="mx-5 bg-transparent col-md-1 border-0"
              >
                <FontAwesomeIcon icon={faClose} size="2xl" color="red" />
              </Button>
            </div>
          </div>
          <div className=" d-flex justify-content-center">
            <Form.Label className="mx-2 my-2 h5 text-center">
              I want to
              <Form.Check
                type="radio"
                name="productPurpose"
                value="Sell"
                label="Sell"
                checked={productDetails.productPurpose == "Sell"}
                inline
                required
                className="custom-radio ms-3 font-weight-600"
                onChange={handleChange}
              />
              <Form.Check
                type="radio"
                inline
                required
                name="productPurpose"
                value="Buy"
                label="Buy"
                checked={productDetails.productPurpose == "Buy"}
                className="custom-radio font-weight-600"
                onChange={handleChange}
              />
              this Product
            </Form.Label>
          </div>

          <div className="col-md-10 mx-auto my-4 d-flex justify-content-center flex-wrap">
            <div className=" col-md-5 ">
              <div className="col-md-9 mb-3 mx-auto">
                <Form.Label className="font-weight-600 font-45474B">
                  Product name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={productDetails.name}
                  onChange={handleChange}
                  className="py-2 shadow-inset bg-light"
                />
              </div>
              <div className="col-md-9 mb-3 mx-auto">
                <Form.Label className="font-weight-600 font-45474B">
                  Product Type
                </Form.Label>

                <Form.Control
                  required
                  as="select"
                  className="py-2 shadow-inset bg-light"
                  onChange={handleChange}
                  name="productType"
                >
                  <option className="" value={productDetails.productType}>
                    {productDetails.productType}
                  </option>
                  {productTypes &&
                    productTypes.map((productType) => (
                      <option
                        className=""
                        key={productType.id}
                        value={productType.name}
                        variant="danger"
                      >
                        {productType.name}
                      </option>
                    ))}
                </Form.Control>
              </div>
              <div className="col-md-9 mb-3 mx-auto">
                <Form.Label className="font-weight-600 font-45474B">
                  Unit
                </Form.Label>
                <Form.Control
                  required
                  as="select"
                  className="form-control py-2 shadow-inset bg-light"
                  name="productUnitType"
                  onChange={handleChange}
                >
                  <option value={productDetails.productUnitType}>
                    {productDetails.productUnitType}
                  </option>

                  <option value="Acre">Acre</option>
                  <option value="Ampere">Ampere</option>
                  <option value="Bag">Bag</option>
                  <option value="Barrel">Barrel</option>
                  <option value="Box">Box</option>
                  <option value="Bushel">Bushel</option>
                  <option value="Carton">Carton</option>
                  <option value="Case">Case</option>
                  <option value="Centimeter">Centimeter</option>
                  <option value="Chain">Chain</option>
                  <option value="CubicCentimeter">Cubic Centimeter</option>
                  <option value="CubicFoot">Cubic Foot</option>
                  <option value="CubicInch">Cubic Inch</option>
                  <option value="CubicMeter">Cubic Meter</option>
                  <option value="CubicYard">Cubic Yard</option>
                  <option value="DegreesCelsius">Degrees Celsius</option>
                  <option value="DegreesFahrenheit">Degrees Fahrenheit</option>
                  <option value="Dozen">Dozen</option>
                  <option value="Dram">Dram</option>
                  <option value="FluidOunce">Fluid Ounce</option>
                  <option value="Foot">Foot</option>
                  <option value="FortyFootContainer">
                    Forty-Foot Container
                  </option>
                  <option value="Furlong">Furlong</option>
                  <option value="Gallon">Gallon</option>
                  <option value="Gill">Gill</option>
                  <option value="Grain">Grain</option>
                  <option value="Gram">Gram</option>
                  <option value="Gross">Gross</option>
                  <option value="Hectare">Hectare</option>
                  <option value="Hertz">Hertz</option>
                  <option value="Inch">Inch</option>
                  <option value="Item">Item</option>
                  <option value="Kiloampere">Kiloampere</option>
                  <option value="Kilogram">Kilogram</option>
                  <option value="Kilohertz">Kilohertz</option>
                  <option value="Kilometer">Kilometer</option>
                  <option value="Kiloohm">Kiloohm</option>
                  <option value="Kilovolt">Kilovolt</option>
                  <option value="Kilowatt">Kilowatt</option>
                  <option value="Liter">Liter</option>
                  <option value="Long Ton">Long Ton</option>
                  <option value="Megahertz">Megahertz</option>
                  <option value="Meter">Meter</option>
                  <option value="Metric Ton">Metric Ton</option>
                  <option value="Mile">Mile</option>
                  <option value="Milliampere">Milliampere</option>
                  <option value="Milligram">Milligram</option>
                  <option value="Millihertz">Millihertz</option>
                  <option value="Milliliter">Milliliter</option>
                  <option value="Millimeter">Millimeter</option>
                  <option value="Milliohm">Milliohm</option>
                  <option value="Millivolt">Millivolt</option>
                  <option value="Milliwatt">Milliwatt</option>
                  <option value="NauticalMile">Nautical Mile</option>
                  <option value="Ohm">Ohm</option>
                  <option value="Ounce">Ounce</option>
                  <option value="Pack">Pack</option>
                  <option value="Pallet">Pallet</option>
                  <option value="Pair">Pair</option>
                  <option value="Parcel">Parcel</option>
                  <option value="Perch">Perch</option>
                  <option value="Piece">Piece</option>
                  <option value="Pint">Pint</option>
                  <option value="Plant">Plant</option>
                  <option value="Pole">Pole</option>
                  <option value="Pound">Pound</option>
                  <option value="Quart">Quart</option>
                  <option value="Quarter">Quarter</option>
                  <option value="Rod">Rod</option>
                  <option value="Roll">Roll</option>
                  <option value="Set">Set</option>
                  <option value="Sheet">Sheet</option>
                  <option value="ShortTon">Short Ton</option>
                  <option value="SquareCentimeter">Square Centimeter</option>
                  <option value="SquareFoot">Square Foot</option>
                  <option value="SquareInch">Square Inch</option>
                  <option value="SquareMeter">Square Meter</option>
                  <option value="SquareMile">Square Mile</option>
                  <option value="SquareYard">Square Yard</option>
                  <option value="Stone">Stone</option>
                  <option value="Strand">Strand</option>
                  <option value="Ton">Ton</option>
                  <option value="Tonne">Tonne</option>
                  <option value="Tray">Tray</option>
                  <option value="TwentyFootContainer">
                    Twenty-Foot Container
                  </option>
                  <option value="Unit">Unit</option>
                  <option value="Volt">Volt</option>
                  <option value="Watt">Watt</option>
                  <option value="Wp">Wp</option>
                  <option value="Yard">Yard</option>
                </Form.Control>
              </div>
            </div>
            <div className="col-md-6 col-11">
              <Form.Label className="font-weight-600 font-45474B">
                Specifications
              </Form.Label>
              <div className="col-md-11 border shadow-inset py-2 rounded-3 bg-light grey-scroll overflow-y-auto max-height-200">
                <div className="d-flex">
                  <div className="border-bottom border-dark ms-4 me-2 py-2 col rounded-top-3 rounded-end-0">
                    Type
                  </div>
                  <div className="border-bottom border-dark ms-2 me-4 py-2 col rounded-top-3 rounded-start-0">
                    Details
                    <img
                      src={addBtn}
                      className=" float-end mt-1 icon-20 cursor-pointer"
                      onClick={handleAddSpecification}
                    />
                  </div>
                </div>
                <div>{renderSpecificationControls()}</div>
              </div>
            </div>
            <div className="col-md-9 col-11 my-4">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <Form.Label className="font-weight-600 font-45474B">
                Images
              </Form.Label>
              <div className="mx-auto bg-light shadow-inset py-2 bg-F5F7F8 border rounded">
                <div className="d-flex flex-wrap justify-content-center col-12  mx-auto edit-product-images grey-scroll">
                  {productDetails.images.length !== 0 ? (
                    [
                      ...existingImages.map((image, index) => (
                        <div
                          onClick={() => handleImageDelete(index, "existing")}
                          key={`existing-${index}`}
                          className="text-center my-2 rounded mx-2 position-relative product-img-container cursor-pointer align-content-center border"
                        >
                          <Image
                            className=""
                            src={`data:image/png;base64,${image}`}
                            thumbnail
                            alt={`image-${index}`}
                          />
                          <div className="overlay">
                            <div className="delete-button">&#10006;</div>
                          </div>
                        </div>
                      )),
                      ...newImages.map((image, index) => (
                        <div
                          onClick={() => handleImageDelete(index, "new")}
                          key={`new-${index}`}
                          className="text-center my-2 rounded mx-2 position-relative product-img-container cursor-pointer align-content-center border"
                        >
                          <Image
                            className=""
                            src={URL.createObjectURL(image)}
                            thumbnail
                            alt={`new-image-${index}`}
                          />
                          <div className="overlay">
                            <div className="delete-button">&#10006;</div>
                          </div>
                        </div>
                      )),
                    ]
                  ) : (
                    <Alert className="text-center">No Image</Alert>
                  )}
                </div>

                <div className="w-100 d-flex justify-content-center">
                  <button
                    onClick={handleImageUpload}
                    className="bg-white border-1 mx-auto px-5 h6 text-center mb-1 h5 fw-bold py-2 my-2 rounded-3"
                  >
                    Add Image
                  </button>
                </div>
                {/* <div className="ps-3 py-3">
              <FaInfoCircle className="text-secondary" />
              The photos may be different from the product and the details may
              not be clear. Please request to avoid problems in the
              conversation.
            </div> */}
              </div>
            </div>
          </div>

          <div className="text-center my-4">
            <Button
              onClick={handleSubmit}
              className="mx-3 btn-lg bg-success border-0"
            >
              Save
            </Button>
          </div>
        </React.Fragment>
      )}

      <Modal
        show={show}
        onHide={handleHide}
        className="mt-5"
        backdrop="static"
        keyboard={false}
        style={{
          backgroundColor: "black",
          border: "none",
          boxShadow: "none",
        }}
      >
        <Modal.Header
          className="justify-content-end"
          style={{
            backgroundColor: "black",
            border: "none",
            padding: 0,
          }}
        >
          <button className="close-button">
            <span aria-hidden="true" onClick={handleHide}>
              &times;
            </span>
          </button>
        </Modal.Header>

        <Modal.Body
          className=""
          style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", padding: 0 }}
        >
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className="carousel"
          >
            {existingImages.map((image, index) => (
              <Carousel.Item key={index} style={{ width: "100%" }}>
                <Image
                  className=""
                  src={`data:image/png;base64,${image}`}
                  thumbnail
                  alt={`image-${index}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "500px",
                    objectFit: "contain",
                    backgroundColor: "black",
                    border: "none",
                  }}
                />
              </Carousel.Item>
            ))}
            {newImages.map((image, index) => (
              <Carousel.Item key={index}>
                <Image
                  className=""
                  src={URL.createObjectURL(image)}
                  thumbnail
                  alt={`new-image-${index}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "500px",
                    objectFit: "contain",
                    backgroundColor: "black",
                  }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductDetails;
