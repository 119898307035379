import { trackPromise } from "react-promise-tracker";
import http from "./services/httpServices";
const makeApiCall = async ({
  restapi,
  controller,
  apiName,
  hasId,
  hasWhereId,
  whereId,
  paginated,
  pageNumber,
  pageSize,
  data,
  loading,
  status,
  more,
}) => {
  const baseUrl = process.env.REACT_APP_REQUEST_URL;
  let apiEndPoint = `${baseUrl}/${controller}/${apiName}`;

  if (hasId) apiEndPoint += `/${hasId}`;
  if (hasWhereId) apiEndPoint += `?${hasWhereId}=${whereId}`;
  if (hasWhereId && paginated) apiEndPoint += `&`;
  if (!hasWhereId && paginated) apiEndPoint += "?";
  if (paginated) apiEndPoint += `pageNumber=${pageNumber}&pageSize=${pageSize}`;
  if (status) apiEndPoint += `?status=${status}`;
  if (more) apiEndPoint += `${more}`;

  let response;

  if (loading) {
    switch (restapi) {
      case "get":
        response = await trackPromise(http.get(apiEndPoint));
        break;
      case "put":
        response = await trackPromise(http.put(apiEndPoint, data));
        break;
      case "post":
        response = await trackPromise(http.post(apiEndPoint, data));
        break;
      case "delete":
        response = await trackPromise(http.delete(apiEndPoint));
        break;
      default:
        throw new Error("Invalid REST API method");
    }
  } else {
    switch (restapi) {
      case "get":
        response = await http.get(apiEndPoint);
        break;
      case "post":
        response = await http.post(apiEndPoint, data);
        break;
      case "put":
        response = await http.put(apiEndPoint, data);
        break;
      case "delete":
        response = await http.delete(apiEndPoint);
        break;
      default:
        throw new Error("Invalid REST API method");
    }
  }

  return response;
};

export default makeApiCall;
