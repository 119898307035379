import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useLocation, useParams, useNavigate } from "react-router";
import PaginationComponent from "../pagination/pagination";
import makeApiCall from "../../apiCall";
import CompanyLowDets from "../user/Companies&Products/companyLowDets";
import companyBasicImg from "../../images/companyBasic.png";
const SearchCompanies = () => {
  const { searchItem } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const navigate = useNavigate();
  const handleShowProductDetails = (productName, productId) => {
    navigate(`/user/products/${productName}`, { state: { productId } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "SearchCompany",
          hasWhereId: "searchItem",
          paginated: true,
          pageNumber: pageNumber,
          pageSize: "20",
          whereId: searchItem,
          loading: true,
        });

        setTotalPages(response.data.totalPages);
        setCompanies(response.data.companies);
        if (response.data.companies.length === 0) setNoResult(true);
        else setNoResult(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [searchItem, pageNumber]);
  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  const convertBase64ToImage = (base64String) => {
    return `data:image/png;base64,${base64String}`;
  };
  const handleCompanyClick = (companyId, companyName) => {
    navigate("/user/products", { state: { companyId, companyName } });
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <React.Fragment>
      {noResult ? (
        <div className=" mx-auto text-center my-5 border rounded col-5">
          <h4 className="my-3">No results for {searchItem}</h4>
          <h5 className="my-3">
            Try checking your spelling or use more general terms
          </h5>
        </div>
      ) : (
        <h3 className=" m-3">Your search Result for {searchItem}</h3>
      )}
      <Row className=" mx-auto d-md-flex justify-content-start ms-0 ms-md-5 my-3">
        {companies.map((company) => (
          <CompanyLowDets
            image={
              company.image !== null
                ? convertBase64ToImage(company.image)
                : companyBasicImg
            }
            title={company.name}
            CountryCode={company.countryCode}
            category={company.category}
            onClick={() => handleCompanyClick(company.id, company.name)}
          />
        ))}
      </Row>
      {!noResult && (
        <div className="d-flex justify-content-center my-5">
          <PaginationComponent
            PageNumber={pageNumber}
            TotalPages={totalPages}
            handlePaginationClick={handlePaginationClick}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchCompanies;
