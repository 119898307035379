import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import NoImage from "../../../images/noImage.png";
import makeApiCall from "../../../apiCall";
import { useNavigate } from "react-router";

const ProductLowDets = ({
  productId,
  productImage,
  productName,
  productType,
  productPurpose,
}) => {
  const navigate = useNavigate();

  const convertBase64ToImage = (base64String) => {
    return `data:image/png;base64,${base64String}`;
  };
  const handleShowProductDetails = (productId) => {
    navigate(`/user/products/${productName}`, { state: { productId } });
  };

  return (
    <React.Fragment>
      <div className="w-auto px-0">
        <Card
          key={productId}
          onClick={() => handleShowProductDetails(productId)}
          className="rounded-3 px-1 my-md-3 my-1 cursor-pointer mx-md-2 text-center product-low-dets-card"
        >
          <Card.Img
            className="product-low-dets-img mx-auto border rounded-3"
            src={
              productImage != null
                ? convertBase64ToImage(productImage)
                : NoImage
            }
            alt="Image unavailable"
          />
          <Card.Title className="m-auto px-2 pb-2 m-0 w-100 text-start">
            <div className="mt-2 mb-1 text-start h6 product-low-dets-name fw-bold">
              {productName}
            </div>
            <div className=" justify-content-between d-flex w-100 my-0">
              <div className="small-category-name mb-0 product-low-dets-name">
                {productType}
              </div>
              <div className="small-category-name mb-0">
                ({productPurpose}er)
              </div>
            </div>
          </Card.Title>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ProductLowDets;
