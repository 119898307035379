import React, { useEffect, useState } from "react";
import OfferFormat from "./offerFormat";
import { useNavigate } from "react-router";
import { Alert, Button } from "react-bootstrap";
import PaginationComponent from "../../pagination/pagination";
import makeApiCall from "../../../apiCall";

const OfferTable = () => {
  const [offers, setOffers] = useState([]);
  const [alert, setAlert] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [PageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "IntelligentOffer",
          apiName: "GetUserIntelligentOffers",
          paginated: true,
          pageNumber: PageNumber,
          pageSize: "10",
          loading: true,
        });

        setTotalPages(response.data.totalPages);
        setOffers(response.data.intelligentOffers);

        const formattedDates = response.data.intelligentOffers.map((offer) => {
          if (!offer.createdAt) {
            return "No date";
          }
          return new Date(offer.createdAt).toLocaleString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          });
        });

        setFormattedData(formattedDates);
      } catch (error) {
        console.error("Error fetching company detail:", error);
        setAlert(true);
      }
    };

    fetchData();
  }, [PageNumber]);

  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDelete = (offerId) => {
    setOffers(offers.filter((offer) => offer.id !== offerId));
  };

  return (
    <React.Fragment>
      {alert ? (
        <React.Fragment>
          <Alert variant="danger" className="mt-5 h3 text-center">
            You don't have an active offer
          </Alert>
          <div className="col mx-auto justify-content-center">
            <Button
              className=" border-1 border-dark p-2 bg-transparent  focus-hover"
              href="/intelligentoffer"
            >
              <div className="h6 font-45474B col  font-weight-600 my-auto">
                New Intelligent Offer +
              </div>
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="w-100 border-top border-bottom border-dark my-2 my-5 col-md-11 text-center h4 fw-bold mb-0 mx-auto py-1">
            Offers
          </div>
          <td className="col-md-3 d-block my-3 text-center d-md-none">
            <Button
              className=" border-1 border-dark p-2 bg-transparent  focus-hover"
              href="/intelligentoffer"
            >
              <div className="h6 font-45474B col  font-weight-600 my-auto">
                New Intelligent Offer +
              </div>
            </Button>
          </td>
          <div className="my-md-5 my-4 mx-auto col-md-10 col-11">
            <table className="col-12 h5 table-responsive">
              <thead>
                <tr className=" border-0 border-bottom border-dark d-flex align-items-center py-2 border-3">
                  <td className="col-md-3 d-none d-sm-block">
                    <Button
                      className=" border-1 border-dark p-2 bg-transparent  focus-hover"
                      href="/intelligentoffer"
                    >
                      <div className="h6 font-45474B col  font-weight-600 my-auto">
                        New Intelligent Offer +
                      </div>
                    </Button>
                  </td>
                  <td className="col-md-1 col-3 d-none d-sm-block text-center">Product</td>
                  <td className="col-md-3 col-6 text-center">Title</td>
                  <td className="col-md-2 col-2 d-none d-sm-block text-center">Role</td>
                  <td className="col-md-2 col-4 text-center">Created at</td>
              
                </tr>
              </thead>
            </table>
            {offers.map((offer, index) => (
              <div key={index} className="">
                <div style={{ cursor: "pointer" }}>
                  <OfferFormat
                    offerId={offer.id}
                    offerTitle={offer.title}
                    Role={offer.intelligentOfferRequestType}
                    ProductName={offer.productName}
                    createdAt={formattedData[index]}
                    image={`data:image/png;base64,${offer.productImage}`}
                    onDelete={handleDelete}
                  />
                </div>
              </div>
            ))}
            <div className="pagination-container  mt-3 d-flex justify-content-center">
              <PaginationComponent
                PageNumber={PageNumber}
                TotalPages={totalPages}
                handlePaginationClick={handlePaginationClick}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OfferTable;
