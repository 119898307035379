import React, { useEffect, useState } from "react";
import makeApiCall from "../../apiCall";
import searchImage from "../../images/categories/Search engine.svg";
import { useNavigate } from "react-router";
const Categories = () => {
  const [categories, setCategories] =  useState(JSON.parse(localStorage.getItem("categories")));
  const navigate = useNavigate();


  const handleCategoryClick = (categoryName) => {
    navigate(`${categoryName}`);
  };

  const getCategoryNameClass = (name) => {
    if (name.length >= 20) return "small-category-name";
    if (name.length > 14) return "medium-category-name";
    return "large-category-name";
  };

  const renderCategory = (categoryName, categoryId) => (
    <div
      onClick={() => handleCategoryClick(categoryName)}
      className="w-100 border-45474B rounded-3 my-0 cursor-pointer"
    >
      <img
        src={require(`../../images/categories/${categoryId}.jpg`)}
        alt={categoryName}
        className="rounded-top-3 category-img"
      />
      <div
        className={`category-name px-1 fw-bold  text-center align-content-center ${getCategoryNameClass(
          categoryName
        )}`}
      >
        {categoryName}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="w-100 text-center mt-5">
        <img className="col-md-3 col-8" src={searchImage} />
        <div className="text-center mt-4 h5 px-1 mb-4">
          Achieving the desired result by choosing a category
        </div>
      </div>
      {categories ? (
        <div className="d-flex justify-content-center flex-wrap w-100">
          {categories.map((category) => (
            <div
              className="category-container rounded-3 mx-md-4 mx-0 my-md-4 my-1"
              key={category.id}
            >
              {renderCategory(category.name, category.id)}
            </div>
          ))}
        </div>
      ) : (
        <div>No categories available</div>
      )}
    </React.Fragment>
  );
};

export default Categories;
