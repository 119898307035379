import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import makeApiCall from "../../apiCall";
import searchImage from "../../images/categories/Search engine.svg";
import { useNavigate } from "react-router";
const Landingcategories = ({ image, title, category, onClick }) => {
  const [categories, setCategories] = useState(JSON.parse(localStorage.getItem("categories")));
  const navigate = useNavigate();

  const handleCategoryClick = (categoryName) => {
    navigate(`/categories/${categoryName}`);
  };

  return (
    <React.Fragment>
      <div className="h3 ps-2 text-left fw-bold col-md-11 col-12 mx-auto justify-content-center mt-5">
        <div className="border-dark border-bottom mx-4 pb-2">
          Most visited categories
        </div>
      </div>
      <div className="d-flex justify-content-center flex-wrap my-5 mt-2">
        <div className="category-container rounded-3 mx-md-3 mx-0 my-md-4 my-1">
          <div
            className="w-100 border-45474B rounded-3 cursor-pointer my-0"
            onClick={() => handleCategoryClick("Automobiles")}
          >
            <img
              src={require(`../../images/categories/3.jpg`)}
              alt="Automobiles"
              className="rounded-top-3 category-img"
            />
            <div
              className={`category-name px-1 fw-bold  text-center align-content-center`}
            >
              Automobiles
            </div>
          </div>
        </div>

        <div className="category-container rounded-3 mx-md-3 mx-0 my-md-4 my-1">
          <div
            className="w-100 border-45474B rounded-3 cursor-pointer my-0"
            onClick={() => handleCategoryClick("Apparel & Clothing")}
          >
            <img
              src={require(`../../images/categories/2.jpg`)}
              alt="Apparel & Clothing"
              className="rounded-top-3 category-img"
            />
            <div
              className={`category-name px-1 fw-bold  text-center align-content-center`}
            >
              Apparel & Clothing
            </div>
          </div>
        </div>

        <div className="category-container rounded-3 mx-md-3 mx-0 my-md-4 my-1">
          <div
            className="w-100 border-45474B rounded-3 cursor-pointer my-0"
            onClick={() => handleCategoryClick("Food & Beverage")}
          >
            <img
              src={require(`../../images/categories/14.jpg`)}
              alt="Food & Beverage"
              className="rounded-top-3 category-img"
            />
            <div
              className={`category-name px-1 fw-bold  text-center align-content-center`}
            >
              Food & Beverage
            </div>
          </div>
        </div>

        <div className="category-container rounded-3 mx-md-3 mx-0 my-md-4 my-1">
          <div
            className="w-100 border-45474B rounded-3 cursor-pointer my-0"
            onClick={() => handleCategoryClick("Health & Medicines")}
          >
            <img
              src={require(`../../images/categories/18.jpg`)}
              alt="Health & Medicines"
              className="rounded-top-3 category-img"
            />
            <div
              className={`category-name px-1 fw-bold  text-center align-content-center`}
            >
              Health & Medicines
            </div>
          </div>
        </div>

        <div className="category-container rounded-3 mx-md-3 mx-0 my-md-4 my-1">
          <div
            className="w-100 border-45474B rounded-3 cursor-pointer my-0"
            onClick={() => handleCategoryClick("Hardware & Mechanical Parts")}
          >
            <img
              src={require(`../../images/categories/17.jpg`)}
              alt="Hardware & Mechanical Parts"
              className="rounded-top-3 category-img"
            />
            <div
              className={`category-name px-1 fw-bold  text-center align-content-center`}
            >
              Hardware & Mechanical Parts
            </div>
          </div>
        </div>

        <div className="category-container rounded-3 mx-md-3 mx-0 my-md-4 my-1">
          <div
            className="w-100 border-45474B rounded-3 cursor-pointer my-0"
            onClick={() => handleCategoryClick("Gifts & Crafts")}
          >
            <img
              src={require(`../../images/categories/16.jpg`)}
              className="rounded-top-3 category-img"
            />
            <div
              className={`category-name px-1 fw-bold  text-center align-content-center`}
            >
              Gifts & Crafts
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Landingcategories;
