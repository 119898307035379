import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import makeApiCall from "../../../apiCall";
import offerImg from "../../../images/offer.png";
import { useNavigate } from "react-router-dom";
const OfferFormat = ({
  offerId,
  offerTitle,
  Role,
  ProductName,
  createdAt,
  image,
  key,
  onDelete,
}) => {
  const [isSure, setIsSure] = useState(false);
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const handleIsSureModal = () => {
    setIsSure(true);
  };

  const handleDelete = async (offerId) => {
    try {
      await makeApiCall({
        restapi: "delete",
        controller: "IntelligentOffer",
        apiName: "DeleteIntelligentOffer",
        hasId: offerId,
        loading: true,
      });
      onDelete(offerId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOfferClick = (offerId) => {
    navigate(`/offers/${offerId}`, { state: { offerId } });
  };

  return (
    <div className=" table-responsive my-1 ">
      <table className=" table-active bg-F2F2F2 rounded-3 focus-hover cursor-pointer py-md-0 py-5  rounded col-12 ">
        <tbody>
          <tr onClick={() => handleOfferClick(offerId)} key={key}>
            {!isMobileView && (
              <React.Fragment>
                <td
                  className={`py-1 text-center  ${isMobileView ? "h6" : "h5"}`}
                >
                  <Image
                    thumbnail
                    className="offers-image"
                    src={
                      image !== "data:image/png;base64,null" ? image : offerImg
                    }
                  />
                </td>
                <td
                  className={`py-1 col-md-2 px-2 text-left ${
                    isMobileView ? "h6" : "h5"
                  }`}
                >
                  <span className=" ">{ProductName}</span>
                </td>
              </React.Fragment>
            )}
            <td
              className={`py-1 col-md-3 px-2 text-ellipsis-230 offer-low-dets-title ${
                isMobileView ? "h6 text-start" : "h5 text-center"
              }`}
            >
              {offerTitle}
            </td>
            {!isMobileView && (
              <td
                className={`py-1 col-md-2 px-2 text-center ${
                  isMobileView ? "h6" : "h5"
                }`}
              >
                {Role}
              </td>
            )}
            <td
              className={`py-1 col-md-2 px-2 text-center ${
                isMobileView ? "h6" : "h5"
              }`}
            >
              {createdAt}
            </td>
            <td
              className={`py-1 col-md-1 px-2 text-center ${
                isMobileView ? "h6" : "h5"
              }`}
            >
              <button
                className=" bg-transparent  border-0 p-2 focus-hover rounded-pill"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleIsSureModal();
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  size="lg"
                  className=" text-danger "
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className=" bg-info d-flex  justify-content-center ">
        <Modal
          centered
          onBackdropClick={(e) => e.stopPropagation()}
          className=" d-flex justify-content-center  text-center"
          show={isSure}
          onHide={() => {
            setIsSure(false);
          }}
        >
          <Modal.Header className="bg-light mb-0 h4">
            Are You Sure?
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsSure(false);
              }}
              className="btn-close remove-icon"
            ></button>
          </Modal.Header>
          <Modal.Body className="bg-light text-center pt-4 rounded px-1">
            <span
              className={`${
                isMobileView ? "h6" : "h5"
              } text-danger mx-md-5 mx-auto`}
            >
              * related conversations will also be deleted. *
            </span>
            <div className="mt-4 mb-0 d-md-block d-flex w-100">
              <Button
                variant="light"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSure(false);
                  handleDelete(offerId);
                }}
                className={`border border-secondary ${
                  !isMobileView && "btn-lg"
                } my-md-3 my-2 mx-2`}
              >
                Yes, Delete Offer
              </Button>
              <Button
                variant="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSure(false);
                }}
                className={`${!isMobileView && "btn-lg"} my-md-3 my-2 mx-2`}
              >
                Cancel, keep offer
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default OfferFormat;
