import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import step1 from "../../images/step1.png";
import step2 from "../../images/step2.png";
import step3 from "../../images/step3.png";
import step4 from "../../images/step4.png";
import step5 from "../../images/step5.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GuideSlider = () => {
  const settings = {
    className: "align-content-center py-0",
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "20%",

    arrows: false,
    swipeToSlide: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 400,

    responsive: [
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "0%",
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="guide-slider align-content-center pb-5">
        <div className="display-2 mb-md-2 mb-5 text-center text-white fw-bold">
          how to start
        </div>
        <Slider {...settings}>
          <div className="slider-item-no-border">
            <img
              className="w-100 rounded guide-slider-content"
              src={step1}
              alt="Product 1"
            />
          </div>
          <div className="slider-item-no-border">
            <img
              className="w-100 rounded guide-slider-content"
              src={step2}
              alt="Product 1"
            />
          </div>
          <div className="slider-item-no-border">
            <img
              className="w-100 rounded guide-slider-content"
              src={step3}
              alt="Product 1"
            />
          </div>
          <div className="slider-item-no-border">
            <img
              className="w-100 rounded guide-slider-content"
              src={step4}
              alt="Product 1"
            />
          </div>
          <div className="slider-item-no-border">
            <img
              className="w-100 rounded guide-slider-content"
              src={step5}
              alt="Product 1"
            />
          </div>
        </Slider>
      </div>
    </React.Fragment>
  );
};

export default GuideSlider;
