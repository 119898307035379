import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import makeApiCall from "./../../../apiCall";
import PaginationComponent from "../../pagination/pagination";
import ProductLowDets from "./productLowDets";
import Filter from "../../filter/filter";

const AllProducts = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allTotalPages, setAllTotalPages] = useState(1);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const [roleFilters, setRoleFilters] = useState("");
  const [tempRoleFilters, setTempRoleFilters] = useState([]);
  const [tempPageNumber, setTempPageNumber] = useState(0);
  const [filterData, setFilterData] = useState({
    titles: [],
    data: {
      role: [],
    },
    checkedData: {
      role: [],
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          (roleFilters.length == 0 || roleFilters.length == 2) &&
          tempPageNumber != pageNumber
        ) {
          setTempPageNumber(pageNumber);
          const response = await makeApiCall({
            restapi: "get",
            controller: "CustomerProduct",
            apiName: "GetAllProducts",
            paginated: true,
            pageNumber: pageNumber,
            pageSize: 20,
            loading: true,
          });

          if (pageNumber == 1) {
            setAllProducts(response.data.products);
          }

          setAllTotalPages(response.data.totalPages);
          setProducts(response.data.products);
          setTotalPages(response.data.totalPages);
        } else {
          setTempPageNumber(1);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [pageNumber]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (tempRoleFilters != roleFilters) {
        setTempRoleFilters(roleFilters);
        setPageNumber(1);
      }
      try {
        if (roleFilters.length == 1) {
          let more = "";
          if (roleFilters) {
            more += `&productPurposeName=${roleFilters}`;
          }
          const response = await makeApiCall({
            restapi: "get",
            controller: "CustomerProduct",
            apiName: "FilterProduct",
            paginated: true,
            pageNumber: pageNumber,
            pageSize: 20,
            loading: true,
            more: more,
          });
          setProducts(response.data.products);
          setTotalPages(response.data.totalPages);
        } else if (roleFilters.length == 0 || roleFilters.length == 2) {
          console.log("yess");
          setProducts(allProducts);
          setTotalPages(allTotalPages);
        }

        setNoResult(false);
      } catch (error) {
        setNoResult(true);
        console.error("Error fetching companies:", error);
      }
    };

    fetchProducts();
  }, [pageNumber, roleFilters]);

  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  useEffect(() => {
    if (filterData.checkedData) {
      const { role = [] } = filterData.checkedData;
      if (role.length == 2 || role.length == 0) {
        setRoleFilters("");
        console.log("going to page 1");
      } else if (role[0]) {
        setRoleFilters(role);
      }
    }
  }, [filterData]);

  useEffect(() => {
    setFilterData((prevData) => ({
      ...prevData,
      titles: [{ name: "role", expanded: false }],
      data: {
        ...prevData.data,
        role: [{ name: "Buy" }, { name: "Sell" }],
      },
      checkedData: {
        ...prevData.checkedData,
        role: [],
      },
    }));
  }, []);
  return (
    <React.Fragment>
      <Filter filterData={filterData} setFilterData={setFilterData} />
      <Row className=" ms-md-auto mx-auto d-md-flex justify-content-center col-md-9 me-md-5 mt-4 my-3">
        {products &&
          products.map((product) => (
            <ProductLowDets
              key={product.id}
              productId={product.id}
              productImage={product.images}
              productName={product.name}
              productPurpose={product.productPurpose}
              productType={product.productType}
            />
          ))}
      </Row>
      <div className="col-md-10 ms-auto d-flex justify-content-center my-5">
        <PaginationComponent
          PageNumber={pageNumber}
          TotalPages={totalPages}
          handlePaginationClick={handlePaginationClick}
        />
      </div>
      {noResult && (
        <div>
          there's a problem <br />
          Please try again later
        </div>
      )}
    </React.Fragment>
  );
};

export default AllProducts;
