import React, { useEffect, useState } from "react";
import makeApiCall from "../../../apiCall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faFloppyDisk,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import avatar from "../../../images/avatar.png";

const UserProfile = ({ onUpdateUserProfile }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [defaultUserProfile, setDefaultUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [profileFailed, setprofileFailed] = useState(false);

  useEffect(() => {
    setImagePreview(null);
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        const response = await makeApiCall({
          restapi: "get",
          controller: "Profile",
          apiName: "GetUserProfile",
          loading: true,
        });
        setUserProfile(response.data);
        setDefaultUserProfile(response.data);
        setLoading(false);
      } catch (error) {
        setprofileFailed(true);
        console.error("Failed to fetch user profile", error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [reRender]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.replace(
          /^data:image\/[a-z]+;base64,/,
          ""
        );
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    const hasChanged = (fieldName, newValue, initialValue) => {
      return newValue !== initialValue;
    };

    if (hasChanged("Name", userProfile.name, defaultUserProfile.name))
      formData.append("Name", userProfile.name);
    if (
      hasChanged(
        "PhoneNumber",
        userProfile.phoneNumber,
        defaultUserProfile.phoneNumber
      )
    )
      formData.append("PhoneNumber", userProfile.phoneNumber);
    if (hasChanged("Email", userProfile.email, defaultUserProfile.email))
      formData.append("Email", userProfile.email);
    if (
      hasChanged("UserName", userProfile.userName, defaultUserProfile.userName)
    )
      formData.append("UserName", userProfile.userName);
    if (
      hasChanged(
        "TelePhoneNumber",
        userProfile.telePhoneNumber,
        defaultUserProfile.telePhoneNumber
      )
    )
      formData.append("TelePhoneNumber", userProfile.telePhoneNumber);
    if (
      hasChanged(
        "TelegramUserName",
        userProfile.telegramTelegramUserName,
        defaultUserProfile.telegramTelegramUserName
      )
    )
      formData.append("TelegramUserName", userProfile.telegramTelegramUserName);
    if (
      hasChanged(
        "WhatsAppPhoneNumber",
        userProfile.whatsAppPhoneNumber,
        defaultUserProfile.whatsAppPhoneNumber
      )
    )
      formData.append("WhatsAppPhoneNumber", userProfile.whatsAppPhoneNumber);
    if (
      hasChanged(
        "FacebookAccount",
        userProfile.facebookAccount,
        defaultUserProfile.facebookAccount
      )
    )
      formData.append("FacebookAccount", userProfile.facebookAccount);
    if (
      hasChanged(
        "LinkedinAccount",
        userProfile.linkedinAccount,
        defaultUserProfile.linkedinAccount
      )
    )
      formData.append("LinkedinAccount", userProfile.linkedinAccount);
    if (
      hasChanged(
        "Bussines_Email",
        userProfile.bussines_Email,
        defaultUserProfile.bussines_Email
      )
    )
      formData.append("Bussines_Email", userProfile.bussines_Email);

    if (image && image !== userProfile.images) {
      formData.append("Image", image);
    }

    try {
      const response = await makeApiCall({
        restapi: "put",
        controller: "Profile",
        apiName: "UpdateUserProfile",
        hasId: userProfile.id,
        data: formData,
        loading: true,
      });
      console.log("Profile updated successfully", response);
      localStorage.setItem("userName", userProfile.userName);
      if (image) {
        const base64Image = await convertToBase64(image);
        localStorage.setItem("userImage", base64Image);
      }

      setReRender(!reRender);
      setEditMode(false);
    } catch (error) {
      console.error("Failed to update profile", error);
    }
  };

  const handleEditMode = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  const handleCancelClick = () => {
    setEditMode(false);
    setUserProfile(defaultUserProfile);
  };
  const renderField = (label, value, name, type = "text") => {
    return (
      <div className="form-group col-md-6 me-auto my-md-2 my-0 px-md-5 px-3">
        <div className="mx-auto my-md-1 my-0 w-100">
          <label className="mt-2">{label}</label>
        </div>
        {editMode ? (
          <input
            type={type}
            className="form-control form-control-no-focus ps-2 border-0 border-secondary border-bottom rounded-0 w-100 mx-auto"
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
          />
        ) : (
          <div
            className="form-control-plaintext mt-0 w-100 ps-2 text-secondary mx-auto border-bottom"
            id={name}
          >
            {value}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container mt-4 col-md-10">
      {userProfile && (
        <React.Fragment>
          <div className="card border-warning my-5 rounded-5 py-2">
            <div className="mt-3 align-items-center text-dark d-flex justify-content-between">
              <h3 className="mb-0 ps-5">Profile</h3>
              <div className="align-content-center">
                {editMode ? (
                  <React.Fragment>
                    <button
                      onClick={() => handleCancelClick()}
                      className="btn bg-transparent border-0 focus-hover mx-2 px-3 py-2 text-danger"
                    >
                      <FontAwesomeIcon icon={faCancel} size="xl" />
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="btn bg-transparent border-0 focus-hover mx-2 px-3 py-2"
                    >
                      <FontAwesomeIcon
                        icon={faFloppyDisk}
                        size="xl"
                        color="#45474B"
                      />
                    </button>
                  </React.Fragment>
                ) : (
                  <button
                    type="button"
                    className="btn bg-transparent border-0 focus-hover mx-2   px-3 py-2"
                    onClick={handleEditMode}
                  >
                    <FontAwesomeIcon icon={faPen} size="xl" color="#45474B" />
                  </button>
                )}
              </div>
            </div>
            <div className="card-body my-3">
              <form>
                <div className="row">
                  <div className="d-md-flex col-md-6 col px-md-5 px-3">
                    <div className="text-start d-flex col-3 justify-content-start ">
                      <img
                        src={
                          imagePreview ||
                          (userProfile?.images !== null
                            ? `data:image/png;base64,${userProfile?.images}`
                            : avatar)
                        }
                        alt="User Profile"
                        className={`rounded-circle  object-fit-contain border border-dark ${
                          editMode && " cursor-pointer focus-hover p-1"
                        }`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        onClick={() =>
                          editMode &&
                          document.getElementById("imageUpload").click()
                        }
                      />
                      {editMode && (
                        <input
                          type="file"
                          id="imageUpload"
                          className="d-none"
                          onChange={handleImageChange}
                        />
                      )}
                    </div>
                    <div className="form-group col-md col mx-auto">
                      <div className="mx-auto my-md-1 my-0 w-100">
                        <label className="mt-2">Name</label>
                      </div>
                      {editMode ? (
                        <input
                          type="text"
                          className="form-control form-control-no-focus ps-2 border-0 border-secondary border-bottom rounded-0 w-100 mx-auto"
                          name={"name"}
                          value={userProfile?.name}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <div className="form-control-plaintext mt-0 w-100 ps-2 text-secondary mx-auto border-bottom">
                          {userProfile?.name}
                        </div>
                      )}
                    </div>
                  </div>
                  {renderField(
                    "Phone Number",
                    userProfile?.phoneNumber,
                    "phoneNumber"
                  )}
                </div>
                <div className="row mb-3">
                  {renderField("Email", userProfile?.email, "email", "email")}
                  {renderField("Username", userProfile?.userName, "userName")}
                </div>
                <div className="row mb-3">
                  {renderField(
                    "Telephone Number",
                    userProfile?.telePhoneNumber,
                    "telePhoneNumber"
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="card border-warning my-5 rounded-5 py-2">
            <h3 className="mb-0 ps-5 mt-4">Communication</h3>
            <div className="card-body my-3">
              <form>
                <div className="row mb-3">
                  {renderField(
                    "Telegram Username",
                    userProfile?.telegramTelegramUserName,
                    "telegramTelegramUserName"
                  )}
                  {renderField(
                    "WhatsApp Phone Number",
                    userProfile?.whatsAppPhoneNumber,
                    "whatsAppPhoneNumber"
                  )}
                </div>
                <div className="row mb-3">
                  {renderField(
                    "Facebook Account",
                    userProfile?.facebookAccount,
                    "facebookAccount"
                  )}
                  {renderField(
                    "LinkedIn Account",
                    userProfile?.linkedinAccount,
                    "linkedinAccount"
                  )}
                </div>
                <div className="row mb-3">
                  {renderField(
                    "Business Email",
                    userProfile?.bussines_Email,
                    "bussines_Email",
                    "email"
                  )}
                </div>
              </form>
            </div>
          </div>
        </React.Fragment>
      )}
      {profileFailed && (
        <div className="w-100 h2 mb-0 mt-5 pt-5 text-center">
          There was a problem loading your Profile <br />
          Please try again later
        </div>
      )}
    </div>
  );
};

export default UserProfile;
