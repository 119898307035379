import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Alert, Col, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import makeApiCall from "../../../apiCall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faChevronDown,
  faClose,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import chooseFileImg from "../../../images/choosefileimg.svg";
import closeBtnThin from "../../../images/closeButtonThin.svg";
import { FaInfoCircle } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const AddCompany = () => {
  const [countries, setCountries] =  useState(JSON.parse(localStorage.getItem("countries")));
  const [categories, setCategories] =  useState(JSON.parse(localStorage.getItem("categories")));
  const [selectedCategoryIsTrue, setSelectedCategoryIsTrue] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [isSubCategoriesExpanded, setIsSubCategoriesExpanded] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const navigate = useNavigate();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [finishedStep1, setFinishedStep1] = useState(false);
  const [lcImages, setLcImages] = useState([]);
  const [lcImageURLs, setLcImageURLs] = useState([]);
  const [dpImages, setDpImages] = useState([]);
  const [dpImageURLs, setDpImageURLs] = useState([]);
  const [capImages, setCapImages] = useState([]);
  const [capImageURLs, setCapImageURLs] = useState([]);
  const [otherCertificateImages, setOtherCertificateImages] = useState([]);
  const [otherCertificateImageURLs, setOtherCertificateImageURLs] = useState(
    []
  );
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [countryName, setCountryName] = useState("");
  const [address, setAddress] = useState("");
  const [bio, setBio] = useState("");
  const [category, setCategory] = useState("");
  const lcInputRef = useRef(null);
  const dpInputRef = useRef(null);
  const capInputRef = useRef(null);
  const otherCertificateInputRef = useRef(null);
  const [certificateLinks, setCertificateLinks] = useState([
    { name: "", address: "" },
  ]);
  const [makeSpecNull, setMakeSpecNull] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadedImage(file);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedSubCategories = [...subCategory];
    console.log(value);
    if (checked && !updatedSubCategories.includes(value)) {
      updatedSubCategories.push(value);
    } else if (!checked && updatedSubCategories.includes(value)) {
      updatedSubCategories = updatedSubCategories.filter(
        (sub) => sub !== value
      );
    }

    setSubCategory(updatedSubCategories);
    console.log(subCategory);
  };

  const toggleSubCategories = () => {
    setIsSubCategoriesExpanded((prev) => !prev);
    console.log(isSubCategoriesExpanded);
  };

  const handleCategoryChange = async (e) => {
    const { value } = e.target;
    setCategory(value);
    setSelectedCategoryIsTrue(value !== "");
    setSubCategories([]);

    try {
      const selectedCategory = categories.find(
        (category) => category.name === value
      );
      if (selectedCategory) {
        const categoryId = selectedCategory.id;
        setSelectedCategoryIsTrue(true);
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "GetSubCategory",
          hasId: categoryId,
        });
        const subCategoriesData = response.data;
        const subCategoryNames = subCategoriesData.map(
          (subCategory) => subCategory.name
        );
        setSubCategories(subCategoryNames);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleFinishStep1 = (e) => {
    e.preventDefault();

    if (companyName.trim() === "") {
      setRegisterError("Please enter company name");
    } else if (website.trim() === "") {
      setRegisterError("Please enter website");
    } else if (telephoneNumber.trim() === "") {
      setRegisterError("Please enter telephone number");
    } else if (email.trim() === "") {
      setRegisterError("Please enter email");
    } else if (countryName.trim() === "") {
      setRegisterError("Please enter country name");
    } else if (address.trim() === "") {
      setRegisterError("Please enter address");
    } else if (category.trim() === "") {
      setRegisterError("Please enter category");
    } else if (subCategory.length === 0) {
      setRegisterError("Please enter atleast 1 subcategory");
    } else {
      console.log("All fields are filled, proceeding to the next stage");
      setRegisterError("");
      setFinishedStep1(true);
    }
  };
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target);

      subCategory.forEach((subCategory) => {
        formData.append("subCategory[]", subCategory);
      });

      formData.append("name", companyName);
      formData.append("email", email);
      formData.append("telephoneNumber", telephoneNumber);
      formData.append("website", website);
      formData.append("countryName", countryName);
      formData.append("category", category);
      formData.append("address", address);
      formData.append("bio", bio);
      formData.append("image", uploadedImage);

      let totalFileSize = 0;
      const imageArrays = [
        lcImages,
        dpImages,
        capImages,
        otherCertificateImages,
      ];
      const imageKeys = ["LC", "DP", "CAP", "OtherCertificate"];

      imageArrays.forEach((images, index) => {
        images.forEach((image) => {
          formData.append(imageKeys[index], image);
          totalFileSize += image.size;
        });
      });

      const certificateLinksObj = {};

      certificateLinks.forEach((link) => {
        if (!(link.name === "" && link.address === "")) {
          certificateLinksObj[link.name] = link.address;
        } else {
          setMakeSpecNull(true);
        }
      });

      const certificateLinksJSON = JSON.stringify(certificateLinksObj);

      if (!makeSpecNull)
        formData.append("OtherCertificateLinks", certificateLinksJSON);
      else formData.append("OtherCertificateLinks", null);

      console.log(totalFileSize);
      const MAX_IMAGE_SIZE = 1024 * 1024 * 19;

      if (totalFileSize > MAX_IMAGE_SIZE) {
        setError("Request size exceeds the allowed limit");
        console.log("Total image size exceeds 19 MB");
      } else {
        try {
          const response = await makeApiCall({
            restapi: "post",
            controller: "CustomerCompany",
            apiName: "RegisterCompany",
            data: formData,
          });

          toast.success("Company registered successfully!", {
            onClose: () => navigate("/user/companies"),
          });
          console.log(response);
        } catch (error) {
          if (
            error.response.data &&
            error.response.data === "Company Already Exist"
          ) {
            setFinishedStep1(false);
            return setRegisterError("This Company email Already Exists.");
          }

          if (error.response.data.errors) {
            const errorResponse = error.response.data.errors;

            const errorMessages = Object.keys(errorResponse).map((key) => {
              return `${errorResponse[key].join(", ")}`;
            });

            const errorMessageElements = errorMessages.map((message, index) => (
              <div key={index}>{message}</div>
            ));

            setRegisterError(errorMessageElements);
            setFinishedStep1(false);
          }
          if (error.request.status === 413) {
            setError("Entity too large");
            console.log("Entity too large");
          } else {
            console.log(error);
          }

          if (error.request.status === 413) {
            setError("Entity too large");
            console.log("Entity too large");
          } else {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCertificateImageUpload = (event, category) => {
    const files = Array.from(event.target.files);

    const fileURLs = files.map((file) => URL.createObjectURL(file));

    switch (category) {
      case "LC":
        setLcImages([...lcImages, ...files]);
        setLcImageURLs([...lcImageURLs, ...fileURLs]);
        break;
      case "DP":
        setDpImages([...dpImages, ...files]);
        setDpImageURLs([...dpImageURLs, ...fileURLs]);
        break;
      case "CAP":
        setCapImages([...capImages, ...files]);
        setCapImageURLs([...capImageURLs, ...fileURLs]);
        break;
      case "OtherCertificateImages":
        setOtherCertificateImages([...otherCertificateImages, ...files]);
        setOtherCertificateImageURLs([
          ...otherCertificateImageURLs,
          ...fileURLs,
        ]);
        break;
      default:
        break;
    }
  };

  const triggerFileInput = (ref) => {
    ref.current.click();
  };

  const removeImage = (category, index) => {
    switch (category) {
      case "LC":
        setLcImages(lcImages.filter((_, i) => i !== index));
        setLcImageURLs(lcImageURLs.filter((_, i) => i !== index));
        break;
      case "DP":
        setDpImages(dpImages.filter((_, i) => i !== index));
        setDpImageURLs(dpImageURLs.filter((_, i) => i !== index));
        break;
      case "CAP":
        setCapImages(capImages.filter((_, i) => i !== index));
        setCapImageURLs(capImageURLs.filter((_, i) => i !== index));
        break;
      case "OtherCertificateImages":
        setOtherCertificateImages(
          otherCertificateImages.filter((_, i) => i !== index)
        );
        setOtherCertificateImageURLs(
          otherCertificateImageURLs.filter((_, i) => i !== index)
        );
        break;
      default:
        break;
    }
  };

  const handleCertificateLinkChange = (index, field, value) => {
    const updatedLinks = [...certificateLinks];
    updatedLinks[index][field] = value;
    setCertificateLinks(updatedLinks);
  };

  const handleAddCertificateLink = () => {
    setCertificateLinks([...certificateLinks, { name: "", address: "" }]);
  };

  const handleDeleteCertificateLink = (index) => {
    const updatedLinks = [...certificateLinks];
    updatedLinks.splice(index, 1);
    setCertificateLinks(updatedLinks);
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <ToastContainer position="top-center" />
        {!finishedStep1 ? (
          <React.Fragment>
            <div className="d-flex fw-bold justify-content-center">
              <div className="col-11">
                <Col className="col-11 col-md-8 mx-auto p-3 mb-5 rounded-5 my-5 bg-light border border-3 border-warning">
                  <Row className="mb-3">
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">Company</Form.Label>
                      <Form.Control
                        className=" border-secondary-subtle shadow"
                        type="text"
                        name="name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Enter Company's Name"
                        required
                      />
                    </Col>
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">Website</Form.Label>
                      <Form.Control
                        required
                        className=" border-secondary-subtle shadow"
                        type="text"
                        value={website}
                        placeholder="www.example.com"
                        name="website"
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">
                        Company telephone Number
                      </Form.Label>
                      <Form.Control
                        className=" border-secondary-subtle shadow"
                        minLength={8}
                        maxLength={12}
                        value={telephoneNumber}
                        pattern="[0-9]*"
                        placeholder="Please Enter Numbers Only"
                        type="text"
                        name="telephoneNumber"
                        onChange={(e) => setTelephoneNumber(e.target.value)}
                        required
                      />
                    </Col>
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">Email</Form.Label>
                      <Form.Control
                        className=" border-secondary-subtle shadow"
                        type="email"
                        value={email}
                        placeholder="name@example.com"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">Country</Form.Label>
                      <div className="position-relative">
                        <Form.Select
                          className="border-secondary-subtle shadow rounded"
                          name="CountryName"
                          value={countryName}
                          onChange={(e) => setCountryName(e.target.value)}
                          required
                        >
                          <option value="">Select country</option>
                          {countries.map((CountryName) => (
                            <option
                              key={CountryName.name}
                              value={CountryName.name}
                            >
                              {CountryName.name}
                            </option>
                          ))}
                        </Form.Select>

                        <Button className="rounded border bg-white text-dark rounded-start-0 border-secondary-subtle align-content-center position-absolute end-0 top-50 translate-middle-y">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </Button>
                      </div>
                    </Col>
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">Address</Form.Label>
                      <Form.Control
                        className=" border-secondary-subtle shadow"
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">Category</Form.Label>
                      <div className="position-relative">
                        <Form.Select
                          className="border-secondary-subtle shadow rounded"
                          name="category"
                          value={category}
                          onChange={handleCategoryChange}
                          required
                        >
                          <option value="">Select category</option>
                          {categories.map((category) => (
                            <option key={category.name} value={category.name}>
                              {category.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Button className="rounded border bg-white text-dark rounded-start-0 border-secondary-subtle align-content-center position-absolute end-0 top-50 translate-middle-y">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </Button>
                      </div>
                    </Col>
                    <Col xs={12} md={5} className="mx-auto ">
                      <Form.Label className="mt-3 mt-md-0">
                        Sub Category
                      </Form.Label>
                      <div
                        className={`dropdown-container bg-white shadow rounded   border border-secondary-subtle  ${
                          isSubCategoriesExpanded ? "" : " "
                        }`}
                      >
                        <Button
                          className={`form-control ps-4 checkbox-container-button position-relative  bg-transparent border-0 text-start text-dark ${
                            isSubCategoriesExpanded && ""
                          }`}
                          onClick={toggleSubCategories}
                          aria-expanded={isSubCategoriesExpanded}
                          disabled={!selectedCategoryIsTrue}
                        >
                          {selectedCategoryIsTrue
                            ? `${subCategory.length} selected`
                            : `Select Category`}
                          {selectedCategoryIsTrue && (
                            <Button className="rounded rounded-start-0 bg-white text-dark border border-secondary-subtle border-end-0 position-absolute end-0 top-50 translate-middle-y ">
                              <FontAwesomeIcon icon={faChevronDown} />
                            </Button>
                          )}
                        </Button>

                        <div
                          className={`checkbox-container  transparent border-0 fw-normal ${
                            isSubCategoriesExpanded ? "expanded" : "collapsed"
                          }`}
                        >
                          {Array.isArray(subCategories) ? (
                            subCategories.map((subCategory, index) => (
                              <Form.Check
                                className=" ms-3"
                                key={index}
                                type="checkbox"
                                label={subCategory}
                                value={subCategory}
                                onChange={handleCheckboxChange}
                              />
                            ))
                          ) : (
                            <div>Loading subcategories...</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={12} md={5} className="mx-auto">
                      <Form.Label className="mt-3 mt-md-0">Bio</Form.Label>
                      <Form.Control
                        className=" border-secondary-subtle shadow"
                        type="text"
                        placeholder="Enter bio"
                        name="bio"
                        as="textarea"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={5} className="mx-auto mb-2">
                      <Form.Label className="mt-3 mt-md-0 text-start col-12">
                        Company Image
                      </Form.Label>
                      <input
                        className="col-12 border border-secondary-subtle form-control"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </Col>
                  </Row>
                  {registerError && (
                    <Alert variant="danger" className="error">
                      {registerError}
                    </Alert>
                  )}
                  <div className="text-center">
                    <div className="mt-4 mb-2">
                      <Button
                        onClick={handleFinishStep1}
                        className="btn col-6 mx-auto col-md-2  1 fw-bold rounded text-center mt-4 btn-warning"
                      >
                        <h5 className="fw-bold mb-0">Next</h5>
                      </Button>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <Button
                className="border-warning border-2 py-2 bg-white rounded-5"
                style={{ position: "absolute", top: "20%", left: "12.5%" }}
                onClick={() => setFinishedStep1(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} color="#45474B" size="lg" />
              </Button>
              <div className="col-11 col-md-8 mx-auto px-5 py-4 mb-5 rounded-5 my-5 bg-white border border-3 border-warning">
                <h3 className="mb-0 fw-bold ">Certificates</h3>
                <div className="mb-5 mt-1">
                  <FaInfoCircle className=" text-secondary " /> Upload images or
                  links, including certificates, records, or information that
                  you think will positively impact other companies' perception
                  of your company's credibility.
                </div>
                <div className="mb-3 border border-secondary py-2 px-2 rounded-4">
                  <h5 className="fw-bold ps-3 pt-3">LC Images</h5>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => handleCertificateImageUpload(e, "LC")}
                    ref={lcInputRef}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="warning"
                    className="py-0 pe-0 ms-3 border-0 rounded-3 my-2 position-relative"
                    onClick={() => triggerFileInput(lcInputRef)}
                  >
                    Choose Files
                    <img
                      className="ms-3"
                      src={chooseFileImg}
                      alt="Choose files"
                    />
                  </Button>
                  <Swiper
                    slidesPerView={5}
                    spaceBetween={0}
                    className="py-2 bg-light rounded-4 align-content-center"
                  >
                    {lcImageURLs.map((url, index) => (
                      <SwiperSlide key={index} className="image-container mx-2">
                        <Image
                          src={url}
                          thumbnail
                          className="w-100 add-certificate-slider-item certificate-img object-fit-contain"
                          alt={`LC-${index}`}
                        />
                        <div
                          onClick={() => removeImage("LC", index)}
                          className="image-overlay cursor-pointer"
                        >
                          <img src={closeBtnThin} className="w-50" />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <div className="mb-3 border border-secondary py-2 px-2 rounded-4">
                  <h5 className="fw-bold ps-3 pt-3">DP Images</h5>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => handleCertificateImageUpload(e, "DP")}
                    ref={dpInputRef}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="warning"
                    className="py-0 pe-0 ms-3 border-0 rounded-3 my-2 position-relative"
                    onClick={() => triggerFileInput(dpInputRef)}
                  >
                    Choose Files
                    <img
                      className="ms-3"
                      src={chooseFileImg}
                      alt="Choose files"
                    />
                  </Button>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    className="py-2 bg-light rounded-4 align-content-center"
                  >
                    {dpImageURLs.map((url, index) => (
                      <SwiperSlide key={index} className="image-container mx-2">
                        <Image
                          src={url}
                          thumbnail
                          className="w-100 add-certificate-slider-item certificate-img object-fit-contain"
                          alt={`DP-${index}`}
                        />
                        <div
                          onClick={() => removeImage("DP", index)}
                          className="image-overlay cursor-pointer"
                        >
                          <img src={closeBtnThin} className="w-50" />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <div className="mb-3 border border-secondary py-2 px-2 rounded-4">
                  <h5 className="fw-bold ps-3 pt-3">CAP Images</h5>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => handleCertificateImageUpload(e, "CAP")}
                    ref={capInputRef}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="warning"
                    className="py-0 pe-0 ms-3 border-0 rounded-3 my-2 position-relative"
                    onClick={() => triggerFileInput(capInputRef)}
                  >
                    Choose Files
                    <img
                      className="ms-3"
                      src={chooseFileImg}
                      alt="Choose files"
                    />
                  </Button>
                  <Swiper
                    slidesPerView={5}
                    spaceBetween={0}
                    className="py-2 bg-light rounded-4 align-content-center"
                  >
                    {capImageURLs.map((url, index) => (
                      <SwiperSlide key={index} className="image-container mx-2">
                        <Image
                          src={url}
                          thumbnail
                          className="w-100 add-certificate-slider-item certificate-img object-fit-contain"
                          alt={`CAP-${index}`}
                        />
                        <div
                          onClick={() => removeImage("CAP", index)}
                          className="image-overlay cursor-pointer"
                        >
                          <img src={closeBtnThin} className="w-50" />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <div className="mb-3 border border-secondary py-2 px-2 rounded-4">
                  <h5 className="fw-bold ps-3 pt-3">
                    Other Certificate Images
                  </h5>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) =>
                      handleCertificateImageUpload(e, "OtherCertificateImages")
                    }
                    ref={otherCertificateInputRef}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="warning"
                    className="py-0 pe-0 ms-3 border-0 rounded-3 my-2 position-relative"
                    onClick={() => triggerFileInput(otherCertificateInputRef)}
                  >
                    Choose Files
                    <img
                      className="ms-3"
                      src={chooseFileImg}
                      alt="Choose files"
                    />
                  </Button>
                  <Row>
                    <Swiper
                      slidesPerView={5}
                      spaceBetween={0}
                      className="py-2 bg-light rounded-4 align-content-center"
                    >
                      {otherCertificateImageURLs.map((url, index) => (
                        <SwiperSlide key={index} className="image-container mx-2">
                          <Image
                            src={url}
                            thumbnail
                            className="w-100 add-certificate-slider-item certificate-img object-fit-contain"
                            alt={`OtherCertificate-${index}`}
                          />
                          <div
                            onClick={() =>
                              removeImage("OtherCertificateImages", index)
                            }
                            className="image-overlay cursor-pointer"
                          >
                            <img src={closeBtnThin} className="w-50" />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Row>
                </div>

                <div className="mb-3 border border-secondary py-2 px-4 rounded-4">
                  <h5 className="fw-bold ps-3 pt-3">Other Certificate Links</h5>
                  {certificateLinks.map((link, index) => (
                    <Row key={index} className="my-3">
                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          placeholder="Link Name"
                          className="my-2"
                          value={link.name}
                          onChange={(event) =>
                            handleCertificateLinkChange(
                              index,
                              "name",
                              event.target.value
                            )
                          }
                        />
                      </Col>
                      <Col sm={5}>
                        <Form.Control
                          type="text"
                          placeholder="Link Address"
                          className="my-2"
                          value={link.address}
                          onChange={(event) =>
                            handleCertificateLinkChange(
                              index,
                              "address",
                              event.target.value
                            )
                          }
                        />
                      </Col>
                      <Col sm={2}>
                        <Button
                          className="text-success bg-light border-success mx-1"
                          onClick={handleAddCertificateLink}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>

                        {index != 0 && (
                          <Button
                            className="text-danger bg-light border-danger mx-1"
                            onClick={() => handleDeleteCertificateLink(index)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ))}
                </div>
                {registerError && (
                  <Alert variant="danger" className="error">
                    {registerError}
                  </Alert>
                )}
                {error && (
                  <Alert variant="danger" className="error">
                    {error}
                  </Alert>
                )}
                <div className="mx-auto w-100 h5 d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="btn-lg border-0 bg-F4CE14 shadow  text-dark fw-bold mb-0"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};

export default AddCompany;
