import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaginationComponent from "../pagination/pagination";
import { Alert, Col, Row } from "react-bootstrap";
import makeApiCall from "../../apiCall";
import CompanyLowDets from "../user/Companies&Products/companyLowDets";
import companyBasicImg from "../../images/companyBasic.png";
const CategoryCompanies = () => {
  const { category } = useParams() || null;
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState(
    JSON.parse(localStorage.getItem("categories"))
  );
  const [countries, setCountries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );
  const [noResult, setNoResult] = useState(false);
  const [companiesEmpty, setCompaniesEmpty] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "GetCompaniesByCategory",
          hasId: category,
          paginated: true,
          pageNumber: pageNumber,
          pageSize: 20,
          loading: true,
        });
        setCompanies(response.data.companies);
        setTotalPages(response.data.totalPages);

        if (response.data.companies == 0) setNoResult(true);
        else setNoResult(false);
      } catch (error) {
        if (
          error.response.data &&
          error.response.data === "No Companies Found For Category."
        )
          setCompaniesEmpty(true);
        else setNoResult(true);
        console.error("Error fetching companies: ", error);
      }
    };

    fetchCompanies();
  }, [pageNumber]);

  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const convertBase64ToImage = (base64String) => {
    return `data:image/png;base64,${base64String}`;
  };

  const handleCompanyClick = (companyId, companyName) => {
    navigate("/user/products", { state: { companyId, companyName } });
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <React.Fragment>
      <div className="col-12  mx-auto">
        {!companiesEmpty ? (
          <div className=" mt-5 text-center h3">{category} Companies</div>
        ) : (
          <Alert
            variant="info"
            className="w-100 text-center h4 mb-0  border  my-5 py-4"
          >
            {category} contains no companies
          </Alert>
        )}
        <Row className="col-md-11 col-11 mx-auto  d-md-flex justify-content-center mt-4">
          {companies &&
            companies.map((company) => (
              <CompanyLowDets
                key={company.id}
                image={
                  company.image !== null
                    ? convertBase64ToImage(company.image)
                    : companyBasicImg
                }
                title={company.name}
                CountryCode={company.countryCode}
                category={company.category}
                onClick={() => handleCompanyClick(company.id, company.name)}
              />
            ))}
        </Row>
      </div>
      {companies.length > 0 && (
        <div className="col-md-12 mx-auto d-flex justify-content-center my-5">
          <PaginationComponent
            PageNumber={pageNumber}
            TotalPages={totalPages}
            handlePaginationClick={handlePaginationClick}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default CategoryCompanies;
