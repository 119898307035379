import { faInstagram, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
const Footer = () => {
  const jwt = localStorage.getItem("token");
  return (
    <React.Fragment>
      <div className="footer text-light">
        <div className="d-flex  justify-content-center   flex-column flex-md-row">
               <div className="d-flex align-items-center flex-column col-md-4 col-10 mx-auto footer-column ">
            <h4 className=" text-warning">Quick link</h4>
            <Button href="/categories" className=" bg-transparent border-0">
              Browse Categories
            </Button>
            <Button href="/companies" className=" bg-transparent border-0">
              Search Businesses
            </Button>
            <Button href="/countries" className=" bg-transparent border-0">
              Browse Countries
            </Button>
          </div>
               <div className="d-flex align-items-center flex-column col-md-4 col-10 mx-auto footer-column ">
            <h4 className=" text-warning">My Trade Assist</h4>
            <Button href="/about" className=" bg-transparent border-0">
              about us
            </Button>
            <Button
              href={jwt ? "/profile" : "/login"}
              className=" bg-transparent border-0"
            >
              {jwt ? "User Profile" : "Login to your account"}
            </Button> 
            <div className="py-1">privacy policy</div>
          </div>
          <div className="d-flex align-items-center flex-column col-md-4 col-10 mx-auto footer-column ">
            <h4 className=" text-warning">Social</h4>
            <div className=" py-2 ">
              <FontAwesomeIcon icon={faTelegram} size="2xl" className="mx-1" />
              <FontAwesomeIcon icon={faInstagram} size="2xl" className="mx-1" />
              mytradeassist_official
            </div>
            <div className=" py-2 ">
              <EmailOutlinedIcon fontSize="large" className="text-white mx-1" />
              info@mytradeassist.com
            </div>
          </div>
        </div>
        <div className="text-center font-size-14 p-1">
          &copy; 2024 MyTradeAssist.com. All rights reserved.
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
