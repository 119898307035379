import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Col, Row, Image, Alert, Spinner } from "react-bootstrap";
import makeApiCall from "../../../apiCall";

const AddProduct = ({ onSubmitSuccess, companyName, companyId }) => {
  const [images, setImages] = useState(Array(9).fill(null));
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [companySelected, setCompanySelected] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [makeSpecNull, setMakeSpecNull] = useState(false);
  const [addError, setAddError] = useState("");
  const [specifications, setSpecifications] = useState([
    { spec: "", value: "" },
  ]);
  const [ProductPurpose, setProductPurpose] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [productType, setProductType] = useState("");
  const [productTypes, setProductTypes] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchCompanies();
  }, []);


  const fetchCompanies = async () => {
    try {
      const response = await makeApiCall({
        restapi: "get",
        controller: "CustomerCompany",
        apiName: "GetUserCompanies",
      });

      setCompanyData(response.data);

      setCompanyNames(response.data.map((company) => company.name));
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };
  const handleRoleChange = (event) => {
    const { value } = event.target;
    setProductPurpose(value);
    console.log(value);
  };

  let timeoutId = null;

  useEffect(() =>{
  const handleProductTypeChange = async () => {
    const selectedValue = event.target.value;
    setProductType(selectedValue);

    try {
      const response = await makeApiCall({
        restapi: "get",
        controller: "CustomerProduct",
        apiName: "GetProductTypes",
        hasId: companyId,
      });
console.log(response.data);
      setProductTypes(response.data);
    } catch (error) {
      console.error("Error fetching product type suggestions:", error);
    }
  };
  handleProductTypeChange();
},[])

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages[index] = null;
    setImages(updatedImages);
    const updatedUploadedImages = [...uploadedImages];
    updatedUploadedImages.splice(index, 1);
    setUploadedImages(updatedUploadedImages);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const imageIndex = images.findIndex((image) => image === null);

    if (imageIndex !== -1) {
      const updatedImages = [...images];
      updatedImages[imageIndex] = file;

      const reader = new FileReader();
      reader.onload = () => {
        const dataURL = reader.result;
        setImages(updatedImages);
        setSelectedImageIndex(imageIndex);
        setUploadedImages([...uploadedImages, dataURL]);
      };

      reader.readAsDataURL(file);
    }
  };

  // const handleSelectCompany = (event) => {
  //   const selectedCompanyName = event.target.value;
  //   const selectedCompany = companyData.find(
  //     (company) => company.name === selectedCompanyName
  //   );
  //   if (selectedCompany) {
  //     const companyId = selectedCompany.id;
  //     setSelectedCompanyId(companyId);

  //     setCompanySelected(true);
  //   }
  // };

  const handleSpecificationChange = (index, field, value) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index][field] = value;
    setSpecifications(updatedSpecifications);
  };

  const handleAddSpecification = () => {
    setSpecifications([...specifications, { spec: "", value: "" }]);
  };

  const handleDeleteSpecification = (index) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications.splice(index, 1);
    setSpecifications(updatedSpecifications);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (uploadedImages.length === 0) {
      setAddError("Add at least one photo");
      return;
    }

    setLoading(true);

    const formData = new FormData(event.target);
    const imageFiles = [];

    images.forEach((image, index) => {
      if (image !== null) {
        const fileName = `image${index + 1}.png`;
        formData.append("Images", image, fileName);
        formData.append("companyName", companyName);
        imageFiles.push(`-F 'Images=@${fileName};type=image/png'`);
      }
    });

    const specificationsObj = {};

    specifications.forEach((specification) => {
      if (!(specification.spec === "" && specification.value === "")) {
        specificationsObj[specification.spec] = specification.value;
      } else {
        setMakeSpecNull(true);
      }
    });
    const specificationsJSON = JSON.stringify(specificationsObj);
    if (!makeSpecNull) formData.append("specifications", specificationsJSON);
    else formData.append("specifications", null);
    formData.append("ProductPurpose", ProductPurpose);
    if (!companyId) {
      const companyName = formData.get("CompanyName");
      const companyId = companyData.find(
        (company) => company.name === companyName
      )?.id;
      formData.set("CompanyId", companyId);
    }

    if (companyId) {
      formData.set("CompanyId", companyId);
    }

    try {
      const response = await makeApiCall({
        restapi: "post",
        controller: "CustomerProduct",
        apiName: "AddProduct",
        data: formData,
      });

      console.log("Product added successfully:", response.data);
      onSubmitSuccess();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAddError(error.response.data);
      console.error("Error adding product:", error);
    }
  };

  return (
    <div className="shadow  rounded  bg-light ">
      <Form className="mt-3" onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} md={6}>
            <div className="col-12">
              <Row className=" mx-0">
                <Col className=" text-center " sm={12} md={12}>
                  <Form.Label className="mx-2 h5">I want to </Form.Label>
                  <Form.Check
                    type="radio"
                    name="ProductPurpose"
                    value="Sell"
                    label="Sell"
                    inline
                    required
                    className="custom-radio"
                    onChange={handleRoleChange}
                  />
                  <Form.Check
                    type="radio"
                    inline
                    required
                    name="ProductPurpose"
                    value="Buy"
                    label="Buy"
                    className="custom-radio"
                    onChange={handleRoleChange}
                  />
                  <Form.Label className=" h5">this product</Form.Label>
                </Col>
                <Col sm={12} md={12}>
                  <Form.Label className="my-2">Product</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Product's Name"
                    className="form-control"
                    name="Name"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <Form.Label className="my-2">Unit</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    className="form-control my-2"
                    name="ProductUnitType"
                  >
                    <option value="">Select Unit</option>

                    <option value="Acre">Acre</option>
                    <option value="Ampere">Ampere</option>
                    <option value="Bag">Bag</option>
                    <option value="Barrel">Barrel</option>
                    <option value="Box">Box</option>
                    <option value="Bushel">Bushel</option>
                    <option value="Carton">Carton</option>
                    <option value="Case">Case</option>
                    <option value="Centimeter">Centimeter</option>
                    <option value="Chain">Chain</option>
                    <option value="CubicCentimeter">Cubic Centimeter</option>
                    <option value="CubicFoot">Cubic Foot</option>
                    <option value="CubicInch">Cubic Inch</option>
                    <option value="CubicMeter">Cubic Meter</option>
                    <option value="CubicYard">Cubic Yard</option>
                    <option value="DegreesCelsius">Degrees Celsius</option>
                    <option value="DegreesFahrenheit">
                      Degrees Fahrenheit
                    </option>
                    <option value="Dozen">Dozen</option>
                    <option value="Dram">Dram</option>
                    <option value="FluidOunce">Fluid Ounce</option>
                    <option value="Foot">Foot</option>
                    <option value="FortyFootContainer">
                      Forty-Foot Container
                    </option>
                    <option value="Furlong">Furlong</option>
                    <option value="Gallon">Gallon</option>
                    <option value="Gill">Gill</option>
                    <option value="Grain">Grain</option>
                    <option value="Gram">Gram</option>
                    <option value="Gross">Gross</option>
                    <option value="Hectare">Hectare</option>
                    <option value="Hertz">Hertz</option>
                    <option value="Inch">Inch</option>
                    <option value="Item">Item</option>
                    <option value="Kiloampere">Kiloampere</option>
                    <option value="Kilogram">Kilogram</option>
                    <option value="Kilohertz">Kilohertz</option>
                    <option value="Kilometer">Kilometer</option>
                    <option value="Kiloohm">Kiloohm</option>
                    <option value="Kilovolt">Kilovolt</option>
                    <option value="Kilowatt">Kilowatt</option>
                    <option value="Liter">Liter</option>
                    <option value="Long Ton">Long Ton</option>
                    <option value="Megahertz">Megahertz</option>
                    <option value="Meter">Meter</option>
                    <option value="Metric Ton">Metric Ton</option>
                    <option value="Mile">Mile</option>
                    <option value="Milliampere">Milliampere</option>
                    <option value="Milligram">Milligram</option>
                    <option value="Millihertz">Millihertz</option>
                    <option value="Milliliter">Milliliter</option>
                    <option value="Millimeter">Millimeter</option>
                    <option value="Milliohm">Milliohm</option>
                    <option value="Millivolt">Millivolt</option>
                    <option value="Milliwatt">Milliwatt</option>
                    <option value="NauticalMile">Nautical Mile</option>
                    <option value="Ohm">Ohm</option>
                    <option value="Ounce">Ounce</option>
                    <option value="Pack">Pack</option>
                    <option value="Pallet">Pallet</option>
                    <option value="Pair">Pair</option>
                    <option value="Parcel">Parcel</option>
                    <option value="Perch">Perch</option>
                    <option value="Piece">Piece</option>
                    <option value="Pint">Pint</option>
                    <option value="Plant">Plant</option>
                    <option value="Pole">Pole</option>
                    <option value="Pound">Pound</option>
                    <option value="Quart">Quart</option>
                    <option value="Quarter">Quarter</option>
                    <option value="Rod">Rod</option>
                    <option value="Roll">Roll</option>
                    <option value="Set">Set</option>
                    <option value="Sheet">Sheet</option>
                    <option value="ShortTon">Short Ton</option>
                    <option value="SquareCentimeter">Square Centimeter</option>
                    <option value="SquareFoot">Square Foot</option>
                    <option value="SquareInch">Square Inch</option>
                    <option value="SquareMeter">Square Meter</option>
                    <option value="SquareMile">Square Mile</option>
                    <option value="SquareYard">Square Yard</option>
                    <option value="Stone">Stone</option>
                    <option value="Strand">Strand</option>
                    <option value="Ton">Ton</option>
                    <option value="Tonne">Tonne</option>
                    <option value="Tray">Tray</option>
                    <option value="TwentyFootContainer">
                      Twenty-Foot Container
                    </option>
                    <option value="Unit">Unit</option>
                    <option value="Volt">Volt</option>
                    <option value="Watt">Watt</option>
                    <option value="Wp">Wp</option>
                    <option value="Yard">Yard</option>
                  </Form.Control>
                </Col>
              </Row>
              {/* <Row>
                <Col sm={12} md={12}>
                  <Form.Label className="my-2">Select Company</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="CompanyName"
                    className="form-control my-2"
                    onChange={handleSelectCompany}
                  >
                    <option value="">Select Company</option>
                    {companyNames.map((companyName, index) => (
                      <option key={index} value={companyName}>
                        {companyName}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row> */}
              <Row>
                <Col sm={12} md={12}>
                  <Form.Label className="my-2">Product Type</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    // 
                    
                    className="form-control my-2"
                    value={productType}
                    onChange={(event) => setProductType(event.target.value)}
                    name="ProductType"
                  >
                    <option className="" value="">
                      select Product type
                    </option>
                    {productTypes.map((productType) => (
                      <option
                        className=""
                        key={productType.id}
                        value={productType.name}
                        variant="secondary"
                      >
                        {productType.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="bg-white mt-3 ">
              <Button
                variant="light"
                className=" text-center border border-secondary  form-control "
                onClick={handleImageUpload}
              >
                Add an Image
              </Button>

              <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              {uploadedImages.length > 0 && (
                <div className="col-12 add-prduct-images-section mt-1">
                  <table className="table text-center table-hover">
                    <thead>
                      <tr>
                        <th className="w-50 text-center">Image</th>
                        <th className="w-25 text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedImages.map((image, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            {image && (
                              <Image
                                thumbnail
                                src={image}
                                alt={`Product ${index + 1}`}
                                className="product-image col-6"
                              />
                            )}
                          </td>
                          <td className="text-center">
                            {image && (
                              <button
                                type="button"
                                className="btn remove-icon mt-5"
                                onClick={() => handleImageDelete(index)}
                              >
                                <div className="btn-lg text-danger ">
                                  &#10006;
                                </div>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <div className=" col-12">
              <Form.Label className="my-2">Specifications</Form.Label>
              {specifications.map((specification, index) => (
                <Row key={index} className=" my-3">
                  <Col sm={5}>
                    <Form.Control
                      type="text"
                      placeholder="e.g. color"
                      className=" my-2"
                      value={specification.spec}
                      onChange={(event) =>
                        handleSpecificationChange(
                          index,
                          "spec",
                          event.target.value
                        )
                      }
                    />
                  </Col>
                  <Col sm={5}>
                    <Form.Control
                      type="text"
                      className=" my-2"
                      placeholder="e.g. red"
                      value={specification.value}
                      onChange={(event) =>
                        handleSpecificationChange(
                          index,
                          "value",
                          event.target.value
                        )
                      }
                    />
                  </Col>
                  <Col sm={2}>
                    {index === specifications.length - 1 && (
                      <Button
                        className="text-success bg-light border-success "
                        onClick={handleAddSpecification}
                      >
                        +
                      </Button>
                    )}
                    {index !== specifications.length - 1 && (
                      <Button
                        className="text-danger bg-light border-danger "
                        onClick={() => handleDeleteSpecification(index)}
                      >
                        &#215;
                      </Button>
                    )}
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
        <div className=" text-center mx-auto my-3 col-md-2 col-5">
          <Button type="submit" className="btn-warning form-control ">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner
                  animation="border"
                  className="spinner-small"
                  role="status"
                />
              </div>
            ) : (
              " Add Product"
            )}
          </Button>
        </div>
        {addError && (
          <Alert variant="danger" className="text-center">
            {addError}
          </Alert>
        )}
      </Form>
    </div>
  );
};

export default AddProduct;
