import React, { useState } from "react";
import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";
import makeApiCall from "./../../apiCall";

const GetEmail = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);

  const handleSendEmail = async () => {
    try {
      setLoading(true);
      const response = await makeApiCall({
        restapi: "post",
        controller: "Authentication",
        apiName: "GetEmailForForgetPassword",
        data: { email: email },
      });

      setLoading(false);

      if (response.status === 200) {
        setEmailSent(true);
        setUserNotFound(false);
      } else {
        setEmailSent(false);
        setUserNotFound(true);
      }
    } catch (error) {
      setLoading(false);
      setEmailSent(false);
      console.log(error);
      if (error.response.data === "User not found.") {
        setUserNotFound(true);
      }
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Container className=" col-md-5 col-12 mx-auto bg-light border-warning border border-2 bg-F5F7F8  shadow rounded-5 my-5">
        <Form.Group controlId="formEmail">
          <Form.Label className="my-4 ms-1 h5">
            Enter your email address
          </Form.Label>
          <Form.Control
            className="mb-4"
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
        </Form.Group>
        <div className="d-flex justify-content-center mx-auto">
          <Button
            disabled={loading || emailSent}
            className="btn-warning mb-4 mt-2  btn-lg  col-md-6 col-sm-6"
            onClick={handleSendEmail}
          >
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <span className="me-2">Sending...</span>
                <Spinner
                  animation="border"
                  className="spinner-small ms-1"
                  role="status"
                />
              </div>
            ) : emailSent ? (
              "email sent"
            ) : (
              "Send email"
            )}
          </Button>
        </div>
        {userNotFound && (
          <Alert variant="danger" className="text-center my-3">
            User not found. Please check if you've written your email correctly
          </Alert>
        )}
        {emailSent && (
          <p className="text-center my-3">
            Please check your your emails to reset your Password
          </p>
        )}
      </Container>
    </Container>
  );
};

export default GetEmail;
