import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faXmark } from "@fortawesome/free-solid-svg-icons";
import HelpChatWindow from "./helpChatWindow";

const HelpChat = () => {
  const [openHelpChatWindow, setOpenHelpChatWindow] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const chatRef = useRef(null);

  const handleHelpChatIconClick = () => {
    if (openHelpChatWindow) {
      setAnimationClass("slide-out");
      setTimeout(() => {
        setOpenHelpChatWindow(false);
      }, 500);
    } else {
      setOpenHelpChatWindow(true);
      setAnimationClass("slide-in");
    }
  };

  const handleClickOutside = (event) => {
    if (
      chatRef.current &&
      !chatRef.current.contains(event.target) &&
      !event.target.closest(".help-chat-button")
    ) {
      setAnimationClass("slide-out");
      setTimeout(() => {
        setOpenHelpChatWindow(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (openHelpChatWindow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openHelpChatWindow]);

  useEffect(() => {
    if (!openHelpChatWindow) {
      setAnimationClass("");
    }
  }, [openHelpChatWindow]);

  return (
    <React.Fragment>
      {openHelpChatWindow && (
        <div ref={chatRef}>
          <HelpChatWindow animationClass={animationClass} />
        </div>
      )}
      <div
        onClick={handleHelpChatIconClick}
        className="help-chat-button zindex-1 cursor-pointer d-flex justify-content-center align-items-center"
      >
        <FontAwesomeIcon
          icon={openHelpChatWindow ? faXmark : faComments}
          size="xl"
          color="rgb(92, 92, 92)"
        />
      </div>
    </React.Fragment>
  );
};

export default HelpChat;
