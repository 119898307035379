import React, { useState } from "react";
import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";
import makeApiCall from "./../../apiCall";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

const ResetPassword = () => {
  const [pageValid, setPageValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { randomkey } = useParams();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    console.log(randomkey);
  }, []);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        setErrors({ passwordMatchError: true });
        return;
      }
      setLoading(true);
      const response = await makeApiCall({
        restapi: "put",
        controller: "Authentication",
        apiName: "UpdatePassword",
        data: { newPassword: password, randomkey },
      });
      console.log(response);

      setPageValid(false);
      setLoading(false);
      if (response.status == 200) navigate("/login");
    } catch (error) {
      if (error.response.data == "Invalid or expired key.") setPageValid(true);
      else setPageValid(false);
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <div className="col-md-6 col-12 bg-light border border-warning rounded my-5">
        <Form.Group>
          <Form.Label className="my-4 ms-4 h5">
            Enter your new Password
          </Form.Label>

          <div className="position-relative px-3">
            <Form.Control
              className=""
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              size="lg"
              onClick={handlePasswordToggle}
              className="position-absolute me-4  end-0 top-50 translate-middle-y"
              style={{ cursor: "pointer" }}
            />
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label className="my-3 ms-4 h5">Confirm new Password</Form.Label>
          <div className="position-relative px-3">
            <Form.Control
              className=""
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmpassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={showConfirmPassword ? faEyeSlash : faEye}
              size="lg"
              onClick={handleConfirmPasswordToggle}
              className="position-absolute me-4  end-0 top-50 translate-middle-y"
              style={{ cursor: "pointer" }}
            />
          </div>
        </Form.Group>
        {errors.passwordMatchError && (
          <Alert variant="danger" className="mt-4 col-11 mx-auto">
            Passwords do not match.
          </Alert>
        )}
        <div className="d-flex justify-content-center mx-auto my-2">
          <Button
            disabled={loading ? true : false}
            className="btn-warning my-3   btn-lg  col-md-6 col-sm-5"
            onClick={handleResetPassword}
          >
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <span className="me-2">wait a second</span>
                <Spinner
                  animation="border"
                  className="spinner-small ms-1"
                  role="status"
                />
              </div>
            ) : (
              "Reset password"
            )}
          </Button>
        </div>{" "}
        {pageValid && (
          <Alert variant="danger" className="text-center">
            this page is either Invalid or expired
          </Alert>
        )}
      </div>
    </Container>
  );
};

export default ResetPassword;
