import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Alert, Row, Image, Container, Button } from "react-bootstrap";
import { FlagIcon } from "react-flag-kit";
import makeApiCall from "../../../apiCall";
import offerImg from "../../../images/offer.png";
import rolicon from "../../../images/carbon_user-role.png";
import Validation from "../../../images/fluent-mdl2_date-time.png";
import MOQ from "../../../images/simple-icons_moq.png";
import editicon from "../../../images/editImg.png";
import timeImg from "../../../icons/time.svg";
import EditOffer from "./editoffer";
const OfferPage = () => {
  const [offerDet, setOfferDet] = useState([]);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [reRender, setReRender] = useState(false);
  const { offerId } = useParams();
  const [conversations, setConversations] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { offerId } = location.state;
        const response = await makeApiCall({
          restapi: "get",
          controller: "IntelligentOffer",
          apiName: "GetIntelligentOfferDetail",
          hasId: offerId,
          loading: true,
        });

        const data = response.data;
        setOfferDet(data);

        const columns =
          data.length > 0
            ? Object.keys(data[0]).filter((column) => column !== "id")
            : [];

        setData(columns);
      } catch (error) {
        console.error("Error fetching Detail:", error);
        setAlert(true);
      }
    };

    const fetchConversations = async () => {
      const { offerId } = location.state;
      const response = await makeApiCall({
        restapi: "get",
        controller: "MessageCenter",
        apiName: "GetOfferConversatons",
        hasId: offerId,
        loading: true,
      });

      setConversations(response.data);
    };

    fetchConversations();
    fetchData();
  }, [location.state,reRender]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleShowProductDetails = (productName, productId) => {
    navigate(`/user/products/${productName}`, { state: { productId } });
  };

  const handleclick = (conversationId) => {
    navigate(`/messageCenter/conversation/${conversationId}`, {
      state: { status: "Accepted" },
    });
  };
const handleEditMode =(canEdit,updated)=>{
  setEditMode(canEdit);
  if (updated)
setReRender(!reRender)
}

const timeRemaining = (expirationDate) => {
  const now = new Date();
  const expiration = new Date(expirationDate);
  const difference = expiration - now;

  if (difference <= 0) {
    return 'Expired';
  }

  const millisecondsPerMinute = 60 * 1000;
  const millisecondsPerHour = 60 * millisecondsPerMinute;
  const millisecondsPerDay = 24 * millisecondsPerHour;
  const millisecondsPerMonth = 30 * millisecondsPerDay;

  const months = Math.floor(difference / millisecondsPerMonth);
  const days = Math.floor((difference % millisecondsPerMonth) / millisecondsPerDay);
  const hours = Math.floor((difference % millisecondsPerDay) / millisecondsPerHour);
  const minutes = Math.floor((difference % millisecondsPerHour) / millisecondsPerMinute);

  if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''}`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return 'Less than a minute';
  }
};



  const renderOfferProperties = (offer, type) => {
    const properties = offer[type];
    if (!properties) return null;

    return (
      <div className="card OfferProperties">
        <div className="card-body text-center">
          <h5 className="card-title">{`${type.toUpperCase()} : ${
            properties.Destination || "-"
          }`}</h5>
          <p className="card-text">
            {offer.intelligentOfferRequestType === "Seller" &&
              `Min: ${properties.Min || "-"}`}
            {offer.intelligentOfferRequestType === "Buyer" &&
              `Max: ${properties.Max || "-"}`}
          </p>
        </div>
      </div>
    );
  };

  const ConversationsTable = ({ conversations, handleclick, isMobile }) => {
    return (
      <table className="table-hover col-md-10 col-11 mb-3 mx-md-auto mx-auto">
        <tbody className="conversations">
          {conversations && conversations.length !== 0 ? (
            conversations.map((conversation) => (
              <tr
                key={conversation.id}
                className="d-flex justify-content-start px-0 py-1 my-2 fw-bold bg-light text-center align-items-center related-conversations"
              >
                {isMobile ? (
                  <>
                    <td className="col-12 text-start">
                      <span className="fw-bold ps-4">
                        {conversation.subject}
                      </span>
                    </td>

                    <td className="col-12 text-start">
                      <span className="ps-4">To: {conversation.receiver}</span>
                      <FlagIcon
                        code={conversation.receiverCountry}
                        className="flag-icon mx-1 my-auto"
                        size={25}
                      />
                    </td>
                    <td className="col-12 text-start ps-4">
                      {new Date(conversation.createdAt).toLocaleString(
                        "en-US",
                        {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td className="col-12 text-center mt-2 px-5">
                      <Button
                        onClick={() => handleclick(conversation.id)}
                        className={`border-top ${
                          conversation.isRead
                            ? " conversation-isread "
                            : " fw-bold"
                        } text-center cursor-pointer bg-F4CE14 text-dark border-0 rounded-4 py-2`}
                      >
                        Go to conversation
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="col-3 text-start">
                      <span className="ms-4">To: {conversation.receiver}</span>
                      <FlagIcon
                        code={conversation.receiverCountry}
                        className="flag-icon mx-1 my-auto"
                        size={25}
                      />
                    </td>
                    <td className="col-3 px-1 text-truncate text-start">
                      {conversation.subject}
                    </td>
                    <td className="col-3 px-1">
                      {new Date(conversation.createdAt).toLocaleString(
                        "en-US",
                        {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td className="col-3 px-1 py-1 col-md">
                      <Button
                        onClick={() => handleclick(conversation.id)}
                        className={`border-top ${
                          conversation.isRead
                            ? " conversation-isread "
                            : " fw-bold"
                        } text-center cursor-pointer bg-F4CE14 text-dark border-0 rounded-4 py-2`}
                      >
                        Go to conversation
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td>
                <Container className="text-center h4 my-4 fw-bold">
                  This offer doesn't contain any conversations
                </Container>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <React.Fragment>
      {!editMode ? (
        <React.Fragment>
          {offerDet.length > 0 ? (
            offerDet.map((offer) => {
              const offerProperties = ["fob", "fas", "exw", "cpt", "cip", "fca"]
                .map((type) => renderOfferProperties(offer, type))
                .filter(Boolean);

              return (
                <React.Fragment key={offer.id}>
                  <Row className="d-md-flex justify-content-center my-5 abcd">
                    <div className="d-flex gap-5 mt-4 flex-wrap">
                      <div className="justify-content-center">
                        <Image
                         onClick={(e) => {
                          e.stopPropagation();
                          handleShowProductDetails(
                            offer.productName,
                            offer.productId
                          );
                        }}
                          className="col-md-6 col-12 rounded-3 offerdetails-image cursor-pointer object-fit-contain"
                          src={
                            offer.productImage
                              ? `data:image/png;base64,${offer.productImage}`
                              : offerImg
                          }
                        />
                      </div>

                      <div className="text-lg-start text-sm-center offer-details-container">
                        <div>
                          <div className="offer-title text-left">
                            {offer.title.split(" ").slice(0, 7).join(" ")}
                            <br />
                            {offer.title.split(" ").slice(7).join(" ")}
                          </div>

                          <div className="d-flex flex-column gap-4 ms-1">
                            <div className="mt-3">
                              <img className="" src={rolicon} alt="" />
                              <span className="ms-2">
                                {offer.intelligentOfferRequestType}
                              </span>
                            </div>

                            <div>
                              <img src={timeImg} alt="" />
                              <span className="ms-2">  Expires in: {timeRemaining(offer.expireDate)}</span>
                            </div>

                            <div>
                              <img src={Validation} alt="" />
                              <span className="ms-2">
                                {offer.intelligentOfferValidationDate}
                              </span>
                            </div>

                            <div>
                              <img src={MOQ} alt="" />
                              <span className="ms-2">{offer.moq}</span>
                            </div>
     
                          </div>
                         

                          <div>
                            <p
                              onClick={(e) => {
                                e.stopPropagation();
                                handleShowProductDetails(
                                  offer.productName,
                                  offer.productId
                                );
                              }}
                              className="mt-5"
                            >
                              <u className="offerdetails-show-product">
                                {offer.productName}
                              </u>
                            </p>
                          </div>

                          <div>
                            <span className="companyname">
                              {offer.companyName}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="text-end edit-text flex-grow-1">
                        <div className="">
                          <img src={editicon} className="me-2" onClick={()=>setEditMode(true)} role="button"/>
                          <span onClick={()=>setEditMode(true)} role="button">Edit</span>
                        </div>

                        {offerProperties.length > 0 && (
                          <div className="OfferProperties-scroll grey-scroll">
                            {offerProperties}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="w-100 col-md-5 text-center rounded-5 bg-dark"></div>

                    <Container className="my-3 col-md-9 col-11 description mt-5">
                      <span className="h5 fw-bold"></span>
                      {offer.description}
                    </Container>
                  </Row>

                  <Container className="mb-4 fs-3 col-md col-11 related-conversations-text">
                    Related Conversations
                  </Container>

                  <ConversationsTable
                    conversations={conversations}
                    handleclick={handleclick}
                    isMobile={isMobile}
                  />
                </React.Fragment>
              );
            })
          ) : (
            <Alert variant="danger">Details not available</Alert>
          )}
        </React.Fragment>
      ) : (
        <EditOffer data={offerDet} handleEditMode={handleEditMode}/>
      )}
    </React.Fragment>
  );
};
export default OfferPage;
