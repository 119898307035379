import React, { useState, useEffect } from "react";
import { Alert, Row } from "react-bootstrap";
import makeApiCall from "./../../../apiCall";
import PaginationComponent from "../../pagination/pagination";
import { useNavigate, useParams } from "react-router";
import CompanyLowDets from "./companyLowDets";
import companyBasicImg from "../../../images/companyBasic.png";
import Filter from "../../filter/filter";

const AllCompanies = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [alltotalPages, setAllTotalPages] = useState(1);
  const [tempPageNumber, setTempPageNumber] = useState(0);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tempCtgryFilters, setTempCtgryFilters] = useState([]);
  const [tempCntryFilters, setTempCntryFilters] = useState([]);
  const [categories, setCategories] = useState(
    JSON.parse(localStorage.getItem("categories"))
  );
  const [countries, setCountries] = useState(
    JSON.parse(localStorage.getItem("countries"))
  );
  const [noResult, setNoResult] = useState(false);
  const [companiesEmpty, setCompaniesEmpty] = useState(false);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [countryFilters, setCountryFilters] = useState([]);
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    titles: [],
    data: {
      category: [],
      country: [],
    },
    checkedData: {
      category: [],
      country: [],
    },
  });

  useEffect(() => {
    if (filterData.checkedData) {
      const { category = [], country = [] } = filterData.checkedData;

      setCategoryFilters(category);
      setCountryFilters(country);
    }
  }, [filterData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (categoryFilters.length == 0 && tempPageNumber != pageNumber) {
          setTempPageNumber(pageNumber);
          const response = await makeApiCall({
            restapi: "get",
            controller: "CustomerCompany",
            apiName: "GetAllCompanies",
            paginated: true,
            pageNumber: pageNumber,
            pageSize: 20,
            loading: true,
          });

          setAllCompanies(response.data.companies);
          setAllTotalPages(response.data.totalPages);
          setCompanies(response.data.companies);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [pageNumber]);

  useEffect(() => {
    const fetchCompanies = async () => {
      if (tempCtgryFilters != categoryFilters) {
        setTempCtgryFilters(categoryFilters);
        setPageNumber(1);
      }
      if (tempCntryFilters != countryFilters) {
        setTempCntryFilters(countryFilters);
        setPageNumber(1);
      }
      try {
        if (categoryFilters.length > 0 || countryFilters.length > 0) {
          let more = "";

          if (categoryFilters.length > 0) {
            more += categoryFilters
              .map((cat) => `&categoryNames=${encodeURIComponent(cat)}`)
              .join("");
          }

          if (countryFilters.length > 0) {
            more += countryFilters
              .map((cnt) => `&countryNames=${encodeURIComponent(cnt)}`)
              .join("");
          }
          const response = await makeApiCall({
            restapi: "get",
            controller: "CustomerCompany",
            apiName: "FilterCompany",
            paginated: true,
            pageNumber: pageNumber,
            pageSize: 20,
            more: more,
          });
          setCompanies(response.data.companies);
          setTotalPages(response.data.totalPages);
          if (response.data.companies == 0) setNoResult(true);
          else setNoResult(false);
        } else if (categoryFilters.length == 0 && countryFilters.length == 0) {
          setCompanies(allCompanies);

          setTotalPages(alltotalPages);
        }
      } catch (error) {
        if (
          error.response.data &&
          error.response.data === "No Companies Found For Category."
        )
          setCompaniesEmpty(true);
        else setNoResult(true);
        console.error("Error fetching companies: ", error);
      }
    };

    fetchCompanies();
  }, [pageNumber, categoryFilters, countryFilters]);

  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const convertBase64ToImage = (base64String) => {
    return `data:image/png;base64,${base64String}`;
  };

  const handleCompanyClick = (companyId, companyName) => {
    navigate("/user/products", { state: { companyId, companyName } });
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  useEffect(() => {
    setFilterData((prevData) => ({
      ...prevData,
      titles: [
        { name: "category", expanded: false },
        { name: "country", expanded: false },
      ],
      data: {
        ...prevData.data,
        category: categories,
        country: countries,
      },
      checkedData: {
        ...prevData.checkedData,
        category: [],
        country: [],
      },
    }));
  }, [countries, categories]);
  return (
    <React.Fragment>
      <Filter filterData={filterData} setFilterData={setFilterData} />
      <div className="col-10 ms-auto me-md-2 me-auto">
        {/* {category && (
          <div className=" mt-5 text-center h3">{category} Companies</div>
        )} */}
        <Row className=" d-md-flex justify-content-center ms-md-5 mt-5 ">
          {companies &&
            companies.map((company) => (
              <CompanyLowDets
                key={company.id}
                image={
                  company.image !== null
                    ? convertBase64ToImage(company.image)
                    : companyBasicImg
                }
                title={company.name}
                CountryCode={company.countryCode}
                category={company.category}
                onClick={() => handleCompanyClick(company.id, company.name)}
              />
            ))}
        </Row>
      </div>
      {companies.length > 0 && (
        <div className="col-md-10 ms-auto d-flex justify-content-center my-5">
          <PaginationComponent
            PageNumber={pageNumber}
            TotalPages={totalPages}
            handlePaginationClick={handlePaginationClick}
          />
        </div>
      )}
      {companiesEmpty && (
        <Alert
          variant="info"
          className="w-100 text-center h4 mb-0  border  my-5 py-4"
        >
          This category contains no companies
        </Alert>
      )}
      {noResult && (
        <div className=" text-center col-md-6 mx-auto mx-auto alert rounded h5 border alert-info">
          No companies found
        </div>
      )}
    </React.Fragment>
  );
};

export default AllCompanies;
