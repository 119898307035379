import React, { useState, useEffect } from "react";
import { Alert, Badge, Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import makeApiCall from "../../apiCall";
const NotificationsComponent = () => {
  const [notifications, setNotifications] = useState([]);
  const [noNotif, setNoNotif] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "MessageCenter",
          apiName: "GetNotifications",
        });

        console.log(response);
        setNotifications(response.data);
        if (response.data.length === 0) setNoNotif(true);
      } catch (error) {
        console.error(error);
        setNoNotif(true);
      }
    };

    fetchData();
  }, []);
  const handleConversationNotifClick = async (conversationId, message) => {
    const response = await makeApiCall({
      restapi: "post",
      controller: "MessageCenter",
      apiName: "MakeNotificatoinIsReadTrue",
      hasId: conversationId,
    });
    let conversationStatus = "";
    if (message == "You have a new message") conversationStatus = "incoming";
    else conversationStatus = "Accepted";
    navigate(`/messagecenter/conversation/${conversationId}`, {
      state: { status: conversationStatus },
    });
  };

  const pageSize = window.innerWidth;
  const textBreakClass =
    pageSize < 410
      ? "col-12 ps-2 h6 mb-0 bg-transparent text-dark d-block d-sm-inline-block  position-relative"
      : "text-nowrap";
  return (
    <React.Fragment>
      <div className="my-5">
        <div className=" border-top border-bottom border-dark my-2 mb-5 col-md-11 text-center h4 fw-bold mb-0 mx-auto py-1">
          Notifications
        </div>
        {notifications.map((notif) => (
          <Row
            key={notif.conversationId}
            onClick={() =>
              handleConversationNotifClick(notif.conversationId, notif.message)
            }
            className="font-45474B border border-secondary rounded-3 my-3 py-4 focus-hover  mb-0   cursor-pointer col-md-9 col-11 mx-auto h5 mb-0 "
          >
            <div className="col-md-8 col-12 ps-5">
              <Col className="col-12 mb-3 mt-1 h5 fw-bold mb-0">System</Col>
              <Col
                className={`col-12 ps-2 h6 mb-0 bg-transparent text-dark d-block d-sm-inline-block  position-relative ${textBreakClass}`}
              >
                {notif.message}
              </Col>
            </div>
            <Col className="col align-self-center text-secondary-emphasis mt-md-0 mt-3 text-end pe-5">
              {new Date(notif.createdAt).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </Col>
          </Row>
        ))}
        {noNotif && (
          <Container className="bg-F4CE14 my-5 py-4 h1 text-center border rounded">
            <Badge className=" bg-transparent text-dark">
              You have no Notifications
            </Badge>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default NotificationsComponent;
