import React, { useEffect, useState } from "react";
import CompanyLowDets from "../user/Companies&Products/companyLowDets";
import makeApiCall from "../../apiCall";
import companyBasic from "../../images/companyBasic.png";
import { useNavigate } from "react-router";
const MostSearched = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "GetLast4Companies",
        });
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleCompanyClick = (companyId, companyName) => {
    navigate("/user/products", { state: { companyId, companyName } });
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <React.Fragment>
      <div className="h3 ps-2 text-left fw-bold col-10 py-2  border-bottom border-1 border-dark justify-content-center Highly-rated-companies-title">
        Highly rated companies
      </div>
      <div className="col-md-10 col-12 d-md-flex flex-wrap  justify-content-center mx-auto my-4">
        {data &&
          data.map((company) => (
            <div className="mx-2 justify-content-center d-flex">
              <CompanyLowDets
                key={company.id}
                image={
                  company.image
                    ? `data:image/png;base64,${company.image}`
                    : companyBasic
                }
                title={company.name}
                CountryCode={company.countryCode}
                category={company.category}
                onClick={() => handleCompanyClick(company.id, company.name)}
              />
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default MostSearched;
