// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family:  'inter';
}

/* 
Body::-webkit-scrollbar {
  width: 15px;
  background-color: black;
}


Body::-webkit-scrollbar-track {
  background: #ffffff;
  
  box-shadow: inset 8px 0 8px -8px rgba(0, 0, 0, 0.3),
              inset -8px 0 8px -8px rgba(0, 0, 0, 0.3);
}


Body::-webkit-scrollbar-thumb {
  background: #F4CE14;
  border-radius: 25px;
max-width: 10px !important;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;;;;;;;;;;;;;;;;;;;GAmBG","sourcesContent":["body {\n  font-family:  'inter';\n}\n\n/* \nBody::-webkit-scrollbar {\n  width: 15px;\n  background-color: black;\n}\n\n\nBody::-webkit-scrollbar-track {\n  background: #ffffff;\n  \n  box-shadow: inset 8px 0 8px -8px rgba(0, 0, 0, 0.3),\n              inset -8px 0 8px -8px rgba(0, 0, 0, 0.3);\n}\n\n\nBody::-webkit-scrollbar-thumb {\n  background: #F4CE14;\n  border-radius: 25px;\nmax-width: 10px !important;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
