import React from "react";
import aboutMyTrade from "../../images/aboutmytrade.svg";
import teamImg from "../../images/team.svg";
import aboutMC from "../../images/aboutMC.svg";
import aboutIO from "../../images/aboutIO.svg";
import aboutST from "../../images/aboutST.svg";
import aboutLineLong from "../../images/aboutLineLong.svg";
import aboutLineShort from "../../images/aboutLineShort.svg";
const About = () => {
  return (
    <React.Fragment>
      <div className="d-md-flex justify-content-center my-5">
        <div className="col-md-7 ">
          <img src={aboutMyTrade} className="mt-4 ms-md-5 col-md-7  col-12" />
          <div className="fs-2 ps-md-2 me-md-0 ms-md-5 ps-3 me-3 mt-3">
            <b>Trade Assist</b> is an international trade site that, relying on
            the B2B business model, tries to connect two businesses to each
            other, and in this regard, it has made efforts to improve, which you
            will get to know later.
          </div>
        </div>
        <div className="col-md-5 d-none d-sm-block text-center">
          <img src={teamImg} className="m-2" />
        </div>
      </div>
      <div className="text-center">
        <img src={aboutLineLong} />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-center col-md-9 col-11 my-4 mx-auto">
        <div className="order-1 order-md-1 col-md-5 text-center">
          <img src={aboutIO} className="col-11" />
        </div>
        <div className="order-2 order-md-2 col-md-7 my-4 border-start border-secondary">
          <div className="fs-4 ps-5">
            <b className="h2 fw-bold">Intelligent offer</b>
            <div className="mt-4">
              The offline registration feature allows you to send a message for
              the product you want or the product for which you are looking for
              a buyer, and define an intelligent description for this message,
              and if you have a problem, use artificial intelligence.
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <img src={aboutLineShort} />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-center col-md-9 col-11 my-4 mx-auto">
        <div className="order-1 order-md-1 col-md-5 text-center">
          <img src={aboutMC} className="col-11" />
        </div>
        <div className="order-2 order-md-2 col-md-7 my-4 border-start border-secondary">
          <div className="fs-4 ps-5">
            <b className="h2 fw-bold">Message Center</b>
            <div className="mt-4">
              After you have registered your product in detail, it is time for
              customers or sellers to contact you. At this time, you can refer
              to the message center without any problems and with three
              categories: Expected, Rejected, and Confirm. Check your ongoing
              conversations
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <img src={aboutLineShort} />
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-center col-md-9 col-11 my-4 mx-auto">
        <div className="order-1 order-md-1 col-md-5 text-center">
          <img src={aboutST} className="col-11" />
        </div>
        <div className="order-2 order-md-2 col-md-7 my-4 border-start border-secondary">
          <div className="fs-4 ps-5">
            <b className="h2 fw-bold">Smart Translation</b>
            <div className="mt-4">
              If you have problems communicating with other languages, with our
              smart translation feature, we give you the possibility to
              translate your text at the same moment and change it wherever you
              want.
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <img src={aboutLineLong} />
      </div>
      <div className="bg-F4CE14 col-md-8 col-11 mx-auto rounded-4 mt-5 py-4">
        <div className=" text-center mx-auto col-md-11 fs-5 mb-2 px-md-0 px-4">
          Trade Assist is a fledgling team of 5 people who are always striving
          to improve themselves. If you feel that there is a weakness in our
          performance, know that it will be better in the future and support us
          by using the MyTrade platform. By doing this you make us stay You can
          contact us using the following methods to send your comments and
          suggestions
        </div>
        <div className="d-flex justify-content-center mx-auto">
          <span className="text-center font-weight-600 shadow-4 rounded-3 py-2 px-3 mt-4 mb-2">
            info@mytradeassist.com
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
