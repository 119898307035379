import React from "react";
import { Container } from "react-bootstrap";
import notFoundRobot from "../../images/notFoundRobot.png";
const NotFound = () => {
  return (
    <React.Fragment>
      <Container className=" mx-auto text-center d-flex mt-5 justify-content-center">
        <div className="h1 align-content-center d-flex justify-content-center ">
          <div className="align-content-center  ">
            <p>Sorry!</p> we couldn't find that page
          </div>
          <div className=" col-4">
            <img src={notFoundRobot} className=" col-12" alt="404 not found" />
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default NotFound;
