import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  FormGroup,
  Alert,
  Col,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import makeApiCall from "../../apiCall";
import google from "../../images/google.png";
const SignUp = () => {
  const [Password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [selectedDialCode, setSelectedDialCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, SetSelectedCountry] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownFocus = () => {
    setDropdownOpen(true);
    console.log(dropdownOpen);
  };

  const handleDropdownBlur = () => {
    setDropdownOpen(false);
    console.log(dropdownOpen);
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleRePasswordToggle = () => {
    setShowRePassword(!showRePassword);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (Password !== repassword) {
      setErrors({ passwordMatchError: true });
      return;
    }

    const formData = new FormData(e.target);
    formData.set("PhoneNumber", `${selectedDialCode}${phoneNumber}`);
    formData.delete("DialCode");
    formData.append("Image", image);

    const fieldsToCheck = [
      "TelegramUserName",
      "WhatsAppPhoneNumber",
      "FacebookAccount",
      "LinkedinAccount",
      "Bussines_Email",
    ];

    fieldsToCheck.forEach((fieldName) => {
      if (!formData.get(fieldName)) {
        formData.set(fieldName, "");
      }
    });

    try {
      const response = await makeApiCall({
        restapi: "post",
        controller: "Authentication",
        apiName: "Sign-Up",
        loading: true,
        data: formData,
      });

      if (response.status === 200) {
        setSuccessMessage("Account created successfully!");
        navigate("/login");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;

        if (errorMessage.toLowerCase().includes("password")) {
          setErrors({ passwordError: errorMessage });
        } else {
          setErrors({ otherError: errorMessage });
        }
      } else {
        setErrors({
          otherError: error.response.data,
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
  

        if (data.length > 0) {
          setSelectedDialCode(data[0].dialCode);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleDialChange = (target) => {
    console.log(target);
    setSelectedDialCode(target);
    setPhoneNumber("");
    const selectedCountryData = countries.find(
      (country) => country.dialCode === target
    );

    SetSelectedCountry(selectedCountryData);
  };

  return (
    <div className=" d-flex  justify-content-center ">
      <Col
        sm={11}
        md={8}
        className="bg-F5F7F8 border border-2 col-11 border-warning d-flex justify-content-center shadow p-3 mb-5 rounded-3 my-5 controls-outline"
      >
        <Form className="col-12" onSubmit={handleSignUp}>
          <Row>
            <Form.Group className="my-2 col-md-6 col-12">
              <Form.Label>Real name</Form.Label>
              <Form.Control
                className="col border-secondary-subtle"
                type="text"
                placeholder="Serene Journey"
                name="Name"
                required
              />
            </Form.Group>
            <Form.Group className="my-2 col-md-6 col-12">
              <Form.Label>Username</Form.Label>
              <Form.Control
                className="col border-secondary-subtle"
                type="text"
                placeholder="SereneJourney22"
                name="UserName"
                required
              />
            </Form.Group>

            <Form.Group className="my-2 col-md-6 col-12">
              <Form.Label>Presonal Email</Form.Label>
              <Form.Control
                className="col border-secondary-subtle"
                placeholder="Example@gmail.com"
                type="Email"
                name="Email"
                required
              />
            </Form.Group>
            <Form.Group className="my-2 col-md-6 col-12">
              <Form.Label>Mobile number</Form.Label>
              <div>
                <InputGroup className="">
                  <InputGroup.Text className="py-0 px-0 col-md-3 col-4 bg-F4CE14 border-secondary-subtle">
                    <Form.Select
                      className="form-control bg-transparent border-0 rounded-0 w-100"
                      value={selectedDialCode}
                      required
                      onChange={(e) => handleDialChange(e.target.value)}
                      onFocus={handleDropdownFocus}
                      onBlur={handleDropdownBlur}
                      onClick={handleDropdownFocus}
                    >
                      <option value="" disabled>
                        Select your country
                      </option>
                      {countries
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((Country) => (
                          <option key={Country.name} value={Country.dialCode}>
                            {dropdownOpen
                              ? `${Country.name} ${Country.dialCode} `
                              : Country.dialCode}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup.Text>
                  <Form.Control
                    disabled={selectedCountry ? false : true}
                    className="col border-secondary-subtle"
                    minLength={selectedCountry.phoneNumberLength}
                    maxLength={selectedCountry.phoneNumberLength}
                    pattern="[0-9]*"
                    placeholder={"9129584621".slice(
                      0,
                      selectedCountry.phoneNumberLength
                    )}
                    type="text"
                    name="PhoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </InputGroup>
              </div>
            </Form.Group>

            <Form.Group className="my-2 col-md-6 col-12">
              <Form.Label>Telephone number</Form.Label>
              <Form.Control
                className="col border-secondary-subtle"
                minLength={8}
                maxLength={12}
                pattern="[0-9]*"
                placeholder="telephone number"
                type="text"
                name="TelephoneNumber"
                required
              />
            </Form.Group>

            <Form.Group className="my-2 col-md-6 col-12">
              <Form.Label>Profile Picture</Form.Label>
              <input
                className="col-12 border border-secondary-subtle form-control"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Form.Group>

            <Form.Group
              className="my-2 col-md-6 col-12"
              controlId="formPassword"
            >
              <Form.Label className="my-2">Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  className="col border-secondary-subtle"
                  type={showPassword ? "text" : "Password"}
                  placeholder="Password"
                  name="Password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  size="lg"
                  color="grey"
                  onClick={handlePasswordToggle}
                  className="position-absolute me-3 end-0 top-50 translate-middle-y"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="my-2 col-md-6 col-12"
              controlId="formRepassword"
            >
              <Form.Label className="my-2">Confirm Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  className="col border-secondary-subtle"
                  type={showRePassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={repassword}
                  onChange={(e) => setRepassword(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={showRePassword ? faEyeSlash : faEye}
                  size="lg"
                  color="grey"
                  onClick={handleRePasswordToggle}
                  className="position-absolute me-3  end-0 top-50 translate-middle-y"
                  style={{ cursor: "pointer" }}
                />
              </div>

              {errors.passwordMatchError && (
                <Alert variant="danger" className="my-2">
                  Passwords do not match.
                </Alert>
              )}
              {errors.passwordError && (
                <Alert variant="danger" className="my-2">
                  {errors.passwordError}
                </Alert>
              )}
            </Form.Group>
            <div className="my-2 col-md-6 col-12">
              <label className="col-md-6 col-12 ">Telegram username</label>
              <input
                type="text"
                placeholder="Telegram username"
                name="TelegramUserName"
                className=" form-control my-2 border-secondary-subtle"
              />
            </div>
            <div className="my-2 col-md-6 col-12">
              <label className="col-md-6 col-12 ">Whats app phone number</label>
              <input
                type="text"
                placeholder="Whats app phone number"
                name="WhatsAppPhoneNumber"
                className=" form-control my-2 border-secondary-subtle"
              />
            </div>

            <div className="my-2 col-md-6 col-12">
              <label className="col-md-6 col-12 ">Facebook account</label>
              <input
                type="text"
                placeholder="Facebook account"
                name="FacebookAccount"
                className=" form-control my-2 border-secondary-subtle"
              />
            </div>
            <div className="my-2 col-md-6 col-12">
              <label className="col-md-6 col-12 ">Linkedin account</label>
              <input
                type="text"
                placeholder="Linkedin account"
                name="LinkedinAccount"
                className=" form-control my-2 border-secondary-subtle"
              />
            </div>
            <div className="my-2 col-md-6 col-12">
              <label className="col-md-6 col-12 ">Bussines_Email</label>
              <input
                type="text"
                placeholder="Bussines_Email"
                name="Bussines_Email"
                className=" form-control my-2 border-secondary-subtle"
              />
            </div>

            <div className="text-center">
              <Button
                type="submit"
                className="btn btn-lg py-1 fw-bold col-md-4 mt-4 mb-1 bg-F4CE14 border-0 text-dark"
              >
                Sign up
              </Button>
              <p className="mb-1">or continue with</p>
              <Button className="btn mx-auto  bg-transparent text-dark py-0 border-dark d-flex justify-content-center align-items-center col-md-4">
                <img src={google} className=" icon-50" />
                <span className="h5 mb-0">Continue with Google</span>
              </Button>
            </div>
            <p className="mx-auto col-md-6 col-12 text-center mt-3 mb-0">
              if you have an account,
              <a href="login" className=" text-decoration-none">
                Log in
              </a>
            </p>
            {errors.otherError && (
              <Alert variant="danger" className="my-4">
                {errors.otherError}
              </Alert>
            )}
          </Row>
        </Form>
      </Col>
    </div>
  );
};

export default SignUp;
