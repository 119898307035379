import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import makeApiCall from "../../apiCall";

const SubNav = () => {
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const jwtToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleCountriesClick = () => {
    navigate("/countries");
  };
  const handleChatsClick = () => {
    navigate("/messagecenter");
  };

  const handleMyoffersClick = () => {
    navigate("/offers");
  };
  const handleCategoriesClick = () => {
    navigate("/categories");
  };
  const handleCompaniesClick = () => {
    navigate("/companies");
  };
  const handleProductsClick = () => {
    navigate("/products");
  };
  const handleAboutClick = () => {
    navigate("/about");
  };
  return (
    <React.Fragment>
      <div className=" bg-white position-relative  border subnav">
        <div className="py-1 align-self-end">
          {isMobileView ? (
            <React.Fragment>
              <div className="row justify-content-center mx-auto">
                <div className="col-auto px-1">
                  <span
                    className="mx-1 font-size-14 cursor-pointer focus-hover-light p-0 rounded"
                    onClick={handleProductsClick}
                  >
                    products
                  </span>
                </div>
                <div className="col-auto px-0">
                  <span
                    className="mx-1 font-size-14 cursor-pointer focus-hover-light p-1 rounded"
                    onClick={handleCompaniesClick}
                  >
                    companies
                  </span>
                </div>
                <div className="col-auto px-0">
                  <span
                    className="mx-1 font-size-14 cursor-pointer focus-hover-light p-1 rounded"
                    onClick={handleCategoriesClick}
                  >
                    categories
                  </span>
                </div>
                <div className="col-auto px-0">
                  <span
                    className="mx-1 font-size-14 cursor-pointer text-center rounded focus-hover-light p-1"
                    onClick={handleCountriesClick}
                  >
                    Countries
                  </span>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {" "}
              <span
                className="mx-3 cursor-pointer focus-hover-light fw-bold p-1 rounded"
                onClick={handleProductsClick}
              >
                Products
              </span>
              <span
                className="mx-3 cursor-pointer focus-hover-light p-1 rounded fs-6"
                onClick={handleCompaniesClick}
              >
                Companies
              </span>
              <span
                className="mx-3 cursor-pointer focus-hover-light p-1 rounded fs-6"
                onClick={handleCategoriesClick}
              >
                Categories
              </span>
              <span
                className="mx-3 cursor-pointer text-center rounded focus-hover-light p-1  "
                onClick={handleCountriesClick}
              >
                Countries
              </span>
            </React.Fragment>
          )}

          {jwtToken && !isMobileView && (
            <React.Fragment>
              <span className=" border-end border-dark border-2 mx-2"> </span>
              <span
                className="mx-3 cursor-pointer focus-hover-light p-1 rounded fs-6"
                onClick={handleChatsClick}
              >
                Chats
              </span>

              <span
                className="mx-3 cursor-pointer focus-hover-light p-1 rounded fs-6"
                onClick={handleMyoffersClick}
              >
                My offers
              </span>
              <span
                className="mx-3 cursor-pointer focus-hover-light p-1 rounded fs-6"
                onClick={handleAboutClick}
              >
                About
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubNav;
