import React, { useEffect, useState } from "react";
import makeApiCall from "../../../apiCall";
import CountryLowDets from "./countryLowDets";
import { Row, Form, Col } from "react-bootstrap";
import PaginationComponent from "../../pagination/pagination";
import _ from "lodash";
import { PulseLoader } from "react-spinners";

const Countries = () => {
  const [countries, setCountries] = useState([]);
  const [searchedCountries, setSearchedCountries] = useState([]);
  const [shownCountries, setShownCountries] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [shownSearchTerm, setShownSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const handlePaginationClick = (pageNumber) => {
    setPageNumber(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (!searchTerm) setSearchedCountries([]);
  }, []);

  const fetchCountries = async (searchTerm = "", pageNumber = 1) => {
    try {
      setIsMounted(false);
      setLoading(true);
      setShownSearchTerm(searchTerm);
      const response = await makeApiCall({
        restapi: "get",
        controller: "CustomerCompany",
        apiName: searchTerm ? "SearchCountry" : "GetCountriesPaginated",
        paginated: searchTerm ? false : true,
        pageNumber: pageNumber,
        pageSize: 20,
        hasWhereId: searchTerm ? "countryName" : undefined,
        whereId: searchTerm || undefined,
      });
      if (!searchTerm) {
        setCountries(response.data.countries);
        setTotalPages(response.data.totalPages || 1);
        setIsMounted(true);
        setShownCountries(response.data.countries);
      } else {
        setSearchedCountries(response.data);
        setIsMounted(true);
        setShownCountries(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const debounceFetchCountries = _.debounce(fetchCountries, 500);

  useEffect(() => {
    debounceFetchCountries(searchTerm, pageNumber);
    return debounceFetchCountries.cancel;
  }, [searchTerm, pageNumber]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPageNumber(1);
  };

  return (
    <React.Fragment>
      <Row className="col-md-6  mt-5">
        <Col className="col-md-6 col-10 position-relative justify-content-start mx-auto px-0">
          <Form.Control
            type="text"
            className="form-control-lg w-100"
            placeholder="Search countries..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {loading && (
            <div className="position-absolute translate-middle-y top-50 end-0 me-3">
              <PulseLoader
                className="text-center"
                loading={loading}
                color="rgba(128, 128, 128, 1)"
                size={10}
                speedMultiplier={1}
              />
            </div>
          )}
        </Col>
      </Row>

      <Row className="col-md-12 my-3 mx-auto">
        {shownCountries && (
          <div>
            {Array.from({ length: 4 }).map((_, rowIndex) => (
              <Row
                key={rowIndex}
                className="col-md-12 my-0 d-flex mx-auto justify-content-center"
              >
                {Array.from({ length: 5 }).map((_, columnIndex) => {
                  const index = rowIndex * 5 + columnIndex;
                  const country = shownCountries[index];
                  if (country) {
                    const fontSize =
                      country.name.length > 25
                        ? "h4"
                        : country.name.length > 20
                        ? "h3"
                        : country.name.length > 15
                        ? "h2"
                        : "h1";
                    return (
                      <CountryLowDets
                        key={country.id}
                        countryCode={country.code}
                        countryName={country.name}
                        fontSize={fontSize}
                        companiesCount={country.companiesCount}
                      />
                    );
                  }
                  return null;
                })}
              </Row>
            ))}
          </div>
        )}

        {searchTerm && searchedCountries.length == 0 && !loading && (
          <div className=" mx-auto text-center my-5 border rounded col-md-8 col-11">
            <h4 className="my-3">No results for {shownSearchTerm}</h4>
            <h5 className="my-3">
              Try checking your spelling or use more general terms
            </h5>
          </div>
        )}
      </Row>
      {!searchTerm && shownCountries.length > 0 && (
        <div className="d-flex justify-content-center my-5">
          <PaginationComponent
            PageNumber={pageNumber}
            TotalPages={totalPages}
            handlePaginationClick={handlePaginationClick}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Countries;
