import React, { useEffect, useState } from "react";
import companyBasicImg from "../../../images/companyBasic.png";
import makeApiCall from "../../../apiCall";
import { FlagIcon } from "react-flag-kit";
import {
  Tooltip,
  OverlayTrigger,
  Modal,
  Button,
  Carousel,
  Image,
} from "react-bootstrap";
import editImg from "../../../images/edit.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
const CompanyDetails = ({ companyDets, certificates, handleEditMode }) => {
  const {
    id,
    name,
    image,
    certificateId,
    category,
    countryName,
    isUserCompany,
    countryCode,
    website,
    subCategory,
    bio,
    email,
    telephoneNumber,
    address,
  } = companyDets;
  const [allEmpty, setAllEmpty] = useState(false);
  const [certificateData, setCertificateData] = useState({
    lc: [],
    dp: [],
    cap: [],
    otherCertificateImages: [],
    otherCertificateLinks: [],
  });
  const [error, setError] = useState(null);
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const [certificateLinks, setCertificateLinks] = useState([
    { spec: "", value: "" },
  ]);

  useEffect(() => {
    try {
      if (certificates) {
        setCertificateData({
          lc: certificates.lc || [],
          dp: certificates.dp || [],
          cap: certificates.cap || [],
          otherCertificateImages: certificates.otherCertificateImages || [],
          otherCertificateLinks: certificates.otherCertificateLinks || [],
        });

        const transformedLinks = Object.keys(certificates.otherCertificateLinks || {}).map((key) => ({
          spec: key,
          value: certificates.otherCertificateLinks[key],
        }));
        setCertificateLinks(transformedLinks);

        const allLinksEmpty = transformedLinks.every(({ value }) => value === "");
        setAllEmpty(allLinksEmpty);
      }
    } catch (error) {
      console.log("Error processing certificates", error);
      setError(error);
    }
  }, [certificates]);

  const areAllArraysEmpty = (data) => {
    return (
      data.lc.length === 0 &&
      data.dp.length === 0 &&
      data.cap.length === 0 &&
      data.otherCertificateImages.length === 0
    );
  };

  const renderLinks = () => {
    const allEmpty = certificateLinks.every(
      (link) => link.spec === "" && link.value === ""
    );

    if (allEmpty) {
      return <div className="my-0"></div>;
    }

    return (
      <React.Fragment>
        <div className="h3 mb-0 text-start py-4 col-md-4">
          certificates Links
        </div>
        <div className="ms-5">
          {certificateLinks.map((link, index) => {
            let href = link.value;
            if (!/^https?:\/\//i.test(href)) {
              href = `http://${href}`;
            }

            return (
              <div
                key={`link-${index}`}
                className="text-center bg-45474B rounded-pill text-white mx-2 py-2 px-5 mt-1 d-inline-block"
              >
                <div className="">
                  <a
                    className="text-white text-decoration-none"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.spec}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {address}
    </Tooltip>
  );

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleImageClick = (index) => {
    setIndex(index);
    handleShow();
  };
  const [editMode, setEditMode] = useState(false);

  const existingImages = [
    ...certificateData.lc,
    ...certificateData.dp,
    ...certificateData.cap,
    ...certificateData.otherCertificateImages,
  ];
  const existingImagesLabels = [
    ...certificateData.lc.map((image) => ({ type: "lc" })),
    ...certificateData.dp.map((image) => ({ type: "dp" })),
    ...certificateData.cap.map((image) => ({ type: "cap" })),
    ...certificateData.otherCertificateImages.map((image) => ({
      type: "other",
    })),
  ];

  return (
    <div className="">
      <div className="bg-F4CE14 d-md-flex justify-content-between align-items-md-end align-items-center company-details-header">
        {isMobileView && (
          <React.Fragment>
            <img
              className="icon-150 d-flex mt-3 mb-2 justify-content-center mx-auto rounded-4 object-fit-contain border bg-white p-1"
              src={image ? `data:image/png;base64,${image}` : companyBasicImg}
              alt="company img"
            />
          </React.Fragment>
        )}
        <div className="d-flex">
          {!isMobileView && (
            <img
              className="icon-150 d-none d-sm-block rounded-4 object-fit-contain border bg-white p-1 position-absolute translate-middle-y top-100"
              src={image ? `data:image/png;base64,${image}` : companyBasicImg}
              alt="company img"
            />
          )}
          <div className="align-self-end ms-md-5 ps-md-5 col-12">
            <div className="d-flex justify-content-between col">
              <h3 className="ms-md-5 ps-md-4 mb-0 align-self-center fw-bold font-45474B">
                {name}
              </h3>
              {isUserCompany && (
                <Button
                  className="border-0 bg-transparent font-45474B font-weight-600"
                  onClick={handleEditMode}
                >
                  <img src={editImg} className="icon-20 mb-1 me-1" />
                  Edit
                </Button>
              )}
            </div>
            <div className="ps-md-5 ms-md-4 h5 mx-auto my-2">{category}</div>
          </div>
        </div>
        <div className="d-flex  flex-column align-items-center justify-content-center">
          <div className="d-flex align-items-center">
            <div className="ms-md-5">
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <button className="btn d-inline-block align-items-center border border-dark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                  </svg>
                  Address
                </button>
              </OverlayTrigger>

              <button
                type="button"
                className="btn d-inline-block align-items-center border border-dark px-3 text-center m-2 focus-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-globe-americas"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                </svg>
                <a
                  className="text-dark text-decoration-none px-1"
                  href={
                    !/^https?:\/\//i.test(website)
                      ? `http://${website}`
                      : website
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  site address
                </a>
              </button>
            </div>
          </div>
          <div>
            <p className="text-center">
              {countryName}
              <FlagIcon
                code={countryCode}
                className="rounded-1 mx-2"
                width={30}
                height={20}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="d-md-flex">
        <div className="col-md-6 col-12 ms-md-auto ms-1 my-md-1 my-3 text-center">
          {subCategory && (
            <div className="ps-md-5 ps-2 ms-md-4 ms-0 mx-auto h5 text-start my-1 mt-md-1 mt-5">
              subCategories :
              <span className="text-secondary">{subCategory.join(", ")}</span>
            </div>
          )}
        </div>
        <div className="col-md-4 col-11 ps-md-0 ps-2 ms-2 d-md-flex">
          <div className="mx-md-2 my-md-1 my-2 ">{email}</div>
          <div className="mx-md-2 my-md-1 my-2 ">{telephoneNumber}</div>
        </div>
      </div>

      {bio && (
        <div className="justify-content-center mx-auto col-md-8 col-11 overflow-x-auto my-5">
          <div className="fs-5 align-self-end">Overview</div>

          <div className="border-top border-dark ">{bio}</div>
        </div>
      )}
      {!allEmpty && (
        <div className="justify-content-center my-md-4">
          <div className="col-md-9 col-11 mx-auto">{renderLinks()}</div>
        </div>
      )}
      <div className="container my-5">
        <div className="col-12 px-3 pt-0">
          {!areAllArraysEmpty(certificateData) ? (
            <div className="col border certificates-shadow mx-auto border-warning rounded-4 border-2 mx-auto my-5">
              <div className="display-5 fw-bold text-center pt-3">
                Certificates
              </div>
              <div>
                <Swiper spaceBetween={40} slidesPerView={"auto"}>
                  {existingImages.map((image, index) => (
                    <SwiperSlide
                      key={index}
                      className="certificate-item mb-5 mt-md-4 mt-2 cursor-pointer px-2"
                      onClick={() => handleImageClick(index)}
                    >
                      <div className="d-flex justify-content-center certificate-img-container">
                        <img
                          src={
                            image instanceof Blob
                              ? URL.createObjectURL(image)
                              : `data:image/png;base64,${image}`
                          }
                          alt={`image-${index}`}
                          className="object-fit-contain company-certificate-img w-100"
                        />
                      </div>
                      <div className="text-center mt-2 h4 fw-bold font-45474B">
                        {existingImagesLabels[index].type}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center mx-auto justify-content-center col-md-6 col-11 certificate-image-error">
              <span className="fs-3 certificate-image-error-text mx-5 mx-auto">
                No Certificate images
              </span>
            </div>
          )}
        </div>
      </div>

      <div>
        <Modal
          show={show}
          onHide={handleHide}
          className="mt-5"
          backdrop="static"
          keyboard={false}
          style={{
            backgroundColor: "black",
            border: "none",
            boxShadow: "none",
          }}
        >
          <Modal.Header
            className="justify-content-end"
            style={{
              backgroundColor: "black",
              border: "none",
              padding: 0,
            }}
          >
            <button className="close-button">
              <span aria-hidden="true" onClick={handleHide}>
                &times;
              </span>
            </button>
          </Modal.Header>

          <Modal.Body
            className=""
            style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", padding: 0 }}
          >
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {existingImages.map((image, index) => (
                <Carousel.Item key={index} style={{ width: "100%" }}>
                  <Image
                    className=""
                    src={`data:image/png;base64,${image}`}
                    thumbnail
                    alt={`image-${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "500px",
                      objectFit: "contain",
                      backgroundColor: "black",
                      border: "none",
                    }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Modal.Body>
        </Modal>
        {show && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 1)",
              backdropFilter: "blur(10px)",
              zIndex: 1000,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyDetails;
