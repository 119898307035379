import React, { useEffect, useState } from "react";
import {
  Alert,
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Modal,
  Container,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import makeApiCall from "../../../apiCall";
import translateImg from "../../../icons/translate.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
const Conversation = ({
  AcceptedStatus,
  conversationId,
  remainingHeight,
  handleconversationStatus,
  setConversationIsSelected,
}) => {
  const location = useLocation();
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [firstMessage, setFirstMessage] = useState([]);
  const [status, setStatus] = useState(AcceptedStatus);
  const [showModal, setShowModal] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [translatedMessage, setTranslatedMessage] = useState("");
  const [productId, setProductId] = useState("");
  const [intelligentOfferId, setIntelligentOfferId] = useState("");
  const [isMobileView, setIsMobileView] = useState(
    localStorage.getItem("isMobileView") === "true"
  );
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      console.log(status, AcceptedStatus);
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "MessageCenter",
          apiName: "GetMessages",
          hasId: conversationId,
          loading: true,
        });

        console.log(response);
        setProductId(response.data.productId);
        setIntelligentOfferId(response.data.intelligentOfferId);
        const [firstMessage, ...restOfMessages] =
          response.data.conversationMessages;
        setMessages(restOfMessages);
        setFirstMessage(firstMessage);

        window.scrollTo({ bottom: 0, behavior: "smooth" });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [conversationId, AcceptedStatus]);

  const handleSubmit = () => {
    if (messageValue.trim() !== "") {
      try {
        makeApiCall({
          restapi: "post",
          controller: "MessageCenter",
          apiName: "ReplyEmail",
          data: { message: messageValue, conversationId },
        });

        const messageSender = messages.push({
          createdAt: Date.now(),
          sender: firstMessage.isSenderUser
            ? firstMessage.sender
            : firstMessage.receiver,
          emailBody: messageValue,
          isSenderUser: true,
        });
        setMessages(messages);
        setIsMessageSent(!isMessageSent);
        setMessageValue("");
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleTranslateButton = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall({
        restapi: "post",
        controller: "OpenAI",
        apiName: "TranslateMessage",
        data: {
          messageLanguage: "english",
          messageBody: messageValue,
        },
      });

      setTranslatedMessage(response.data.message);
      console.log(response);
      setIsLoading(false);
      setShowModal(true);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  const handleTranslateMessage = () => {
    setMessageValue(translatedMessage);
    setShowModal(false);
  };
  const handleDescriptionChange = (event) => {
    setMessageValue(event.target.value);
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";
    setMessageValue(textarea.value);
  };
  const handleAcceptClick = async (status) => {
    try {
      const response = await makeApiCall({
        restapi: "get",
        controller: "MessageCenter",
        apiName: "DetermineIncomingConversation",
        hasId: conversationId,
        status: status,
        loading: true,
      });

      console.log(response);

      handleconversationStatus();
    } catch (error) {
      console.error(error);
    }
  };
  const handleShowProductDetails = () => {
    navigate(`/user/products/${productId}`, { state: { productId } });
  };
  const handleOfferClick = () => {
    navigate(`/offers/${intelligentOfferId}`, {
      state: { offerId: intelligentOfferId },
    });
  };
  return (
    <React.Fragment>
      <div
        style={{
          minHeight: `${
            AcceptedStatus === "incoming"
              ? parseInt(remainingHeight)
              : parseInt(remainingHeight) - 100
          }px`,
          maxHeight: `${
            AcceptedStatus === "incoming"
              ? parseInt(remainingHeight)
              : parseInt(remainingHeight) - 100
          }px`,
        }}
        className={`conversation-chatbar position-relative ${
          isMobileView && "w-100 mt-0"
        }`}
      >
        <div
          className={`w-100 ${
            isMobileView && "my-0"
          } py-2 font-F4CE14 bg-45474B d-flex justify-content-between position-absolute zindex-10 end-0 top-0`}
        >
          <div className="h4 font-weight-600 mb-0 my-1 mx-4">
            {firstMessage.sender}
          </div>
          <div className="">
            <Button
              className="my-1 mx-md-3 mx-2 font-weight-600 font-45474B btn-sm rounded-3"
              variant="warning"
              onClick={(e) => {
                e.stopPropagation();
                handleShowProductDetails();
              }}
            >
              Product details
            </Button>

            <Button
              className="my-1 me-md-4 mx-2 font-weight-600 font-45474B btn-sm rounded-3"
              variant="warning"
              onClick={(e) => {
                e.stopPropagation();
                handleOfferClick();
              }}
            >
              Offer Details
            </Button>
          </div>
          {isMobileView && (
            <div className="position-absolute mt-5 ms-2">
              <button
                onClick={() => setConversationIsSelected()}
                className="rounded-5 btn bg-white shadow-4 border-F4CE14 border-2"
              >
                <FontAwesomeIcon icon={faArrowLeft} size="" color="#45474B" />
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            minHeight: `${
              AcceptedStatus === "incoming"
                ? parseInt(remainingHeight) - 45
                : parseInt(remainingHeight) - 100
            }px`,
            maxHeight: `${
              AcceptedStatus === "incoming"
                ? parseInt(remainingHeight) - 45
                : parseInt(remainingHeight) - 100
            }px`,
          }}
          className="overflow-y-scroll conversation-container grey-scroll"
        >
          <div className="  col-md-11 mx-auto justify-content-center ">
            <Alert className=" col-md-10 col-11 shadow-3 border-0 text-dark mx-auto mt-4 bg-F4CE14 rounded-4">
              <div className=" d-flex ">
                <p className=" h4 mb-0">Title: {firstMessage.subject}</p>
              </div>
              <div> {firstMessage.emailBody}</div>
              <Row>
                <Col className=" d-flex justify-content-end mt-md-3">
                  {new Date(firstMessage.createdAt).toLocaleString("en-US", {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </Col>
              </Row>
            </Alert>
          </div>
          {messages.map((message, index) =>
            message.isSenderUser ? (
              <div className="my-4 d-flex justify-content-end me-4">
                <Alert className="col-md-6 col-10 py-md-3 py-2 shadow-3 rounded-4 text-dark bg-white border-0 rounded">
                  <div> {message.emailBody}</div>
                  <Row>
                    {/* <Col md={6} sm={6} className="mt-3 ">
                      {message.sender} <span className="fw-bold">(You)</span>
                    </Col> */}
                    <Col
                      md={12}
                      sm={12}
                      className=" d-flex justify-content-end mt-md-3"
                    >
                      {new Date(message.createdAt).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </Col>
                  </Row>
                </Alert>
              </div>
            ) : (
              <div className="my-4 d-flex justify-content-start ms-4">
                <Alert className="col-md-6 col-10 py-md-2 p-1 shadow-3 rounded-4 text-dark bg-FFF5C1  border-0 rounded">
                  <div> {message.emailBody}</div>
                  <Row>
                    {/* <Col md={6} sm={6} className="mt-3">
                      {message.sender}
                    </Col> */}
                    <Col
                      md={12}
                      sm={12}
                      className=" d-flex justify-content-end mt-md-3"
                    >
                      {new Date(message.createdAt).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </Col>
                  </Row>
                </Alert>
              </div>
            )
          )}
          {AcceptedStatus === "incoming" && (
            <div className="mb-5 mt-3">
              <div className="text-center h4">
                Do you approve this conversation?
              </div>
              <div className=" d-flex justify-content-center py-1 px-1 ">
                <Button
                  className="btn btn-lg my-2 btn-warning mx-3 shadow-3"
                  onClick={() => handleAcceptClick("Reject")}
                >
                  Reject
                </Button>
                <Button
                  className="btn btn-lg my-2 btn-success mx-3 shadow-3"
                  onClick={() => handleAcceptClick("Accept")}
                >
                  Accept
                </Button>
              </div>
            </div>
          )}
        </div>
        {AcceptedStatus !== "incoming" && (
          <div className=" d-flex conversation-textbar bottom-0 py-md-0 py-0 end-0 px-1 position-fixed border justify-content-center">
            <div className="align-self-center">
              <Button
                disabled={messageValue.trim() ? false : true}
                onClick={handleTranslateButton}
                className="rounded-3 bg-transparent border-0 focus-hover"
              >
                {isLoading ? (
                  <React.Fragment>
                    <Spinner
                      animation="border"
                      className="spinner-small text-dark align-self-center"
                      role="status"
                    />
                  </React.Fragment>
                ) : (
                  <img src={translateImg} className="" />
                )}
              </Button>
            </div>

            <div className="col">
              <Form.Control
                placeholder="message"
                className="w-100 my-1 form-control-no-focus border-0"
                type="text"
                name="message"
                value={messageValue}
                onChange={(e) => handleDescriptionChange(e)}
                required
              />
            </div>

            <div className="align-self-center">
              <Button
                disabled={messageValue.trim() ? false : true}
                onClick={handleSubmit}
                variant="warning"
                className="rounded-3 me-2 bg-transparent border-0 focus-hover"
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faPaperPlane}
                  color="#45474B"
                />
              </Button>
            </div>
          </div>
        )}
        <Modal className="" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header className=" justify-content-end  bg-light">
            <div
              className="modal-close-btn btn"
              onClick={() => setShowModal(false)}
            >
              &#10006;
            </div>
          </Modal.Header>
          <Row className="bg-light">
            <Container className="col text-center my-3  h5">Message</Container>
            <Container className="col text-center my-3  h5">
              Translated To English
            </Container>
          </Row>

          <Row className="bg-light">
            <div className="col-6 px-1">
              <div className="col-md-10 col-12 mx-auto">
                <Form.Control
                  as="textarea"
                  readOnly
                  rows={15}
                  className="bg-secondary-subtle border-0  mx-auto"
                >
                  {messageValue}
                </Form.Control>
              </div>
            </div>
            <div className="col-6 px-1">
              <div className="col-md-10 col-12 mx-auto">
                <Form.Control
                  as="textarea"
                  rows={15}
                  className="bg-white border-warning mx-auto"
                >
                  {translatedMessage}
                </Form.Control>
              </div>
            </div>
          </Row>
          <Row className="bg-light d-flex  justify-content-center ">
            <Button
              className="my-4 btn-success col-md-2 col-4 "
              onClick={handleTranslateMessage}
            >
              Apply
            </Button>
          </Row>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Conversation;
