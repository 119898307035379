import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  OverlayTrigger,
  Tooltip,
  Container,
  Col,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { FaInfoCircle } from "react-icons/fa";
import makeApiCall from "../../../apiCall";
import { ToastContainer, toast } from "react-toastify";

const IntelligentOffer = () => {
  const [companyNames, setCompanyNames] = useState([]);
  const [formData, setFormData] = useState({
    productName: "",
    title: "",
    moq: "",
    intelligentOfferValidationDate: "",
    intelligentOfferRequestType: "",
    fcaMin: 0,
    fcaMax: 0,
    fcaDestination: "",
    fasMin: 0,
    fasMax: 0,
    fasDestination: "",
    fobMin: 0,
    fobMax: 0,
    fobDestination: "",
    exwMin: 0,
    exwMax: 0,
    exwDestination: "",
    cipMin: 0,
    cipMax: 0,
    cipDestination: "",
    cptMin: 0,
    cptMax: 0,
    cptDestination: "",
    messageLanguage: "",
  });
  const [selectedShippingTerms, setSelectedShippingTerms] = useState([]);
  const [isManual, setIsManual] = useState(false);
  const [products, setProducts] = useState([]);
  const [intelligentOfferRequestType, setIntelligentOfferRequestType] =
    useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { productName, productId, productPurpose } = location.state || "";
  const [languageSelected, setLanguageSelected] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [isSure, setIsSure] = useState(false);
  const [message, setMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [descError, setDescError] = useState([]);
  const [OfferError, setOfferError] = useState("");

  // const handleCheckboxChange = (event) => {
  //   const { value, checked } = event.target;
  //   if (checked) {
  //     setSelectedShippingTerms((prevTerms) => [...prevTerms, value]);
  //   } else {
  //     setSelectedShippingTerms((prevTerms) =>
  //       prevTerms.filter((term) => term !== value)
  //     );
  //   }
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [`${value.toLowerCase()}Min`]: 0,
  //     [`${value.toLowerCase()}Max`]: 0,
  //     [`${value.toLowerCase()}Destination`]: "",
  //   }));
  // };

  useEffect(() => {
    if (productName && productId && productPurpose) {
      setIsManual(true);

      setFormData((prevData) => ({ ...prevData, productName: productName }));
      if (productPurpose === "Buy")
        setFormData((prevData) => ({
          ...prevData,
          intelligentOfferRequestType: "Seller",
        }));
      else
        setFormData((prevData) => ({
          ...prevData,
          intelligentOfferRequestType: "Buyer",
        }));
    }
  }, []);

  const handleRoleChange = async (event) => {
    setSelectedShippingTerms([]);
    const { value } = event.target;

    try {
      const response = await makeApiCall({
        restapi: "get",
        controller: "CustomerProduct",
        apiName: "GetUserProducts",
        hasId: value,
      });

      setProducts(response.data);
    } catch (error) {
      console.error(error);
    }
    setIntelligentOfferRequestType(value);
    setFormData((prevData) => ({
      ...prevData,
      intelligentOfferRequestType: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedShippingTerms((prevTerms) => [...prevTerms, value]);
    } else {
      setSelectedShippingTerms((prevTerms) =>
        prevTerms.filter((term) => term !== value)
      );
    }
    setFormData((prevData) => ({
      ...prevData,
      [`${value.toLowerCase()}Min`]: 0,
      [`${value.toLowerCase()}Max`]: 0,
      [`${value.toLowerCase()}Destination`]: "",
    }));
  };

  const handleFormInputChange = (event, term) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [`${term.toLowerCase()}${name}`]: value,
    }));
  };

  const handleTitleChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ ...prevData, title: value }));
  };

  const handleMoqChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ ...prevData, moq: value }));
  };

  const handleintelligentOfferValidationDateChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      intelligentOfferValidationDate: value,
    }));
  };

  const handleProductName = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ ...prevData, productName: value }));
  };

  const handleAddproduct = () => {
    navigate("/user/companies");
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await makeApiCall({
          restapi: "get",
          controller: "CustomerCompany",
          apiName: "GetUserCompaniesPaginated",
          paginated:true,
          pageNumber:1,
          pageSize:100,
        });

        setCompanyNames(response.data.companies.map((company) => company.name));
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleSelectCompany = (event) => {
    const selectedCompanyName = event.target.value;

    setFormData((prevData) => ({
      ...prevData,
      companyName: selectedCompanyName,
    }));
  };

  useEffect(() => {
    if (submitSuccess) {
      toast.success("Offer saved successfully!");
      const timerId = setTimeout(() => {
        setSubmitSuccess(false);
        navigate("/offers");
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [submitSuccess]);

  useEffect(() => {
    const textarea = document.getElementById("descriptionTextarea");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";
    }
  }, [message]);

  const handleGenerateByTradeCopilot = async () => {
    setBtnClicked(true);
    try {
      const response = await makeApiCall({
        restapi: "post",
        controller: "OpenAI",
        apiName: "GenerateMessage",
        data: formData,
      });

      setMessage(response.data.message);
      setBtnClicked(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDescriptionChange = (event) => {
    const textarea = event.target;
    textarea.style.height = "auto";
    textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";
    setMessage(textarea.value);
  };

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    if (value !== "") {
      setLanguageSelected(true);
      setFormData((prevState) => ({
        ...prevState,
        messageLanguage: value,
      }));
    } else {
      setLanguageSelected(false);
    }
  };
  const handleSubmit = async (event) => {
    setIsSure(false);
    event.preventDefault();
    try {
      const updatedFormData = { ...formData };
      delete updatedFormData.messageLanguage;
      updatedFormData.description = message;

      const response = await makeApiCall({
        restapi: "post",
        controller: "IntelligentOffer",
        apiName: "RegisterIntelligentOffer",
        data: updatedFormData,
        loading: true,
      });

      setSubmitSuccess(true);
    } catch (error) {
      console.error(error.response.data.errors);

      console.log(error.response.data.errors);
      if (!error.response.data.errors) {
        setOfferError(error.response.data);
        setDescError([]);
      } else {
        setOfferError("");
        setDescError(error.response.data.errors);
      }

      console.log(OfferError);
    }
  };
  const handleIsSureModal = () => {
    setIsSure(true);
  };

  return (
    <React.Fragment>
      <div className="d-flex  justify-content-center">
        <div className="col-md-7 col-11 col-sm-12">
          <Form className=" rounded text-center my-5">
            <div className="bg-F5F7F8  border border-2  border-warning  rounded-4 ">
              {!isManual ? (
                <React.Fragment>
                  <div className=" my-4 fw-bold">
                    <Form.Check
                      type="radio"
                      name="intelligentOfferRequestType"
                      value="Seller"
                      label="I am a Seller"
                      inline
                      required
                      className="custom-radio"
                      checked={intelligentOfferRequestType === "Seller"}
                      onChange={handleRoleChange}
                    />
                    <Form.Check
                      type="radio"
                      inline
                      required
                      name="intelligentOfferRequestType"
                      value="Buyer"
                      label="I am a Buyer"
                      className="custom-radio"
                      checked={intelligentOfferRequestType === "Buyer"}
                      onChange={handleRoleChange}
                    />
                  </div>
                  <Container className=" d-md-flex col-10  justify-content-start my-3">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          You need to add your target product first
                        </Tooltip>
                      }
                    >
                      <Form.Label className=" col-md-3 col-12 text-start col-4 my-auto   ps-4  py-2">
                        Product
                        <FaInfoCircle className="ms-1 text-secondary" />
                      </Form.Label>
                    </OverlayTrigger>

                    <div className="col-md-6 col-12">
                      <Form.Control
                        required
                        as="select"
                        name="productName"
                        className=" col-md  h-100 rounded-end-0 "
                        onChange={handleProductName}
                      >
                        <option value="">Select product</option>
                        {products.map((product) => (
                          <option key={product.id} value={product.name}>
                            {product.name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                    <Button
                      className="col-md-3 col-12 mt-md-0 mt-3 rounded-start-0"
                      variant="warning"
                      onClick={handleAddproduct}
                    >
                      add Product
                    </Button>
                  </Container>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className=" my-4 fw-bold">
                    I am a {productPurpose === "Buy" ? "Seller" : "Buyer"}
                    {/* <Form.Check
                      type="radio"
                      name="intelligentOfferRequestType"
                      value="Seller"
                      label="I am a Seller"
                      inline
                      readOnly
                      className="custom-radio"
                      checked={productPurpose === "Buy"}
                    />
                    <Form.Check
                      type="radio"
                      inline
                      readOnly
                      name="intelligentOfferRequestType"
                      value="Buyer"
                      label="I am a Buyer"
                      className="custom-radio"
                      checked={productPurpose === "Sell"}
                    /> */}
                  </div>
                  <Container className=" d-md-flex col-10  justify-content-start my-3">
                    <Form.Label className=" col-md-3 col-12 text-start col-4 my-auto   ps-4  py-2">
                      Product
                    </Form.Label>

                    <div className="col-md-9 mx-auto col-12">
                      <Form.Control
                        value={productName}
                        name="productName"
                        className=" col-md  h-100"
                        disabled
                      />
                    </div>
                  </Container>
                  <Container className=" d-md-flex col-10  justify-content-start my-3">
                    <Form.Label className=" col-md-3 col-12 text-start col-4 my-auto   ps-4  py-2">
                      Select Company
                    </Form.Label>
                    <div className="col-md-9 mx-auto col-12">
                      <Form.Control
                        required
                        as="select"
                        name="CompanyName"
                        className=" col-md  h-100"
                        onChange={handleSelectCompany}
                      >
                        <option value="">Select Company</option>
                        {companyNames.map((companyName, index) => (
                          <option key={index} value={companyName}>
                            {companyName}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Container>
                </React.Fragment>
              )}
              <Container className="  d-md-flex col-10 justify-content-start my-3 ">
                <Form.Label className="col-md-3 col-12 text-start col-4 my-auto  ps-4 py-2">
                  Offer title
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    value={formData.title}
                    onChange={handleTitleChange}
                    required
                  />
                </Col>
              </Container>

              <Container className=" d-md-flex col-10 justify-content-start my-3">
                <Form.Label className="col-md-3 col-12 text-start col-4 my-auto  ps-4 py-2">
                  MOQ
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    className=""
                    type="number"
                    placeholder="Minimum Order Quantity"
                    name="moq"
                    value={formData.moq}
                    onChange={handleMoqChange}
                    required
                  />
                </Col>
              </Container>

              <Container className=" d-md-flex col-10 justify-content-start my-3 mb-4">
                <Form.Label className="col-md-3 col-12 text-start col-4 my-auto  ps-4 py-2">
                  Validation date
                </Form.Label>
                <Col md={9}>
                  <Form.Control
                    as="select"
                    className="col-3"
                    name="intelligentOfferValidationDate"
                    value={formData.intelligentOfferValidationDate}
                    onChange={handleintelligentOfferValidationDateChange}
                    required
                  >
                    <option value="">Select duration</option>
                    <option value="OneWeek">1 week</option>
                    <option value="TwoWeeks">2 weeks</option>
                    <option value="OneMonth">1 month</option>
                    <option value="ThreeMonths">3 months</option>
                    <option value="SixMonths">6 months</option>
                    <option value="OneYear">1 year</option>
                  </Form.Control>
                </Col>
              </Container>
              <h4 className="mb-2 mt-5">Shipping Terms</h4>
              <div className="text-start col-md-10 mx-auto">
                <div className="row col-md col-11 me-auto mx-md-auto my-2">
                  {["FCA", "FAS", "FOB", "EXW", "CIP", "CPT"].map((term) => (
                    <div className="col-md-2 col-3 mx-md-auto my-2" key={term}>
                      <Form.Check
                        type="checkbox"
                        name="shippingTerm"
                        value={term}
                        label={term}
                        checked={selectedShippingTerms.includes(term)}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {selectedShippingTerms.map((term) => (
                <div key={term} className="col-10 mx-auto my-4">
                  <div className=" d-md-flex w-100 justify-content-center">
                    <div className="term-label col-1 mx-auto align-content-center">
                      {term}
                    </div>
                    <div className=" col-md-6 col-11 mx-auto">
                      <Form.Control
                        type="address"
                        name="Destination"
                        placeholder="Destination"
                        onChange={(event) => handleFormInputChange(event, term)}
                      />
                    </div>
                    <div className=" col-md-3 col-8 mt-md-0 mt-2 mx-auto">
                      <Form.Control
                        className=" "
                        name={
                          intelligentOfferRequestType === "Seller"
                            ? "Min"
                            : "Max"
                        }
                        type="number"
                        placeholder={
                          intelligentOfferRequestType === "Seller"
                            ? "Min Price"
                            : "Max Price"
                        }
                        onChange={(event) => handleFormInputChange(event, term)}
                      />
                    </div>
                  </div>
                </div>
              ))}

              <div className=" d-flex  justify-content-center ">
                <div className="col-md-11 col-12 col-sm-12 mb-3 mt-4">
                  <Row className=" mx-auto col-md-8 justify-content-center col-10 text-center ">
                    <div className="col-md-3 ">
                      <Form.Control
                        className=" text-center w-100 rounded-3 border-secondary-subtle py-1"
                        as="select"
                        onChange={handleLanguageChange}
                      >
                        <option value="">language</option>
                        <option value="English">English</option>
                        <option value="Spanish">Spanish</option>
                        <option value="persian">Persian</option>
                        <option value="German">German</option>
                      </Form.Control>
                    </div>
                    <Button
                      disabled={!languageSelected || btnClicked ? true : false}
                      variant="warning"
                      className={`text-center py-1 rounded-3 col-md-6 ${
                        (!languageSelected || btnClicked) && "btn-disabled"
                      }`}
                      onClick={handleGenerateByTradeCopilot}
                    >
                      {btnClicked ? (
                        <div className="d-flex align-items-center  justify-content-center">
                          <span className="me-2">Generating</span>
                          <Spinner
                            animation="border"
                            className="spinner-small"
                            role="status"
                          />
                        </div>
                      ) : (
                        "Generate by Trade Copilot"
                      )}
                    </Button>
                  </Row>

                  <Row className="mt-3 mb-4 text-center mx-auto col-md-10 col-11">
                    <Form.Label className="col-md-10 mb-0 rounded-top-4  border-bottom-0 bg-white text-start mx-auto border fw-bold py-1">
                      Description
                    </Form.Label>
                    <div className="col-md-10 mx-auto px-0">
                      <Form.Control
                        id="descriptionTextarea"
                        className=" rounded-bottom-4 rounded-top-0 intelligent-offer-description"
                        as="textarea"
                        name="description"
                        onChange={handleDescriptionChange}
                        required
                        value={message}
                      ></Form.Control>
                    </div>

                    <div className="text-center w-100 px-0">
                      {descError && descError.Description && (
                        <div className="alert alert-danger mt-2">
                          {descError.Description[0]}
                        </div>
                      )}
                      {OfferError && (
                        <div className="alert alert-danger mt-2">
                          {OfferError}
                        </div>
                      )}
                    </div>
                  </Row>
                  <Button
                    variant="warning"
                    onClick={handleIsSureModal}
                    className="text-center mx-auto mb-5 rounded-3 border col-md-2"
                  >
                    Submit
                  </Button>
                  <div className=" bg-info d-flex  justify-content-center ">
                    <Modal
                      centered
                      className=" d-flex justify-content-center  text-center"
                      show={isSure}
                      onHide={() => setIsSure(false)}
                    >
                      <Modal.Header className="bg-light mb-0 h4">
                        Are You Sure?
                        <button
                          onClick={() => setIsSure(false)}
                          className="btn-close remove-icon"
                        ></button>
                      </Modal.Header>
                      <Modal.Body className="bg-light text-center pt-4 rounded">
                        <span className="h5  mx-5">
                          You can't change your offer after it is submitted
                        </span>
                        <div className="mt-4">
                          <Button
                            variant="success"
                            onClick={handleSubmit}
                            className=" btn-lg my-3"
                          >
                            I'm sure
                          </Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>

                  <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    onClose={() => navigate("/offers")}
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <React.Fragment></React.Fragment>
    </React.Fragment>
  );
};

export default IntelligentOffer;
